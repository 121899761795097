import {
    SET_CREATE_PRESENTATION_MODAL_VIEW,
    SET_BACKGROUND_COLOR_MODAL_VIEW,
    SET_GUESTPASS_GENERATE_MODAL_VIEW,
    SET_GUESTPASS_DROPBOX_MODAL_VIEW,
    SET_ICON_COLOR_MODAL_VIEW,
    SET_TILE_COLOR_MODAL_VIEW,
    SET_FONT_COLOR_MODAL_VIEW,
    SET_ADD_TILE_MODAL_VIEW,
    SET_TILE_MODAL_VIEW,
    SET_FILE_OVERRIDE_MODAL_VIEW,
    SET_COMPANY_USER_FILTER
} from '../constants/actionTypes';

const initialState = {
    createPresentationModalView: false,
    backgroundColorModalView: false,
    guestpassGenerateModalView: false,
    guestpassDropboxModalView: false,
    iconColorModalView: false,
    tileColorModalView: false,
    fontColorModalView: false,
    addTileModalView: false,
    tileModalView: false,
    fileOverrideModalView: false,
    companyUserFilter: "company"
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CREATE_PRESENTATION_MODAL_VIEW:
            return {
                ...state,
                createPresentationModalView: action.payload
            };
        case SET_BACKGROUND_COLOR_MODAL_VIEW:
            return {
                ...state,
                backgroundColorModalView: action.payload
            };
        case SET_GUESTPASS_GENERATE_MODAL_VIEW:
            return {
                ...state,
                guestpassGenerateModalView: action.payload
            };
        case SET_FILE_OVERRIDE_MODAL_VIEW:
            return {
                ...state,
                fileOverrideModalView: action.payload
            };
        case SET_GUESTPASS_DROPBOX_MODAL_VIEW:
            return {
                ...state,
                guestpassDropboxModalView: action.payload
            };
        case SET_ICON_COLOR_MODAL_VIEW:
            return {
                ...state,
                iconColorModalView: action.payload
            };
        case SET_TILE_COLOR_MODAL_VIEW:
            return {
                ...state,
                tileColorModalView: action.payload
            };
        case SET_FONT_COLOR_MODAL_VIEW:
            return {
                ...state,
                fontColorModalView: action.payload
            };
        case SET_ADD_TILE_MODAL_VIEW:
            return {
                ...state,
                addTileModalView: action.payload
            };
        case SET_TILE_MODAL_VIEW:
            return {
                ...state,
                tileModalView: action.payload
            };
        case SET_COMPANY_USER_FILTER:
            return {
                ...state,
                companyUserFilter: action.payload
            };
        default:
            return state;
    }
};