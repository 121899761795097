import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Intro from './pages/Intro.js';
import ScreenChoice from './pages/ScreenChoice.js';
import Settings from './pages/Settings.js';
import TileChoice from './pages/TileChoice.js';
import Export from './pages/Export.js';
import Login from './pages/Login.js';
import Presentations from './pages/choosePresentation.js';
import GuestPass from './pages/GuestPass';
import Header from './components/Header.js';
import GenerateGuestPassModal2 from './components/GenerateGuestPassModal2.js';
import GuestPassDropboxModal2 from './components/GuestPassDropboxModal2.js';
import C from './firebase/context';

class App extends Component {
  render() {
    return (
      <C.Consumer>
        {firebase => (
          <Router>
            <Switch>
                <Route exact path="/">
                  <Login firebase={firebase} />
                </Route>
                <Route exact path='/select' component={Presentations} />
                <Route path='/guestpass/*' component={GuestPass} />
              <div style={{backgroundColor: "#0768b0", overflow: 'hidden'}}>
                <Header />
                <GenerateGuestPassModal2 />
                <GuestPassDropboxModal2 />
                <Route exact path="/intro" component={Intro} />
                <Route exact path="/screen" component={ScreenChoice} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/tiles" component={TileChoice} />
                <Route exact path="/export" component={Export} />
              </div>
            </Switch>
          </Router>
        )}
      </C.Consumer>
    )
  };
}

export default App;
