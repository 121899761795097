import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { 
    setPresentationId,
    setNewPresentation
} from '../actions/presentationActions';
import { 
    setScreenVisit,
    setCurrentPage
} from '../actions/visitActions';
import {
    setCreatePresentationModalView
} from "../actions/viewActions";
import {
    addPresentation
} from "../actions/authActions";
import {
    createNewPresentation
} from '../utils/API';
import { withRouter } from 'react-router-dom';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

const StyledBackdrop = styled.div`
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 2;
    background-color: #3b3b3bBF;
`;

const StyledCreationModal = styled.div`
    height: 200px;
    width: 400px;
    background-color: white;
    position: absolute;
    z-index: 3;
    top: calc(50% - 100px);
    left: calc(50% - 200px);
    box-shadow: 1px 1px 8px 1px #00000080;
    transition: all 0.3s ease;
    transform: ${props => props.active ? `translateX(0%)` : `translateX(100vw)`};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 450px) and (orientation: portrait) {
        width: 350px;
        left: calc(50% - 175px);
    }

    .input-box {
        height: 40%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .title-box {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding-left: 50px;

            p {
                margin-block: 0px;
                color: #3b3b3b;
                font-size: 14px;
            }
        }

        input {
            height: 30px;
            width: 300px;
            padding: 1% 5% 1% 5%;
            text-align: center;
            font-style: ${props => props.length ? `normal` : `italic`};
            border-radius: 2px;
        }
    }

    .confirm-box {
        height: 40%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .confirm-button {
            height: 35px;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            background-color: #0768b0;
            cursor: pointer;
            box-shadow: 1px 1px 1px #3b3b3b;

            p {
                margin-block: 0px;
                color: white;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    .exit-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        width: 40px;
    }
`;

class CreatePresentationModal extends Component {

    constructor(){
        super();
        this.state = {
            showHide: false,
            name: "",
            clicked: false,
            usedNames: [],
            nameError: "",
            disabled: false
        }
    }

    componentDidMount = () => {
        let names = [];
        for (let presentation of this.props.presentations) {
            if (!presentation.isArchived) {
                console.log('pres', presentation.name);
                if (!names.includes(presentation.name)) {
                    names.push(presentation.name.toLowerCase());
                }
            }
        }
        this.setState({ usedNames: names });
    }

    onChange = (event) => {
        this.setState({ name: event.target.value });
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          this.createPresentation();
        }
    };

    handleModalShow = () => {
        this.props.setCreatePresentationModalView(!this.props.createPresentationModalView);
    }

    createPresentation = () => {
        let presentationName = this.state.name;
        if (!this.state.usedNames.includes(presentationName.toString().toLowerCase())) {
            if (presentationName !== '') {
                console.log("NEW PRES NAME DETECTED");
                this.setState({ disabled: true });
                setTimeout(() => {
                    this.setState({ disabled: false });
                }, 2000);
                let newPresentation = {
                    id: uuidv4(),
                    userId: this.props.user,
                    name: presentationName.trim(),
                    companyId: this.props.companyId,
                    companyName: this.props.companyName,
                    token: this.props.authToken
                }
    
                this.props.setPresentationId(newPresentation.id);
                createNewPresentation(newPresentation).then(res => {
                    console.log("NEW PRESENTATION", res);
                    this.props.addPresentation(res.data.presentation);
                }); 
                this.props.setScreenVisit(true);
                // this.props.setNewPresentation(true);
                this.props.setCreatePresentationModalView(false);
                this.props.setCurrentPage('screen');
                this.props.history.push('/screen');
            } else {
                console.log("NO NAME DETECTED");
                this.setState({ nameError: "Please input a name." });
                setTimeout(() => {
                    this.setState({ nameError: "" });
                }, 2500);
                return;
            }
        } else {
            console.log("DUPLICATE NAME DETECTED");
            this.setState({ nameError: "Name already taken." });
            setTimeout(() => {
                this.setState({ nameError: "" });
            }, 2500);
        }
    }

    clickInput = () => {
        this.setState({ clicked: true });
    }

    render() {

        return(
            <>
                <StyledBackdrop
                    style={{
                        visibility: this.props.createPresentationModalView ? 'visible' : 'hidden'
                    }}
                    onClick={() => this.handleModalShow()}
                />
                <StyledCreationModal 
                    active={this.props.createPresentationModalView}
                    length={this.state.name.length}
                >
                    <div className='exit-icon'
                        onClick={() => this.handleModalShow()}
                    >
                        <CancelRoundedIcon
                            style={{
                                color: '#ff1940',
                                fontSize: `32px`,
                                cursor: 'pointer',
                            }}
                        />
                    </div>
                    <div className='input-box'>
                        <div className='title-box'>
                            <p
                                style={{
                                    color: this.state.nameError.length ? 'red' : '#3b3b3b'
                                }}
                            >
                                {this.state.nameError.length ? this.state.nameError : 'Presentation Name'}
                            </p>
                        </div>
                        <input
                            onChange={this.onChange}
                            onKeyPress={this.handleKeyPress}
                            value={this.state.name}
                            type="text"
                            placeholder={this.state.clicked === false ? "F2B Services LLC" : null}
                            onClick={() => this.clickInput()}
                        />
                    </div>
                    <div className='confirm-box'>
                        <div 
                            className='confirm-button'
                            onClick={this.state.disabled ? null : () => this.createPresentation()}
                        >
                            <p>Create Presentation</p>
                        </div>
                    </div>
                </StyledCreationModal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {        
        user: state.authReducer.user,
        createPresentationModalView: state.viewReducer.createPresentationModalView,
        authToken: state.authReducer.authToken,
        companyId: state.authReducer.companyId,
        companyName: state.authReducer.companyName,
        presentations: state.authReducer.presentations
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPresentationId: (id) => dispatch(setPresentationId(id)),
        setNewPresentation: (bool) => dispatch(setNewPresentation(bool)),
        setScreenVisit: (bool) => dispatch(setScreenVisit(bool)),
        setCreatePresentationModalView: (bool) => dispatch(setCreatePresentationModalView(bool)),
        addPresentation: (obj) => dispatch(addPresentation(obj)),
        setCurrentPage: (page) => dispatch(setCurrentPage(page))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePresentationModal));