// auth
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_PREVIOUS_TOKEN = "SET_PREVIOUS_TOKEN";
export const SET_PRESENTATIONS = "SET_PRESENTATIONS";
export const SET_EMAIL = "SET_EMAIL";
export const SET_IS_AUTH = "SET_IS_AUTH";
export const EDIT_PRESENTATION_ARCHIVAL = 'EDIT_PRESENTATION_ARCHIVAL';
export const EDIT_PRESENTATION_COLOR = 'EDIT_PRESENTATION_COLOR';
export const ADD_PRESENTATION = 'ADD_PRESENTATION';
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const SET_COMPANY_ID = "SET_COMPANY_ID";
export const RESET_USER = "RESET_USER";
export const SET_HIDDEN_COMPANY_ID = "SET_HIDDEN_COMPANY_ID";

//views
export const SET_CREATE_PRESENTATION_MODAL_VIEW = 'SET_CREATE_PRESENTATION_MODAL_VIEW';
export const SET_BACKGROUND_COLOR_MODAL_VIEW = 'SET_BACKGROUND_COLOR_MODAL_VIEW';
export const SET_GUESTPASS_GENERATE_MODAL_VIEW = 'SET_GUESTPASS_GENERATE_MODAL_VIEW';
export const SET_GUESTPASS_DROPBOX_MODAL_VIEW = 'SET_GUESTPASS_DROPBOX_MODAL_VIEW';
export const SET_ICON_COLOR_MODAL_VIEW = 'SET_ICON_COLOR_MODAL_VIEW';
export const SET_TILE_COLOR_MODAL_VIEW = 'SET_TILE_COLOR_MODAL_VIEW';
export const SET_FONT_COLOR_MODAL_VIEW = 'SET_FONT_COLOR_MODAL_VIEW';
export const SET_ADD_TILE_MODAL_VIEW = 'SET_ADD_TILE_MODAL_VIEW';
export const SET_TILE_MODAL_VIEW = 'SET_TILE_MODAL_VIEW';
export const SET_FILE_OVERRIDE_MODAL_VIEW = 'SET_FILE_OVERRIDE_MODAL';
export const SET_COMPANY_USER_FILTER = 'SET_COMPANY_USER_FILTER';

//presentation
export const SET_NEW_PRESENTATION = "SET_NEW_PRESENTATION";
export const SET_PRESENTATION_ID = "SET_PRESENTATION_ID";
export const SET_BACKGROUND_IMAGE_URL = "SET_BACKGROUND_IMAGE_URL";
export const SET_BACKGROUND_IMAGE_ID = "SET_BACKGROUND_IMAGE_ID";
export const SET_BACKGROUND_COLOR = "SET_BACKGROUND_COLOR";
export const SET_TILE_SPEED = "SET_TILE_SPEED";
export const SET_TILE_SIZE = "SET_TILE_SIZE";
export const SET_MAXIMUM_SCALE = "SET_MAXIMUM_SCALE";
export const SET_TILE_MOVEMENT_DIRECTION = "SET_TILE_MOVEMENT_DIRECTION";
export const SET_ENABLE_BOUNDARIES = "SET_ENABLE_BOUNDARIES";
export const SET_ICONS_COLOR = "SET_ICONS_COLOR";
export const SET_ICONS_BORDER_COLOR = "SET_ICONS_BORDER_COLOR";
export const SET_PRESENTATION_TILES = "SET_PRESENTATION_TILES";
export const ADD_TILE = "ADD_TILE";
export const REMOVE_TILE = "REMOVE_TILE";
export const EDIT_TILE_TITLE = "EDIT_TILE_TITLE";
export const EDIT_TILE_SUBTITLE = "EDIT_TILE_SUBTITLE";
export const EDIT_TILE_TAGS = "EDIT_TILE_TAGS";
export const EDIT_TILE_DESCRIPTION = "EDIT_TILE_DESCRIPTION";
export const EDIT_TILE_ORDER = "EDIT_TILE_ORDER";
export const SET_FONT_COLOR = "SET_FONT_COLOR";
export const EDIT_TILE_GUEST_PASS = "EDIT_TILE_GUEST_PASS";
export const RESET_PRESENTATION = "RESET_PRESENTATION";
export const SET_PRESENTATION_SIZE = "SET_PRESENTATION_SIZE";
export const SET_FILE_OVERRIDE_BOOL = "SET_FILE_OVERRIDE_BOOL";

// tiles
export const SET_OUTLINE_COLOR = "SET_OUTLINE_COLOR";
export const SET_CURRENT_TILE_ID = "SET_CURRENT_TILE_ID";
export const SET_ALL_FILES = "SET_ALL_FILES";
export const REFRESH_FILES = "REFRESH_FILES";
export const SET_CURRENT_TILE_FILES = "SET_CURRENT_TILE_FILES";
export const REMOVE_FILE = "REMOVE_FILE";
export const EDIT_FILE = "EDIT_FILE";
export const EDIT_FILE_GUEST_PASS = "EDIT_FILE_GUEST_PASS";
export const RESET_TILES = "RESET_TILES";

// visit
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_SCREEN_VISIT = "SET_SCREEN_VISIT";
export const SET_TILES_VISIT = "SET_TILES_VISIT";
export const SET_SETTINGS_VISIT = "SET_SETTINGS_VISIT";
export const SET_EXPORT_VISIT = "SET_EXPORT_VISIT";
export const RESET_VISIT = "RESET_VISIT";
export const SET_BY_USER_VIEW = "SET_BY_USER_VIEW";
