import {
    SET_USER,
    SET_TOKEN,
    SET_PREVIOUS_TOKEN,
    SET_PRESENTATIONS,
    SET_EMAIL,
    SET_IS_AUTH,
    EDIT_PRESENTATION_ARCHIVAL,
    EDIT_PRESENTATION_COLOR,
    ADD_PRESENTATION,
    SET_AUTH_TOKEN,
    SET_COMPANY_ID,
    SET_COMPANY_NAME,
    SET_HIDDEN_COMPANY_ID,
    RESET_USER
} from '../constants/actionTypes'

const initialState = {
    user: '',
    email: '',
    token: undefined,
    previousToken: undefined,
    presentations: [],
    isAuth: false,
    authToken: undefined,
    companyName: '',
    companyId: '',
    hiddenCompanyId: ''
};

export default (state = initialState, action) => {
    switch(action.type){
        case SET_USER:
            return {
                ...state, 
                user: action.payload
            };
        case SET_TOKEN:
            return {
                ...state,
                 token: action.payload
            }
        case SET_PREVIOUS_TOKEN:
            window.sessionStorage.setItem('previous-token', action.payload)
            return {
                ...state,
                previousToken: action.payload
            }
        case SET_AUTH_TOKEN:
            return {
                ...state,
                authToken: action.payload
            }
        case SET_COMPANY_NAME:
            return {
                ...state,
                companyName: action.payload
            }
        case SET_COMPANY_ID:
            return {
                ...state,
                companyId: action.payload
            }
        case SET_HIDDEN_COMPANY_ID:
            return {
                ...state,
                hiddenCompanyId: action.payload
            }
        case SET_PRESENTATIONS:
            return {
                ...state,
                presentations: action.payload
            }
        case SET_EMAIL:
            return {
                ...state,
                email: action.payload
            }
        case SET_IS_AUTH:
            return {
                ...state,
                isAuth: action.payload
            }      
        case ADD_PRESENTATION:
            return {
                ...state,
                presentations: state.presentations.concat(action.payload)
            } 
        case EDIT_PRESENTATION_ARCHIVAL:
            return {
                ...state,
                presentations: state.presentations.map(    
                    (presentation) => presentation.id === action.payload.id ? {
                        ...presentation,
                        isArchived: action.payload.isArchived, 
                    } : presentation
                )    
            }   
        case EDIT_PRESENTATION_COLOR:
            return {
                ...state,
                presentations: state.presentations.map(    
                    (presentation) => presentation.id === action.payload.id ? {
                        ...presentation,
                        backgroundColor: action.payload.backgroundColor, 
                    } : presentation
                )    
            }   
        case RESET_USER:
            return {
                ...state,
                isAuth: false,
                token: undefined,
                authToken: undefined,
                companyName: '',
                companyId: '',
                user: '',
                presentations: []
            }         
        default:
            return state
    }
};