import axios from 'axios';
import store from '../store.js';

export const getUser = async(userId) => {
    const session = createSession();
    const response = await session.get(`/users/${userId}`);
    return response.data;
}

export const login = async(CredentialPair) => {
    const session = createSession();
    const response = await session.post('/login', CredentialPair);
    // console.log("LOGIN RESPONSE ", response.data);
    return response.data;
}

export const firebaseUser = async(user) => {
    const session = createSession();
    const response = await session.post('/firebaseUser', user);
    return response.data;
}

export const verify = async(token) => {
    const session = createSession();
    const response = await session.post('/verify', {token: token});
    return response.data;
}

export const resumeSession = async() => {
    const token = window.sessionStorage.getItem('token');
    // const previousToken = window.sessionStorage.getItem('previous-token');
  
    if (!token) {
      return;
    }
  
    let user;
  
    try {
      const response = await verify(token);
      user = response.user;

      if (user == null) {
        throw "Token invalid";
      }
      
      store.dispatch({type:"SET_USER", user: user})
      store.dispatch({type:"SET_TOKEN", token: token})
    } catch (error) {

    }
}  

export const getUserPresentations = async() => {
    const session = createSession();
    const response = await session.get(`/presentations`);
    return response.data;
}

export const getPresentationsByCompany = async(companyId) => {
    const session = createSession();
    const response = await session.get(`/presentations/byCompany/${companyId}`);
    return response;
}

export const getPresentationsByFirestore = async(companyId, presentationName) => {
    const session = createSession();
    const response = await session.get(`/presentations/getFirestore/${companyId}/${presentationName}`);
    return response;
}

export const getSelectedPresentation = async(presentationId) => {
    const session = createSession();
    const response = await session.get(`/presentations/${presentationId}`);
    return response.data;
}

export const createNewPresentation = async(presentationData) => {
    const session = createSession();
    const response = await session.post(`/presentations`, presentationData);
    return response;
}

export const editPresentation = async(presentationId, data) => {
    const session = createSession();
    const response = session.patch(`/presentations/${presentationId}`, data);
    return response;
}

export const getPresentationTiles = async(presentationId) => {
    const session = createSession();
    const response = session.get(`/tiles/getAll/${presentationId}`);
    return response;
}

export const createTile = async(tileData) => {
    const session = createSession();
    const response = session.post(`/tiles`, tileData);
    return response;
}

export const deleteTile = async(tileId) => {
    const session = createSession();
    const response = session.delete(`/tiles/${tileId}`);
    return response;
}

export const updateTile = async(tileId, tileData) => {
    const session = createSession();
    const response = session.patch(`/tiles/${tileId}`, tileData);
    return response;
}

export const getFiles = async(tileId) => {
    const session = createSession();
    const response = session.get(`/files/getAll/${tileId}`);
    return response;
}

export const createFile = async(tileId, file, isThumbnail, isGuestPass, uniqueImageThumbnail) => {
    const session = createSession();
    const response = session.post(`/files/${tileId}/${isThumbnail}/${isGuestPass}/${uniqueImageThumbnail}`, file);
    return response;
}

export const addVideoThumbnail = async(tileId, fileId, file) => {
    const session = createSession();
    const response = session.post(`/files/vidThumb/${tileId}/${fileId}`, file);
    return response;
}

export const deleteFile = async(fileId, tileId, presentationId) => {
    const session = createSession();
    const response = session.delete(`/files/${presentationId}/${tileId}/${fileId}`);
    return response;
}

export const updateFile = async(fileId, data) => {
    const session = createSession();
    const response = session.patch(`/files/${fileId}`, data);
    return response;
}

export const createBackground = async(presentationId, file) => {
    const session = createSession();
    const response = session.post(`/backgrounds/${presentationId}`, file);
    return response;
}

export const getBackground = async(presentationId) => {
    const session = createSession();
    const response = session.get(`/backgrounds/${presentationId}`);
    return response;
}

export const removeBackground = async(presentationId, backgroundId) => {
    const session = createSession();
    const response = session.delete(`/backgrounds/${presentationId}/${backgroundId}`);
    return response;
}

// export const buildFile = async(presentationId, userId) => {
//     const session = createSession();
//     const response = session.post(`/downloads/${presentationId}/${userId}`);
//     return response;
// }

export const buildFile = (presentationId, companyId, companyName) => {
    const session = createSession();
    const response = session.post(`/downloads/${presentationId}/${companyId}/${companyName}`);
    return response;  
}

export const beginDownload = async(presentationId) => {
    const session = createSession();
    const response = session.get(`/downloads/${presentationId}`);
    return response;
}

export const deleteADV = async(presentationId) => {
    const session = createSession();
    const response = session.get(`/downloads/${presentationId}`);
    return response;
}

export const getSelectedPresentationGP = async(presentationId) => {
    const session = createSession();
    const response = await session.get(`/guestpass/${presentationId}`);
    return response.data;
}

export const createTileGP = async(tileData) => {
    const session = createSession();
    const response = session.post(`/guestpass`, tileData);
    return response;
}

export const deleteTileGP = async(tileId) => {
    const session = createSession();
    session.delete(`/guestpass/${tileId}`);
}

export const createFileGP = async(tileId, file, isThumbnail, isGuestPass, uniqueImageThumbnail) => {
    const session = createSession();
    const response = session.post(`/guestpass/${tileId}/${isThumbnail}/${isGuestPass}/${uniqueImageThumbnail}`, file);
    return response;
}

export const deleteFileGP = async(fileId, tileId, presentationId) => {
    const session = createSession();
    const response = session.delete(`/guestpass/${presentationId}/${tileId}/${fileId}`);
    return response;
}

export function createSession() {
    const token = window.sessionStorage.getItem('token');
    return axios.create({
        validateStatus: function(){return true},
        baseURL: 'https://rb.f2bevents.com:3001',
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        },
    });
}