import { 
    SET_BACKGROUND_COLOR, 
    SET_BACKGROUND_IMAGE_URL,
    SET_PRESENTATION_ID,
    SET_TILE_SPEED, 
    SET_TILE_MOVEMENT_DIRECTION, 
    SET_ICONS_COLOR,
    SET_ICONS_BORDER_COLOR,
    SET_NEW_PRESENTATION,
    SET_PRESENTATION_TILES,
    REMOVE_TILE,
    ADD_TILE,
    EDIT_TILE_TITLE,
    EDIT_TILE_SUBTITLE,
    EDIT_TILE_TAGS,
    EDIT_TILE_DESCRIPTION,
    SET_BACKGROUND_IMAGE_ID,
    SET_FONT_COLOR,
    EDIT_TILE_GUEST_PASS,
    RESET_PRESENTATION,
    SET_PRESENTATION_SIZE,
    SET_FILE_OVERRIDE_BOOL,
    EDIT_TILE_ORDER
} from '../constants/actionTypes';

export function setNewPresentation(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_NEW_PRESENTATION,
            payload: bool
        });  
    }  
}; 

export function setFileOverrideBool(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_FILE_OVERRIDE_BOOL,
            payload: bool
        });  
    }  
}; 

export function setPresentationId(id) {  
    return dispatch => {  
        return dispatch({  
            type: SET_PRESENTATION_ID,
            payload: id
        });  
    }  
}; 

export function setBackgroundColor(color) {  
    return dispatch => {  
        return dispatch({  
            type: SET_BACKGROUND_COLOR,
            payload: color  
        });  
    }  
}; 

export function setBackgroundImage(url) {
    return dispatch => {  
        return dispatch({  
            type: SET_BACKGROUND_IMAGE_URL,
            payload: url
        });  
    }  
}

export function setBackgroundId(id) {
    return dispatch => {  
        return dispatch({  
            type: SET_BACKGROUND_IMAGE_ID,
            payload: id
        });  
    }  
}

export function setFontColor(color) {
    return dispatch => {  
        return dispatch({  
            type: SET_FONT_COLOR,
            payload: color
        });  
    }  
}

export function setTileSpeed(data) {  
    return dispatch => {  
        return dispatch({  
            type: SET_TILE_SPEED,
            payload: data 
        });  
    }  
}; 

export function setTileMovementDirection(data) {  
    return dispatch => {  
        return dispatch({  
            type: SET_TILE_MOVEMENT_DIRECTION,
            payload: data 
        });  
    }  
};

export function setIconsColor(color) {
    return dispatch => {
        return dispatch({
            type: SET_ICONS_COLOR,
            payload: color
        })
    }
}

export function setIconsBorderColor(color) {
    return dispatch => {
        return dispatch({
            type: SET_ICONS_BORDER_COLOR,
            payload: color
        })
    }
}

export function setPresentationTiles(data) {
    return dispatch => {
        return dispatch({
            type: SET_PRESENTATION_TILES,
            payload: data
        })
    }
}

export function removeTile(id) {
    return dispatch => {  
        return dispatch({  
            type: REMOVE_TILE,
            payload: id
        });  
    }  
}

export function addTile(tile) {  
    return dispatch => {  
        return dispatch({  
            type: ADD_TILE,
            payload: tile
        });  
    }  
}; 

export function editTileTitle(tileData) {  
    return dispatch => {  
        return dispatch({  
            type: EDIT_TILE_TITLE,
            payload: tileData
        });  
    }  
}; 

export function editTileSubtitle(tileData) {  
    return dispatch => {  
        return dispatch({  
            type: EDIT_TILE_SUBTITLE,
            payload: tileData
        });  
    }  
}; 

export function editTileTags(tileData) {  
    return dispatch => {  
        return dispatch({  
            type: EDIT_TILE_TAGS,
            payload: tileData
        });  
    }  
}; 

export function editTileDescription(tileData) {  
    return dispatch => {  
        return dispatch({  
            type: EDIT_TILE_DESCRIPTION,
            payload: tileData
        });  
    }  
}; 

export function editTileOrder(tileData) {
    return dispatch => {  
        return dispatch({  
            type: EDIT_TILE_ORDER,
            payload: tileData
        });  
    }  
}

export function editTileGuestPass(tileData) {  
    return dispatch => {  
        return dispatch({  
            type: EDIT_TILE_GUEST_PASS,
            payload: tileData
        });  
    }  
};

export function setPresentationSize(fileData) {  
    return dispatch => {  
        return dispatch({  
            type: SET_PRESENTATION_SIZE,
            payload: fileData
        });  
    }  
}; 

export function resetPresentation() {  
    return dispatch => {  
        return dispatch({  
            type: RESET_PRESENTATION
        });  
    }  
}; 