import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import f2b from '../assets/images/f2bbackground.jpg';
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { 
    setBackgroundColor,
    setBackgroundImage,
    setBackgroundId
} from '../actions/presentationActions';
import {
    setTilesVisit,
    setCurrentPage
} from '../actions/visitActions';
import {
    setAllFiles
} from '../actions/tileActions';
import {
    setBackgroundColorModalView
} from '../actions/viewActions';
import {
    createBackground,
    editPresentation,
    removeBackground
} from '../utils/API';
import birds from '../assets/images/birds.png';
import bubbles from '../assets/images/bubbles.png';
import yellow from '../assets/images/yellow.png';
import flower from '../assets/images/flower.png';
import mountain from '../assets/images/mountain.png';
import water from '../assets/images/water.png';
import flowers from '../assets/images/flowers.png';
import winter from '../assets/images/winter.png';
import driveicon from '../assets/images/driveicon.png'
import dropboxicon from '../assets/images/dropboxicon.png';
import ChangeBackgroundColorModal from '../components/ChangeBackgroundColorModal';

const StyledScreenContainer = styled.div`
    height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s all ease;
    background-color: white;
    position: relative;
    overflow: hidden;

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        height: calc(100vh - 64px);
    }

    .overlay {
        position: absolute;
        z-index: 1;
        height: calc(100vh - 80px);
        width: 100%;
        top: 0px;
        left: 0;
        overflow: hidden;

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: calc(100vh - 64px);
        }
    }

    .centering-box {
        z-index: 2;
        height: 90%;
        width: 80%;
        display: grid;
        grid-template-columns: 2fr 6fr;
        gap: 10px;

        @media (orientation: portrait) {
            height: 100%;
            width: 100%;
            grid-template-columns: auto;
            grid-template-rows: 600px 230px;
        }

        @media (orientation: portrait) and (max-width: 900px) {
            grid-template-rows: 500px 230px;
        }

        @media (max-width: 1367px) and (orientation: landscape) {
            height: 100%;
            width: 100%;
            grid-template-columns: auto;
            grid-template-rows: 500px 230px;
        }

        @media (max-width: 1200px) and (orientation: landscape) {
            grid-template-rows: 380px 180px;
        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            grid-template-rows: auto;
            grid-template-columns: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row-reverse;
            padding-bottom: 10%;
        }

        .options {
            grid-column: 1 / span 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;

            @media (orientation: portrait) {
                grid-column: auto;
                grid-row: 2 / span 1;
                flex-direction: row;
                justify-content: center;
            }

            @media (max-width: 1367px) and (orientation: landscape) {
                grid-column: auto;
                grid-row: 2 / span 1;
                flex-direction: row;
                justify-content: center;
            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                grid-row: auto;
                grid-column: auto;
                height: 100%;
                width: 50%;
            }

            .pre-selects {
                height: min-content;
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: flex-start;
                align-items: center;
                justify-content: center;
                margin-bottom: 40px;

                @media (orientation: portrait) {
                    width: 650px;
                    margin-bottom: 0px;
                }

                @media (orientation: portrait) and (max-width: 900px) {
                    width: 500px;
                    margin-bottom: 0px;
                }

                @media (max-width: 1367px) and (orientation: landscape) {
                    width: 840px;
                    margin-bottom: 0px;
                }

                @media (max-width: 1200px) and (orientation: landscape) {
                    width: 650px;
                }

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    width: 50%;
                    justify-content: flex-start
                }
                
                .selection {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 48%;
                    height: min-content;
                    margin: 1%;

                    @media (orientation: portrait) {
                        width: 148.5px;
                    }

                    @media (orientation: portrait) and (max-width: 900px) {
                        width: 96px;
                    }

                    @media (max-width: 1367px) and (orientation: landscape) {
                        width: 192px;
                        margin: 0.5%;
                    }

                    @media (max-width: 1200px) and (orientation: landscape) {
                        width: 148.5px;
                    }

                    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                        width: 64px;
                    }

                    div {
                        height: 110px;
                        width: 100%;
                        cursor: pointer;
                        box-shadow: 2px 2px 3px 1px #3b3b3b80;

                        @media (orientation: portrait) {
                            height: 81px;
                        }

                        @media (orientation: portrait) and (max-width: 900px) {
                            height: 54px;
                        }

                        @media (max-width: 1367px) and (orientation: landscape) {
                            height: 108px;
                        }

                        @media (max-width: 1200px) and (orientation: landscape) {
                            height: 81px;
                        }

                        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                            height: 36px;
                        }
                    }
                }
            }

            .controls {
                height: min-content;
                width: 70%;
                display: grid;
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr;

                @media (orientation: portrait) {
                    width: auto;
                }

                @media (max-width: 1367px) and (orientation: landscape) {
                    width: auto;
                }

                .button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 30px;
                    position: relative;

                    @media (orientation: portrait) {
                        margin-bottom: 0px;
                        padding: 10px;
                    }

                    @media (max-width: 1367px) and (orientation: landscape) {
                        margin-bottom: 0px;
                        padding: 10px;
                    }

                    div, label {
                        height: 80px;
                        border-radius: 50%;
                        width: 80px;
                        cursor: pointer;
                        box-shadow: 2px 2px 3px 1px #3b3b3b80;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        object-fit: contain;

                        @media (orientation: portrait) {
                            height: 60px;
                            width: 60px;
                        }

                        @media (max-width: 1200px) and (orientation: landscape) {
                            height: 60px;
                            width: 60px;
                        }

                        .data-icon {
                            @media (orientation: portrait) {
                                font-size: 40px !important;
                            }

                            @media (max-width: 1200px) and (orientation: landscape) {
                                font-size: 40px !important;
                            }
                        }

                        img {
                            height: 50%;
                            width: auto;
                            max-height: 50%;
                        }
                    }

                    .temp-overlay {
                        cursor: default;
                        box-shadow: none;
                        position: absolute;
                        // top: 0;
                        // left: 0;
                        background-color: #3b3b3b80;
                    }
                }
            }
        }

        .preview {
            grid-column: 2 / span 1;
            position: relative;

            @media (orientation: portrait) {
                grid-column: auto;
                grid-row: 1 / span 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            

            @media (max-width: 1367px) and (orientation: landscape) {
                grid-column: auto;
                grid-row: 1 / span 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                grid-row: auto;
                grid-column: auto;
                height: 100%;
                width: 50%;
            }

            .preview-image {
                position: absolute;
                top: 0;
                left: 0;
                height: 90%;
                width: 100%;
                z-index: 2;
                box-shadow: 3px 3px 6px 1px #3b3b3b80;
                display: flex;
                justify-content: center;
                align-items: center;

                @media (orientation: portrait) {
                    height: 432px;
                    width: 768px;
                    top: auto;
                    left: auto;
                }

                @media (orientation: portrait) and (max-width: 900px) {
                    height: 388.8px;
                    width: 691.2px;
                }

                @media (max-width: 1367px) and (orientation: landscape) {
                    height: 432px;
                    width: 768px;
                    top: auto;
                    left: auto;
                }

                @media (max-width: 1200px) and (orientation: landscape) {
                    height: 324px;
                    width: 576px;
                }

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    height: 162px;
                    width: 288px;
                }

                .change-color {
                    height: 60px;
                    width: 250px;
                    border-radius: 4px;
                    box-shadow: 1px 1px 1px 1px #80808080;
                    background-color: #3b3b3b;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 3;

                    @media (orientation: portrait) and (max-width: 900px) {
                        height: 50px;
                        width: 200px;
                    }

                    @media (max-width: 1200px) and (orientation: landscape) {
                        height: 50px;
                        width: 200px;
                    }

                    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                        height: 30px;
                        width: 100px;
                    }
    
                    p {
                        margin-block: 0px;
                        font-size: 28px;
                        color: white;
                        font-family: 'Crimson Text', serif;

                        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                            font-size: 16px;
                        }
                    }
                }
            }

            .banner {
                position: absolute;
                top: 5%;
                left: 30%;
                height: 60px;
                width: 40%;
                border-radius: 5px;
                background-color: #FFFFFFBF;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 3;

                @media (orientation: portrait) {
                    top: 2%;
                }

                @media (orientation: portrait) and (max-width: 900px) {
                    top: 1%;
                    left: calc(50% - 200px);
                    width: 400px;
                    height: auto;
                }

                @media (max-width: 1367px) and (orientation: landscape) {
                    top: 10%;
                }

                @media (max-width: 1200px) and (orientation: landscape) {
                    top: 20%;
                    width: 30%;
                    left: 35%;
                    height: 50px;
                }

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    display: none;
                }

                p {
                    font-size: 28px;
                    color: #3b3b3b;
                    margin-block: 0px;
                    font-family: 'Crimson Text', serif;
                    font-weight: 600;

                    @media (orientation: portrait) {
                        font-size: 20px;
                    }

                    @media (max-width: 1200px) and (orientation: landscape) {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .continue {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        right: 1.5%;
        bottom: 5%;
        height: 10%;
        width: 5%;
        border-radius: 24px;
        box-shadow: 1px 1px 2px 0px #3b3b3b80;

        @media (orientation: portrait) {
            height: 60px;
            width: 60px;
            top: calc(50% - 30px);
            border-radius: 8px;

            svg {
                font-size: 36px !important;
            }
        }

        @media (orientation: portrait) and (max-width: 900px) {
            height: 50px;
            width: 50px;
            top: 50%;
            border-radius: 8px;

            svg {
                font-size: 30px !important;
            }
        }

        @media (max-width: 1367px) and (orientation: landscape) {
            height: 80px;
            width: 80px;
            top: calc(50% - 40px);
        }

        @media (max-width: 1200px) and (orientation: landscape) {
            height: 60px;
            width: 60px;
            top: calc(50% - 30px);
            border-radius: 8px;

            svg {
                font-size: 36px !important;
            }
        }
    }
`;

const StyledInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

class ScreenChoice extends Component {

    constructor() {
        super();
        this.state = {
            animate: false
        }
    }

    componentDidMount = () => {
        // if (this.props.isAuth !== true) {
        //     this.props.history.push('/')
        // }
        setTimeout(() => {
            this.setState({ animate: true });
        }, 50);
    }

    uploadImage = (event) => {
        let file = event.target.files[0];
        if (event.target.files[0]) {
            console.log("FILE UPLOAD", file);
            let formData = new FormData();
            formData.append('file', file);
            if (this.props.backgroundId !== '') {
                removeBackground(this.props.presentationId, this.props.backgroundId).then((res)=> {
                    console.log("Background Removed: ", res);
                    this.props.setBackgroundImage('');
                    this.props.setBackgroundId('');
                    createBackground(this.props.presentationId, formData).then((res) => {
                        console.log("UPLOAD BACKGROUND IMAGE RESPONSE", res.data.file);
                        this.props.setBackgroundImage(res.data.file.url);
                        this.props.setBackgroundId(res.data.file.id);
                        let colorObject = {
                            backgroundColor: '#FFFFFF'
                        }
                        this.props.setBackgroundColor('#FFFFFF');
                        editPresentation(this.props.presentationId, colorObject);
                    });
                });
            } else {
                createBackground(this.props.presentationId, formData).then((res) => {
                    console.log("UPLOAD BACKGROUND IMAGE RESPONSE", res.data.file);
                    this.props.setBackgroundImage(res.data.file.url);
                    this.props.setBackgroundId(res.data.file.id);
                    let colorObject = {
                        backgroundColor: '#FFFFFF'
                    }
                    this.props.setBackgroundColor('#FFFFFF');
                    editPresentation(this.props.presentationId, colorObject);
                });
            }
        }
    }

    destroyBackground = () => {
        if (this.props.backgroundId !== '') {
            removeBackground(this.props.presentationId, this.props.backgroundId).then((res)=> {
                console.log("Background Removed: ", res);
                this.props.setBackgroundImage('');
                this.props.setBackgroundId('');
            });
        } else {
            return
        }
    }

    setColor = (color) => {
        let colorObject = {
            backgroundColor: color.hex
        }
        this.props.setBackgroundColor(color.hex);
        editPresentation(this.props.presentationId, colorObject);
    };

    handleModalShow = () => {
        this.props.setBackgroundColorModalView(!this.props.backgroundColorModalView);
    }

    loadXHR(url) {

        return new Promise(function(resolve, reject) {
            try {
                var xhr = new XMLHttpRequest();
                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.onerror = function() {reject("Network error.")};
                xhr.onload = function() {
                    if (xhr.status === 200) {resolve(xhr.response)}
                    else {reject("Loading error:" + xhr.statusText)}
                };
                xhr.send();
            }
            catch(err) {reject(err.message)}
        });
    }

    convertToBlob = (url, name) => {
        this.loadXHR(url).then((blob) => {
            console.log("LOADED BLOB", blob);
            let file = new File([blob], name, {
                type: "image/jpeg"
            });
            console.log("BLOB TO FILE", file);
            let formData = new FormData();
            formData.append('file', file);
            createBackground(this.props.presentationId, formData).then((res) => {
                console.log("UPLOAD BACKGROUND IMAGE RESPONSE", res.data.file);
                this.props.setBackgroundImage(res.data.file.url);
                this.props.setBackgroundId(res.data.file.id);
                let colorObject = {
                    backgroundColor: '#FFFFFF'
                }
                this.props.setBackgroundColor('#FFFFFF');
                editPresentation(this.props.presentationId, colorObject);
            });
        });
        
    }

    continue = () => {
        if (this.props.backgroundColor !== '' || this.props.backgroundImage !== '') {
            this.props.setTilesVisit(true);
            this.props.setCurrentPage('tiles')
            this.props.history.push('/tiles');
        }
    }
    

    render() {

        return(
            <div style={{overflow: 'hidden'}}>
                <ChangeBackgroundColorModal/>
                <StyledScreenContainer style={{opacity: this.state.animate ? 1 : 0}}>
                    <div className='overlay'
                        style={{
                            backgroundImage: `url(${f2b})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            opacity: '0.5'
                        }}
                    />
                    <div className='centering-box'>
                        <div className='options'>
                            <div className='pre-selects'>
                                <div className='selection'>
                                    <div
                                        style={{
                                            backgroundImage: `url(${birds})`,
                                            backgroundSize: "cover"
                                        }}
                                        onClick={() => this.convertToBlob(birds, "birds.png")}
                                    />
                                </div>
                                <div className='selection'>
                                    <div
                                        style={{
                                            backgroundImage: `url(${yellow})`,
                                            backgroundSize: "cover"
                                        }}
                                        onClick={() => this.convertToBlob(yellow, "yellow.png")}
                                    />
                                </div>
                                <div className='selection'>
                                    <div
                                        style={{
                                            backgroundImage: `url(${flower})`,
                                            backgroundSize: "cover"
                                        }}
                                        onClick={() => this.convertToBlob(flower, "flower.png")}
                                    />
                                </div>
                                <div className='selection'>
                                    <div
                                        style={{
                                            backgroundImage: `url(${mountain})`,
                                            backgroundSize: "cover"
                                        }}
                                        onClick={() => this.convertToBlob(mountain, "mountain.png")}
                                    />
                                </div>
                                <div className='selection'>
                                    <div
                                        style={{
                                            backgroundImage: `url(${water})`,
                                            backgroundSize: "cover"
                                        }}
                                        onClick={() => this.convertToBlob(water, "water.png")}
                                    />
                                </div>
                                <div className='selection'>
                                    <div
                                        style={{
                                            backgroundImage: `url(${flowers})`,
                                            backgroundSize: "cover"
                                        }}
                                        onClick={() => this.convertToBlob(flowers, "flowers.png")}
                                    />
                                </div>
                                <div className='selection'>
                                    <div
                                        style={{
                                            backgroundImage: `url(${bubbles})`,
                                            backgroundSize: "cover"
                                        }}
                                        onClick={() => this.convertToBlob(bubbles, "bubbles.png")}
                                    />
                                </div>
                                <div className='selection'>
                                    <div
                                        style={{
                                            backgroundImage: `url(${winter})`,
                                            backgroundSize: "cover"
                                        }}
                                        onClick={() => this.convertToBlob(winter, "winter.png")}
                                    />
                                </div>
                            </div>
                            <div className='controls'>
                                <ReactTooltip />
                                <div className='button'>
                                    <label 
                                        for="addImage"
                                        style={{
                                            backgroundColor: 'rgb(7, 104, 176)'
                                        }}
                                        // data-tip='Upload Background'
                                    >
                                        <PublishRoundedIcon 
                                            className='data-icon'
                                            style={{
                                                color: 'white',
                                                fontSize: '54px'
                                            }}
                                        />
                                    </label>
                                    <StyledInput type="file" multiple={false} accept=".jpg,.png" onChange={(event) => {this.uploadImage(event); event.target.value=""}} name="addImage" id="addImage"></StyledInput>
                                </div>
                                <div className='button'>
                                    <div
                                        style={{
                                            backgroundColor: '#c71306'
                                        }}
                                        onClick={() => this.destroyBackground()}
                                        // data-tip='Delete Background'
                                    >
                                        <CloseRoundedIcon 
                                            className='data-icon'
                                            style={{
                                                color: 'white',
                                                fontSize: '54px'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='button'>
                                    <div className='temp-overlay' data-tip='Feature Coming Soon' />
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            boxShadow: 'none'
                                        }}
                                    >
                                        <img 
                                            src={driveicon}
                                            alt='google-drive'
                                        />
                                    </div>
                                </div>
                                <div className='button'>
                                    <div className='temp-overlay' data-tip='Feature Coming Soon' />
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            boxShadow: 'none'
                                        }}
                                    >
                                        <img 
                                            src={dropboxicon}
                                            alt='dropbox'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div 
                            className='preview'
                            style={{

                            }}
                        >   
                            <div 
                                className='preview-image'
                                style={{
                                    backgroundImage: this.props.backgroundImage === '' || this.props.backgroundImage === undefined ? "none" : `url('${this.props.backgroundImage}')`,
                                    backgroundSize: "cover",
                                    // display: this.props.backgroundImage === '' || this.props.backgroundImage === undefined ? 'none' : 'block',
                                    backgroundColor: this.props.backgroundColor === '' ? `white` : `${this.props.backgroundColor}`,
                                }}
                            >
                                <div 
                                    className='change-color' 
                                    onClick={() => this.handleModalShow()}
                                    style={{
                                        visibility: this.props.backgroundImage === '' || this.props.backgroundImage === undefined ? 'visible' : 'hidden'
                                    }}
                                >
                                    <p>Color Select</p>
                                </div>
                            </div>
                            <div className='banner'>
                                <p>Presentation Background Preview</p>
                            </div>
                        </div>
                    </div>
                    <div 
                        className='continue'
                        style={{
                            backgroundColor: this.props.backgroundColor === '' && this.props.backgroundImage === '' ? "#7693a8" : "#2695D2",
                            cursor: this.props.backgroundColor === '' && this.props.backgroundImage === '' ? 'default' : 'pointer'
                        }}
                        onClick={() => this.continue()} 
                    >
                        <DoubleArrowRoundedIcon 
                            style={{
                                fontSize: '48px',
                                color: 'white'
                            }}
                        />
                    </div>
                </StyledScreenContainer>
            </div>
        );

    }
}

const mapStateToProps = state => {
    return {        
        backgroundColor: state.presentationReducer.color,
        backgroundImage: state.presentationReducer.backgroundImage,
        backgroundId: state.presentationReducer.backgroundId,
        isAuth: state.authReducer.isAuth,
        presentationId: state.presentationReducer.id
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setBackgroundColor: (color) => dispatch(setBackgroundColor(color)),
        setBackgroundImage: (img) => dispatch(setBackgroundImage(img)),
        setTilesVisit: (bool) => dispatch(setTilesVisit(bool)),
        setAllFiles: (file) => dispatch(setAllFiles(file)),
        setBackgroundId: (id) => dispatch(setBackgroundId(id)),
        setCurrentPage: (page) => dispatch(setCurrentPage(page)),
        setBackgroundColorModalView: (bool) => dispatch(setBackgroundColorModalView(bool))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenChoice);