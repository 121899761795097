import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
// import { SketchPicker } from 'react-color';
import f2b from '../assets/images/f2bbackground.jpg';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import CloudRoundedIcon from '@material-ui/icons/CloudRounded';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import {
    setOutlineColor,
    setCurrentTileId,
    setAllFiles, 
    removeFile
} from '../actions/tileActions';
import {
    setTileColorModalView,
    setFontColorModalView,
    setAddTileModalView,
    setTileModalView,
    setFileOverrideModalView
} from '../actions/viewActions';
import {
    addTile,
    removeTile,
    setFontColor,
    setPresentationSize,
    editTileOrder
} from '../actions/presentationActions';
import {
    setSettingsVisit,
    setCurrentPage
} from '../actions/visitActions'
import {
    createTile,
    deleteTile,
    createFile,
    // editPresentation,
    deleteFile,
    updateFile,
    updateTile
} from '../utils/API';
import { v4 as uuidv4 } from 'uuid';
import TileModal2 from '../components/TileModal2';
// import ReactTooltip from 'react-tooltip';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';
import ChangeTileColorModal from '../components/ChangeTileColorModal';
import ChangeFontColorModal from '../components/ChangeFontColorModal';
import CreateTileModal from '../components/CreateTileModal';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import ReactTooltip from 'react-tooltip';
import FileOverrideModal from '../components/FileOverrideModal.js';

const StyledTileContainer = styled.div`
    height: calc(100vh - 80px);
    width: 100%;
    transition: 0.4s all ease;
    background-color: white;
    position: relative;
    overflow: hidden;

    .choose-color-banner {
        position: absolute;
        top: 0;
        left: 0;
        height: 70px;
        width: 100%;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 5px;

        @media (max-width: 1367px) and (orientation: landscape) {
            width: 90%;
            left: 5%;
        }

        .color-select-button {
            height: 45px;
            margin: 0% 2%;
            width: 150px;
            background-color: #3b3b3b;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 18px; 
            box-shadow: 1px 1px 2px 1px #3b3b3b80;
            cursor: pointer;

            @media (max-width: 1025px) and (orientation: landscape) {
                height: 35px;
                width: 110px;
                border-radius: 3px;
            }

            @media (orientation: portrait) {

            }
    
            @media (orientation: portrait) and (max-width: 1025px) {
                height: 35px;
                width: 100px;
                border-radius: 3px;
            }

            p {
                color: white;
                font-family: 'Crimson Text', serif;
                margin-block: 0px;
                font-size: 17px;

                @media (orientation: portrait) {

                }
        
                @media (orientation: portrait) and (max-width: 1025px) {
                    font-size: 14px;
                }
            }
        }

        .tile-count-container {
            height: 45px;
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: absolute;
            top: 12.5px;
            left: 3.5%;
            overflow: hidden;

            p {
                font-size: 34px;
                font-family: 'Crimson Text', serif;
                font-weight: 600;
                margin-block: 0px;
                color: #3b3b3b;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                @media (max-width: 1367px) and (orientation: landscape) {
                    font-size: 28px;
                }

                @media (orientation: portrait) {

                }
        
                @media (orientation: portrait) and (max-width: 1025px) {
                    font-size: 24px;
                }
            }
        }

        .tooltip-container {
            position: absolute;
            height: 70px;
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: calc(3.5% + 200px);

            @media (max-width: 1025px) and (orientation: landscape) {
                left: calc(3.5% + 175px);

                svg {
                    font-size: 44px !important;
                }
            }

            @media (orientation: portrait) {

            }
    
            @media (orientation: portrait) and (max-width: 1025px) {
                display: none;
            }
        }

        .data-count-container {
            position: absolute;
            height: 70px;
            width: 20%;
            top: 0;
            right: 100px;
            display: grid;
            grid-template-columns: 15fr 85fr;
            grid-template-rows: 2fr 3fr;

            @media (max-width: 1025px) and (orientation: landscape) {
                right: 30px;
                width: 25%;
            }

            @media (orientation: portrait) {

            }
    
            @media (orientation: portrait) and (max-width: 1025px) {
                right: 5px;
                width: 25%;
            }

            .icon-box {
                grid-row: 2 / span 1;
                grid-column: 1 / span 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .text-box {
                grid-row: 1 / span 1;
                grid-column: 2 / span 1;
                display: flex;
                justify-content: Flex-start;
                align-items: flex-end;

                p {
                    font-size: 14px;
                    color: #3b3b3bBF;
                    font-weight: 600;
                    margin-block: 0px;
                }
            }

            .bar-box {
                grid-row: 2 / span 1;
                grid-column: 2 / span 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .tile-container {
        position: absolute;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        align-content: flex-start;
        z-index: 2;
        left: 0px;
        height: calc(100% - 70px);
        width: 100%;
        top: 70px;
        padding-left: 3%;
        padding-right: 1%;
        overflow-y: auto;

        @media (max-width: 1367px) and (orientation: landscape) {
            top: 100px;
            height: calc(65% - 70px);
            width: 90%;
            left: 5%;
            padding-left: 0%;
            padding-right: 0%;
        }

        @media (max-width: 1025px) and (orientation: landscape) {
            top: 70px;
        }

        @media (orientation: portrait) {

        }

        @media (orientation: portrait) and (max-width: 1025px) {
            height: calc(65% - 70px);
            width: 90%;
            left: 5%;
            padding-left: 0%;
            padding-right: 0%;
        }

        .tile {
            height: 250px;
            width: 18%;
            margin: 0.5%;
            border-radius: 3px;
            box-shadow: 4px 5px 14px 0px #3b3b3b;
            object-fit: contain;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: white;
            position: relative;
            transition: 0.3s ease all;

            p {
                user-select: none;
            }

            @media (max-width: 1367px) and (orientation: landscape) {
                width: 23%;
                margin: 1%;
                height: 225px;
            }

            @media (max-width: 1025px) and (orientation: landscape) {
                width: 29.33%;
                margin: 2%;
            }

            @media (orientation: portrait) {

            }
    
            @media (orientation: portrait) and (max-width: 1025px) {
                width: 46%;
                margin: 2%;
                height: 250px;
            }

            @media (orientation: portrait) and (max-width: 900px) {
                height: 200px;
            }

            .tile-title {
                height: 35px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                padding-left: 10px;
                padding-right: 10px;

                p {
                    font-size: 16px;
                    color: white;
                    font-weight: 600;
                    font-family: 'Crimson Text', serif;
                    margin-block: 0px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;  
                }
            }

            .tile-file-count {
                height: 35px;
                width: 50%;
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                overflow: hidden;
                padding-left: 10px;
                padding-right: 10px;

                p {
                    font-size: 14px;
                    color: white;
                    font-weight: 600;
                    font-family: 'Crimson Text', serif;
                    margin-block: 0px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;  
                }
            }

            .tile-delete {
                height: 35px;
                width: 12%;
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }

            .add-tile-button, label {
                height: 60px;
                width: 70%;
                margin: 3%;
                background-color: rgb(7, 104, 176);
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 5px;
                box-shadow: 2px 2px 2px 0px #3b3b3b80;

                p {
                    color: white;
                    margin-block: 0px;
                    font-size: 16px;
                    font-weight: 600;
                }
            }

            label {
                color: white;
                margin-block: 0px;
                font-size: 16px;
                font-weight: 600;
            }

            .tile-thumbnail {
                width: calc(100% - 8px);
                height: 180px;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border-radius: 2px;
                cursor: pointer;

                @media (max-width: 1367px) and (orientation: landscape) {
                    height: 150px;
                    width: calc(100% - 16px);
                }

                @media (orientation: portrait) {

                }
        
                @media (orientation: portrait) and (max-width: 1025px) {
                    height: 175px;
                }

                @media (orientation: portrait) and (max-width: 900px) {
                    height: 135px;
                }

                .spinner-box {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    z-index: 2;
                }
            }
        }
    }

    .overlay {
        position: absolute;
        z-index: 1;
        height: calc(100vh - 80px);
        width: 100%;
        top: 0px;
        left: 0;
        overflow: hidden;
    }

    .continue {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 3;
        right: 1.5%;
        bottom: 5%;
        height: 10%;
        width: 5%;
        border-radius: 24px;
        box-shadow: 1px 1px 2px 0px #3b3b3b80;

        @media (max-width: 1367px) and (orientation: landscape) {
            right: calc(50% - 40px);
            height: 80px;
            width: 80px;
            bottom: 17%;

            svg {
                font-size: 44px !important;
            }
        }

        @media (max-width: 1025px) and (orientation: landscape) {
            right: calc(50% - 30px);
            height: 60px;
            width: 60px;
            bottom: 20%;
            border-radius: 8px;
            bottom: 17%;

            svg {
                font-size: 36px !important;
            }
        }

        @media (orientation: portrait) {

        }

        @media (orientation: portrait) and (max-width: 1025px) {
            right: calc(50% - 30px);
            height: 60px;
            width: 60px;
            bottom: 20%;
            border-radius: 8px;
            bottom: 20%;

            svg {
                font-size: 36px !important;
            }
        }
    }
`;

const StyledInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

const StyledFileError = styled.div`
    width: 230px;
    height: 150px;
    background-color: #3b3b3b;
    position: absolute;
    left: calc(50% - 115px);
    top: calc(50% - 75px);
    box-shadow: 2px 2px 3px 1px #3b3b3b80;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding: 1%;

    p {
        margin-block: 0px;
        color: white;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }
`;

const StyledPreviewIconContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
`;

class TileChoice extends Component {

    constructor() {
        super();
        this.state = {
            animate: false,
            addTileHoverState: false,
            errorVisible: false,
            errorMessage: '',
            pressedKeys: [],
            windowDimensions: this.getWindowDimensions(),
            underTile: null
        }
    }

    getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
    }

    handleResize = () => {
        this.setState({ windowDimensions: this.getWindowDimensions() })
    }

    handleKeyDown = (event) => {
        if (event.key === 'j') {
            let keys = this.state.pressedKeys;
            keys.push(event.key);
            this.setState({ pressedKeys: keys });
        } else if (event.key === 'o' && this.state.pressedKeys.includes("j")) {
            let keys = this.state.pressedKeys;
            keys.push(event.key);
            this.setState({ pressedKeys: keys }); 
        } else if (event.key === 'e' && this.state.pressedKeys.includes("j") && this.state.pressedKeys.includes("o")) {
            let keys = this.state.pressedKeys;
            keys.push(event.key);
            this.setState({ pressedKeys: keys }); 
        } else if (event.key === '4' && this.state.pressedKeys.includes("j") && this.state.pressedKeys.includes("o") && this.state.pressedKeys.includes("e")) {
            let keys = this.state.pressedKeys;
            keys.push(event.key);
            this.setState({ pressedKeys: keys }); 
        } else if (event.key === '1' && this.state.pressedKeys.includes("j") && this.state.pressedKeys.includes("o") && this.state.pressedKeys.includes("e") && this.state.pressedKeys.includes("4")) {
            this.setState({ pressedKeys: [] });
            this.props.setFileOverrideModalView(!this.props.fileOverrideModalView);
        } else {
            this.setState({ pressedKeys: [] });
        }
    }

    integerAndNullSort = (ascending) => {
        return function (a, b) {
            if (a.order === b.order) {
                return 0;
            }
            else if (a.order === null) {
                return 1;
            }
            else if (b.order === null) {
                return -1;
            }
            else if (ascending) {
                return a.order < b.order ? -1 : 1;
            }
            else { 
                return a.order < b.order ? 1 : -1;
            }
        };
    }

    componentDidMount = () => {
        if (this.props.isAuth !== true) {
            this.props.history.push('/')
        }
        setTimeout(() => {
            this.setState({ animate: true });
        }, 50);
        let sortedArray = this.props.presentationTiles.sort(this.integerAndNullSort(true));
        for (let i = 0; i < sortedArray.length; i++) {
            if (!sortedArray[i].order || sortedArray[i].order === null) {
                let orderState = {
                    id: sortedArray[i].id,
                    order: i
                }
                let newOrder = {
                    order: i
                }
                this.props.editTileOrder(orderState);
                updateTile(sortedArray[i].id, newOrder);
            }
        }
        window.addEventListener("keydown", this.handleKeyDown);
        window.addEventListener('resize', this.handleResize);
        return () => window.removeEventListener('resize', this.handleResize);
    }

    componentWillUnmount = () => {
        window.removeEventListener("keydown", this.handleKeyDown) 
    }

    removeTile = (tileId) => {
        let filteredFiles = this.props.files.filter(file => file.tileId === tileId);
        let tileOrder = this.props.presentationTiles.find(tile => tile.id === tileId).order;
        let otherTiles = this.props.presentationTiles.filter(tile => tile.id !== tileId);
        console.log("FILES BEING DELETED", filteredFiles);
        for (let i = 0; i < filteredFiles.length; i++) {
            let thumbnailFile = this.props.files?.find(file => file?.thumbnailOfId  === filteredFiles[i].id);
            if (thumbnailFile) {
                deleteFile(thumbnailFile.id, tileId, this.props.presentationId).then((thumbRes) => {
                    console.log("REMOVING THUMB FOR FILE", thumbRes);
                    this.props.removeFile(thumbnailFile.id);
                    deleteFile(filteredFiles[i].id, tileId, this.props.presentationId).then((fileRes) => {
                        console.log("REMOVING FILE IN TILE", fileRes);
                        this.props.removeFile(filteredFiles[i].id);
                        let newList = this.props.files?.filter(file => file?.tileId === tileId);
                        if (!newList.length) {
                            this.props.removeTile(tileId);
                            deleteTile(tileId).then(deleteTileRes => {
                                for (let tempTile of otherTiles) { // for every file except the tile we're removing (we do this to avoid possible async state update not being fast enough)
                                    if (tempTile.order !== null) { // check null order
                                        if (tempTile.order > tileOrder) { // if the tile was after the removed tile in order
                                            // push the order back one in state and database
                                            let orderState = {
                                                id: tempTile.id,
                                                order: tempTile.order - 1
                                            }
                                            let newOrder = {
                                                order: tempTile.order - 1
                                            }
                                            this.props.editTileOrder(orderState);
                                            updateTile(tempTile.id, newOrder);
                                        } 
                                    }
                                }
                            });
                        }
                    });
                });
            } else {
                deleteFile(filteredFiles[i].id, tileId, this.props.presentationId).then((fileRes) => {
                    console.log("REMOVING FILE IN TILE", fileRes);
                    this.props.removeFile(filteredFiles[i].id);
                    let newList = this.props.files?.filter(file => file?.tileId === tileId);
                    if (!newList.length) {
                        this.props.removeTile(tileId);
                        deleteTile(tileId).then(deleteTileRes => {
                            for (let tempTile of otherTiles) { // for every file except the tile we're removing (we do this to avoid possible async state update not being fast enough)
                                if (tempTile.order !== null) { // check null order
                                    if (tempTile.order > tileOrder) { // if the tile was after the removed tile in order
                                        // push the order back one in state and database
                                        let orderState = {
                                            id: tempTile.id,
                                            order: tempTile.order - 1
                                        }
                                        let newOrder = {
                                            order: tempTile.order - 1
                                        }
                                        this.props.editTileOrder(orderState);
                                        updateTile(tempTile.id, newOrder);
                                    } 
                                }
                            }
                        });
                    }
                });
            }
        }
        if (!filteredFiles.length) {
            deleteTile(tileId);
            this.props.removeTile(tileId);
        }
    }

    tempHideTile = (tileId) => {
        let filteredFiles = this.props.files.filter(file => file.tileId === tileId);
        console.log("FILES BEING HIDDEN", filteredFiles);
        for (let i = 0; i < filteredFiles.length; i++) {
            let update = {
                isTempHidden: true
            }
            updateFile(filteredFiles[i].id, update).then((fileRes) => {
                console.log(fileRes);
                this.props.removeFile(filteredFiles[i].id);
                if ((filteredFiles.length - i) === 1) {
                    this.props.removeTile(tileId);
                    updateTile(tileId, update);
                }
            });
        }
        if (!filteredFiles.length) {
            let update = {
                isTempHidden: true
            }
            updateTile(tileId, update);
            this.props.removeTile(tileId);
        }
    }

    multiUpload = (event, totalSize) => {
        let tempTileArray = [...this.props.presentationTiles];
        console.log("TEMP TILE ARRAY", tempTileArray);
        for (let i = 0; i < event.target.files.length; i++) {
            let highest = -1;
            if (tempTileArray.length) {
                for (let tile of tempTileArray) {
                    if (tile.order !== null) {
                        if (tile.order > highest) {
                            highest = tile.order;
                        }
                    }
                }
            }
            let orderValue;
            if (highest !== null) {
                orderValue = highest + 1;
            } else {
                orderValue = 0;
            }
            let tileId = uuidv4();
            let newTile = {
                id: tileId,
                order: orderValue,
                presentationId: this.props.presentationId,
                files: [],
                ContentPaths: null,
                PrimaryThumbnailPath: null,
                SecondaryThumbnailPath: null,
                isGuestPass: false,
                Title: "",
                SubTitle: "",
                Description: "",
                Tags: ""
            }
            // event.target.files[i].name = encodeURIComponent(event.target.files[i].name);
            let file = event.target.files[i];
            console.log("FILE", file)
            // file.name = encodeURIComponent(file.name);
            // console.log("NEW FILE", file);
            this.props.addTile(newTile);
            tempTileArray.push(newTile);
            createTile(newTile).then((res) => {
                let uploadFile = file;
                let formData = new FormData();
                formData.append('file', uploadFile);
                console.log("DATA RECEIVED FROM UPLOADED FILE", uploadFile);
                if ((file.size && file.size < 100000000 && (totalSize + file.size < 500000000)) || (file.size && this.props.fileOverrideBool)) {
                    let thumbBool = false;
                    if (uploadFile.type === "image/jpeg" || uploadFile.type === "image/png") {
                        thumbBool = true;
                    }
                    let fillerId = uuidv4();
                    let fillerFile = {
                        id: fillerId,
                        type: 'filler',
                        tileId: tileId
                    };
                    this.props.setAllFiles(fillerFile);
                    createFile(tileId, formData, thumbBool, false, false).then((res) => {
                        if (res.data.errors) {
                            this.setState({errorMessage: `File ${res.data.errors[0].value} too large or already exists within tile.`});
                            this.setState({errorVisible: true});
                            setTimeout(() => {
                                this.setState({errorVisible: false});
                            }, 3000);
                            this.props.removeFile(fillerId);
                            deleteTile(tileId);
                            this.props.removeTile(tileId);
                        } else {
                            console.log("FINAL FILE", res.data);
                            this.props.setAllFiles(res.data.file);
                            this.props.removeFile(fillerId);
                        }
                    });
                } else {
                    if (file.size && (totalSize + file.size > 500000000)) {
                        this.setState({errorMessage: 'Data limit reached.'});
                    } else {
                        this.setState({errorMessage: `Uploaded files (${uploadFile.name}) cannot be larger than 100MB.`});
                    }
                    this.setState({errorVisible: true});
                    setTimeout(() => {
                        this.setState({errorVisible: false});
                    }, 3000);
                    deleteTile(tileId);
                    this.props.removeTile(tileId);
                }
            })
        }
    }

    continue = () => {
        if (this.props.outlineColor !== '' && this.props.presentationTiles.length !== 0) {
            this.props.setSettingsVisit(true);
            this.props.setCurrentPage('settings')
            this.props.history.push('/settings');
        }
    }
    
    handleAddTileHover = (state) => {
        this.setState({ addTileHoverState: state });
    }

    handleTileColorModalShow = () => {
        this.props.setTileColorModalView(!this.props.tileColorModalView);
    }

    handleFontColorModalShow = () => {
        this.props.setFontColorModalView(!this.props.fontColorModalView);
    }

    handleAddTileModalShow = () => {
        this.props.setAddTileModalView(!this.props.addTileModalView);
    }

    handleTileModalShow = () => {
        this.props.setTileModalView(!this.props.tileModalView);
    }

    handleTileOnClick = (tileId) => {
        this.props.setCurrentTileId(tileId);
        this.handleTileModalShow();
    }

    render() {

        // console.log("SIZE", this.state.windowDimensions);

        let totalFileSize = 0;
        for (let file of this.props.files?.filter(file => !file?.isGuesPass && !file?.isTempHidden)) {
            if (file?.size) {
                totalFileSize += file.size;
            }
        }
        let storageCount;
        if (totalFileSize > 0) {
            storageCount = (totalFileSize / 1000000000).toFixed(3);
        } else {
            storageCount = 0;
        }
        let storagePercent;
        if (totalFileSize > 0) {
            storagePercent = ((totalFileSize/10000000) / 5).toFixed(1);
        } else {
            storagePercent = 0;
        }
        let tileSelection;
        let tileCount = this.props.presentationTiles?.length ? this.props.presentationTiles.filter(tile => !tile.isTempHidden && !tile.isGuestPass).length : 0;
        // console.log("TILES", this.props.presentationTiles);


        // console.log("SORTED TILES", this.props.presentationTiles.sort(this.integerAndNullSort(true)));
        if (this.props.presentationTiles?.length) {
            tileSelection = (this.props.presentationTiles.filter(tile => !tile.isGuestPass && !tile.isTempHidden).sort(this.integerAndNullSort(true)).map((tile) => {
                let thumbnail = this.props.files?.length ? this.props.files.find(file => (file?.isThumbnail && file?.tileId === tile.id)) : undefined;
                let filler = this.props.files?.length ? this.props.files.find(file => (file?.tileId === tile.id && file?.type === 'filler')) : undefined;
                let fileCount = this.props.files?.length ? this.props.files.filter(file => (file?.tileId === tile.id) && !file.isTempHidden && !file.thumbnailOfId && !file.uniqueImageThumbnail)?.length : 0;
                let video = this.props.files?.length ? this.props.files.find(file => (file?.tileId === tile.id && (file.type === 'video/x-mastroka' || file.type === 'video/mp4' || file.type === 'video/mov'))) : undefined;
                let pdf = this.props.files?.length ? this.props.files.find(file => (file?.tileId === tile.id && (file.type === 'application/pdf'))) : undefined;
                return(
                    <div 
                        id={tile.order}
                        key={tile.id}
                        className='tile'
                        style={{
                            backgroundColor: this.props.outlineColor !== '' ? this.props.outlineColor : '#3b3b3b'
                        }}
                        draggable={true}
                        onDragEnter={(event) => {
                            if (this.state.underTile !== tile.order) {
                                this.setState({ underTile: tile.order });
                            }
                        }}
                        onDragEnd={() => {
                            if (this.state.underTile !== null && this.state.underTile !== tile.order) {
                                let oldOrder = tile.order;
                                let otherTiles = this.props.presentationTiles.filter(filteredTile => filteredTile.id !== tile.id);
                                otherTiles.sort(this.integerAndNullSort(true));
                                if (this.state.underTile < oldOrder) {
                                    for (let tempTile of otherTiles) {
                                        if (tempTile.order !== null) {
                                            if ((tempTile.order >= this.state.underTile) && (tempTile.order < oldOrder)) {
                                                let orderState = {
                                                    id: tempTile.id,
                                                    order: tempTile.order + 1
                                                }
                                                let newOrder = {
                                                    order: tempTile.order + 1
                                                }
                                                this.props.editTileOrder(orderState);
                                                updateTile(tempTile.id, newOrder);
                                            } 
                                        }
                                    }
                                } else if (this.state.underTile > oldOrder) {
                                    for (let tempTile of otherTiles) {
                                        if (tempTile.order !== null) {
                                            if ((tempTile.order <= this.state.underTile) && (tempTile.order > oldOrder)) {
                                                let orderState = {
                                                    id: tempTile.id,
                                                    order: tempTile.order - 1
                                                }
                                                let newOrder = {
                                                    order: tempTile.order - 1
                                                }
                                                this.props.editTileOrder(orderState);
                                                updateTile(tempTile.id, newOrder);
                                            } 
                                        }
                                    }
                                }
                                let orderState = {
                                    id: tile.id,
                                    order: this.state.underTile
                                }
                                let newOrder = {
                                    order: this.state.underTile
                                }
                                this.props.editTileOrder(orderState);
                                updateTile(tile.id, newOrder);
                            }
                        }}
                        // onDragLeave={(event) => {
                        //     this.setState({ underTile: null})
                        // }}
                    >
                        <div 
                            className='tile-file-count'
                            onDragEnter={(event) => {
                                if (this.state.underTile !== tile.order) {
                                    this.setState({ underTile: tile.order });
                                }
                            }}
                        >
                            <p 
                                style={{color: this.props.fontColor}}
                                onDragEnter={(event) => {
                                    if (this.state.underTile !== tile.order) {
                                        this.setState({ underTile: tile.order });
                                    }
                                }}
                            >
                                {fileCount} File{fileCount === 1 ? '' : 's'}
                            </p>
                        </div>
                        <div 
                            className='tile-delete' 
                            onClick={() => this.removeTile(tile.id)}
                            onDragEnter={(event) => {
                                if (this.state.underTile !== tile.order) {
                                    this.setState({ underTile: tile.order });
                                }
                            }}
                        >
                            <CancelRoundedIcon
                                style={{
                                    color: this.props.fontColor,
                                    fontSize: '28px'
                                }}
                                onDragEnter={(event) => {
                                    if (this.state.underTile !== tile.order) {
                                        this.setState({ underTile: tile.order });
                                    }
                                }}
                            />
                        </div>
                        <div 
                            className='tile-thumbnail'
                            style={{
                                backgroundImage: thumbnail ? `url('${thumbnail.url}')` : 'none',
                                backgroundSize: "cover"
                            }}
                            onDragEnter={(event) => {
                                if (this.state.underTile !== tile.order) {
                                    this.setState({ underTile: tile.order });
                                }
                            }}
                            onClick={() => this.handleTileOnClick(tile.id)}
                        >
                            {filler !== undefined && fileCount === 1 ? (
                                <div 
                                    className='spinner-box'
                                    onDragEnter={(event) => {
                                        if (this.state.underTile !== tile.order) {
                                            this.setState({ underTile: tile.order });
                                        }
                                    }}
                                >
                                    <Spinner 
                                        animation="border" 
                                        role="status" 
                                        variant="dark"
                                        onDragEnter={(event) => {
                                            if (this.state.underTile !== tile.order) {
                                                this.setState({ underTile: tile.order });
                                            }
                                        }}
                                    >
                                        <span 
                                            className="sr-only"
                                            onDragEnter={(event) => {
                                                if (this.state.underTile !== tile.order) {
                                                    this.setState({ underTile: tile.order });
                                                }
                                            }}
                                        >
                                            Loading...
                                        </span>
                                    </Spinner>
                                </div>
                            ) : null}
                            <StyledPreviewIconContainer
                                onDragEnter={(event) => {
                                    if (this.state.underTile !== tile.order) {
                                        this.setState({ underTile: tile.order });
                                    }
                                }}
                            >
                                {thumbnail !== undefined ? (
                                    <ImageRoundedIcon 
                                        style={{
                                            fontSize: '40px',
                                            color: this.props.outlineColor !== '' ? this.props.outlineColor : '#0768b0',
                                            margin: '1%'
                                        }} 
                                        onDragEnter={(event) => {
                                            if (this.state.underTile !== tile.order) {
                                                this.setState({ underTile: tile.order });
                                            }
                                        }}
                                    />
                                ) : null}
                                {video !== undefined ? (
                                    <PlayCircleFilledWhiteRoundedIcon 
                                        style={{
                                            fontSize: '40px',
                                            color: this.props.outlineColor !== '' ? this.props.outlineColor : '#0768b0',
                                            margin: '1%'
                                        }}
                                        onDragEnter={(event) => {
                                            if (this.state.underTile !== tile.order) {
                                                this.setState({ underTile: tile.order });
                                            }
                                        }}
                                    />
                                ) : null}
                                {pdf !== undefined ? (
                                    <PictureAsPdfRoundedIcon
                                        style={{
                                            fontSize: '40px',
                                            color: this.props.outlineColor !== '' ? this.props.outlineColor : '#0768b0',
                                            margin: '1%'
                                        }}
                                        onDragEnter={(event) => {
                                            if (this.state.underTile !== tile.order) {
                                                this.setState({ underTile: tile.order });
                                            }
                                        }}
                                    />
                                ) : null}
                            </StyledPreviewIconContainer>
                        </div>
                        <div 
                            className='tile-title'
                            onDragEnter={(event) => {
                                if (this.state.underTile !== tile.order) {
                                    this.setState({ underTile: tile.order });
                                }
                            }}
                        >
                            <p 
                                style={{color: this.props.fontColor}}
                                onDragEnter={(event) => {
                                    if (this.state.underTile !== tile.order) {
                                        this.setState({ underTile: tile.order });
                                    }
                                }}
                            >
                                {tile.Title} {tile.Title !== "" && tile.SubTitle !== "" ? "-" : null} {tile.SubTitle}
                            </p>
                        </div>
                    </div>
                );
            }));
        } else {
            tileSelection = null;
        }

        return(
            <>
                <ChangeTileColorModal/>
                <ChangeFontColorModal/>
                <FileOverrideModal />
                <CreateTileModal
                    totalFileSize={totalFileSize}
                />
                <TileModal2
                    totalFileSize={totalFileSize}
                />
                <StyledFileError style={{
                    visibility: this.state.errorVisible ? 'visible' : 'hidden'
                }}>
                    <p>{this.state.errorMessage}</p>
                </StyledFileError>
                <StyledTileContainer 
                    style={{opacity: this.state.animate ? 1 : 0}}
                >
                    <div className='overlay'
                        style={{
                            backgroundImage: `url(${f2b})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            opacity: '0.5'
                        }}
                    />
                    <div className='choose-color-banner'>
                        <div className='tile-count-container'>
                            <p>{tileCount} tile{tileCount === 1 ? null : 's'} created</p>
                        </div>
                        <div className='color-select-button' onClick={() => this.handleTileColorModalShow()}>
                            <p>Tile Color</p>
                        </div>
                        <div className='color-select-button' onClick={() => this.handleFontColorModalShow()}>
                            <p>Font Color</p>
                        </div>
                        <div className='tooltip-container'>
                            <InfoRoundedIcon 
                                data-tip="Thumbnails may not be to scale - tile size will scale to fit thumbnail resolution within the Presentation Board App"
                                style={{
                                    color: '#3b3b3b',
                                    fontSize: '54px',
                                    cursor: 'pointer'
                                }}
                            />
                        </div>
                        <div className='data-count-container'>
                            <div className='icon-box'>
                                <ReactTooltip place='top'/>
                                <CloudRoundedIcon 
                                    data-tip="If encountering errors in constructing your presentation, please contact operations@f2bservices.com"
                                    style={{
                                        fontSize: '24px',
                                        color: '#3b3b3b',
                                        cursor: 'pointer'
                                    }}
                                />
                            </div>
                            <div className='text-box'>
                                <p>{storageCount} GB of {this.props.fileOverrideBool ? '∞' : 5} GB used</p>
                            </div>
                            <div className='bar-box'>
                                <Progress
                                    percent={this.props.fileOverrideBool ? 0 : storagePercent}
                                    theme={{
                                        success: {
                                            color: '#c43737',
                                            symbol: null
                                        },
                                        active: {
                                            color: '#37c43e',
                                            symbol: null
                                        },
                                        default: {
                                            color: '#3b3b3b'
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='tile-container'>
                        <div className='tile' onPointerEnter={() => this.handleAddTileHover(true)} onPointerLeave={() => this.handleAddTileHover(false)}>
                            {this.state.addTileHoverState || (this.state.windowDimensions.width <= 1366 && this.state.windowDimensions.height <= 1024 && this.state.windowDimensions.width > this.state.windowDimensions.height) || (this.state.windowDimensions.width <= 1024 && this.state.windowDimensions.height <= 1366 && this.state.windowDimensions.width < this.state.windowDimensions.height) ? (
                                <>
                                    <div className='add-tile-button' onClick={() => this.handleAddTileModalShow()}>
                                        <p>Add Single Tile</p>
                                    </div>
                                    <label for="addTiles" className='add-tile-button'>Multiple Tiles</label>
                                    <StyledInput type="file" multiple={true} accept=".jpg,.png,.mp4,.pdf" onChange={(event) => {this.multiUpload(event, totalFileSize); event.target.value=""}} name="addTiles" id="addTiles"></StyledInput>
                                </>
                            ) : (
                                <AddRoundedIcon 
                                    style={{
                                        fontSize: '84px',
                                        color: '#3b3b3b',
                                    }}
                                />
                            )}
                        </div>
                        {tileSelection}
                    </div>
                    <div 
                        className='continue'
                        style={{
                            backgroundColor: this.props.outlineColor === '' || this.props.presentationTiles.length === 0 ? "#7693a8" : "#2695D2",
                            opacity: this.props.outlineColor === '' || this.props.presentationTiles.length === 0 ? 0.4 : 1,
                            cursor: this.props.outlineColor === '' || this.props.presentationTiles.length === 0 ? 'default' : 'pointer'
                        }}
                        onClick={() => this.continue()} 
                    >
                        <DoubleArrowRoundedIcon 
                            style={{
                                fontSize: '48px',
                                color: 'white'
                            }}
                        />
                    </div>
                </StyledTileContainer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {        
        outlineColor: state.tileReducer.outlineColor,
        isAuth: state.authReducer.isAuth,
        presentationId: state.presentationReducer.id,
        presentationTiles: state.presentationReducer.presentationTiles,
        files: state.tileReducer.files,
        fontColor: state.presentationReducer.fontColor,
        tileColorModalView: state.viewReducer.tileColorModalView,
        fontColorModalView: state.viewReducer.fontColorModalView,
        addTileModalView: state.viewReducer.addTileModalView,
        tileModalView: state.viewReducer.tileModalView,
        presentationSize: state.presentationReducer.presentationSize,
        fileOverrideModalView: state.viewReducer.fileOverrideModalView,
        fileOverrideBool: state.presentationReducer.fileOverrideBool
    }
}

const mapDispatchToProps = dispatch => {
    return{
        addTile: (tile) => dispatch(addTile(tile)),
        removeTile: (id) => dispatch(removeTile(id)),
        removeFile: (id) => dispatch(removeFile(id)),
        setOutlineColor: (color) => dispatch(setOutlineColor(color)),
        setSettingsVisit: (bool) => dispatch(setSettingsVisit(bool)),
        setCurrentTileId: (id) => dispatch(setCurrentTileId(id)),
        setAllFiles: (file) => dispatch(setAllFiles(file)),
        setFontColor: (color) => dispatch(setFontColor(color)),
        setCurrentPage: (page) => dispatch(setCurrentPage(page)),
        setTileColorModalView: (bool) => dispatch(setTileColorModalView(bool)),
        setFontColorModalView: (bool) => dispatch(setFontColorModalView(bool)),
        setAddTileModalView: (bool) => dispatch(setAddTileModalView(bool)),
        setTileModalView: (bool) => dispatch(setTileModalView(bool)),
        setPresentationSize: (file) => dispatch(setPresentationSize(file)),
        setFileOverrideModalView: (bool) => dispatch(setFileOverrideModalView(bool)),
        editTileOrder: (tileData) => dispatch(editTileOrder(tileData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TileChoice);