import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import '../assets/icons/savedfiles.png';
import {
    deleteFile,
    updateFile,
    updateTile,
    deleteTile
} from '../utils/API';
import {
    setAllFiles,
    removeFile,
    editFileGuestPass
} from '../actions/tileActions';
import {
    removeTile,
    editTileGuestPass
} from '../actions/presentationActions';
import {
    setGuestpassDropboxModalView
} from '../actions/viewActions';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

const StyledBackdrop = styled.div`
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 4;
    background-color: #3b3b3bBF;
    overflow: hidden;
    top: 0;
`;

const StyledCreationModal = styled.div`
    height: ${props => props.files ? `500px` : `150px`};
    width: 400px;
    background-color: white;
    position: absolute;
    z-index: ${props => props.active ? 5 : 0};
    top: ${props => props.files ? `calc(50% - 250px)` : `calc(50% - 75px)`};
    left: calc(50% - 200px);
    box-shadow: 1px 1px 8px 1px #00000080;
    transition: all 0.3s ease;
    visibility: ${props => props.active ? `visible` : `hidden`};
    opacity: ${props => props.active ? 1 : 0};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .exit-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        width: 40px;
        z-index: 2;
    }

    .intro-box {
        position: absolute;
        top: 20px;
        left: 20px;
        height: 110px;
        width: 360px;
        display: grid;
        grid-template-rows: 1fr 3fr;
        z-index: 1;

        .title {
            grid-row: 1 / span 1;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                margin-block: 0px;
                font-family: 'Crimson Text', serif;
                font-size: 28px;
                font-weight: 600;
            }
        }

        .caption {
            grid-row: 2 / span 1;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                margin-block: 0px;
                font-size: 14px;
                text-align: center;
            }
        }
    }

    .function-box {
        position: absolute;
        left: 20px;
        width: 360px;
        height: 40px;
        top: 130px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        visibility: ${props => props.files ? `visible` : `hidden`};

        .function-button {
            height: 20px;
            width: 100px;
            border-radius: 8px;
            box-shadow: 1px 1px 3px 0px #3b3b3b;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            p {
                font-size: 14px;
                font-weight: 600;
                color: white;
                margin-block: 0px;
            }
        }
    }

    .guest-file-box {
        background-color: #3b3b3b40;
        border-radius: 3px;
        visibility: ${props => props.files ? `visible` : `hidden`};
        position: absolute;
        left: 20px;
        top: 170px;
        height: 310px;
        width: 360px;
        overflow-y: auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        align-content: flex-start;
    }
`;

const StyledFileContainer = styled.div`
    margin: 2%;
    margin-bottom: 3%;
    margin-top: 3%;
    width: 46%;
    height: 27.33%;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 2px;

    .delete-or-add {
        position: absolute;
        right: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3b3b3b59;
        visibility: ${props => props.isHovered ? 'visible' : 'hidden'};

        @media (max-width: 1367px) and (orientation: landscape) {
            visibility: visible;
        }

        @media (max-width: 1025px) and (orientation: portrait) {
            visibility: visible;
        }

        .icon-holder {
            height: 40px;
            width: 40px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5%;
            z-index: 1;
        }
    }
`;

const StyledVideo = styled.video`
    max-width: 100%; 
    max-height: 100%;
    width: 100%;
    height: auto; 
    background-size: cover;
    overflow: hidden;
    filter: drop-shadow(0 0 0.4rem #3b3b3b80);
`;

const StyledPDFAndVideoHolder = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-item: center;
    background-color: white;
    border-radius: 3px;
    object-fit: cover;
    flex-shrink: 0;

    img {
        z-index: 1;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        position: absolute;
        height: 40px;
        width: 40px;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
    }

    .name {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        height: 20px;
        padding-left: 5px;
        padding-right: 5px;
        z-index: 2;
        position: absolute;
        top: calc(50% + 20px);
        left: 0;

        p {
            font-size: 12px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-block: 0px;
        }
    }
`;

class GuestPassDropboxModal extends Component {

    constructor(){
        super();
        this.state = {
            hoveredItemId: '',
            windowDimensions: {}
        }
    }

    handleModalShow = () => {
        this.props.setGuestpassDropboxModalView(!this.props.guestpassDropboxModalView);
    }

    destroyTileAndFile = (fileId, tileId) => {   
        deleteFile(fileId, tileId, this.props.presentationId).then((res) => {
            console.log("DELETED GUEST PASS FILE", res);
            this.props.removeFile(fileId);
            deleteTile(tileId).then(deleteRes => {
                console.log("DELETED GUEST PASS TILE", deleteRes);
                this.props.removeTile(tileId);
            });
        });
    }

    tempHideTileAndFile = (fileId, tileId) => {
        let update = {
            isTempHidden: true
        }
        updateFile(fileId, update).then((fileRes) => {
            this.props.removeFile(fileId);
            updateTile(tileId, update).then(tileRes => {
                this.props.removeTile(tileId);
            })
        });
    }

    deleteSingleFile = (event, fileId, tileId) => {
        event.stopPropagation();
        this.destroyTileAndFile(fileId, tileId);
    }

    destroyAll = () => {
        let GPFiles = this.props.files.filter(file => file.isGuestPass === true);
        for (let file of GPFiles) {
            this.destroyTileAndFile(file.id, file.tileId);
        }
    }

    tempHideAll = () => {
        let GPFiles = this.props.files.filter(file => file.isGuestPass === true && !file.isTempHidden);
        for (let file of GPFiles) {
            this.tempHideTileAndFile(file.id, file.tileId);
        }
    }

    sendToPresentation = (fileId, tileId) => {
        let guestPassObject = {
            isGuestPass: false
        }

        updateFile(fileId, guestPassObject);
        let updatedFileRedux = {
            id: fileId,
            isGuestPass: false
        }
        this.props.editFileGuestPass(updatedFileRedux);
        updateTile(tileId, guestPassObject);
        let updatedTileRedux = {
            id: tileId,
            isGuestPass: false
        }
        this.props.editTileGuestPass(updatedTileRedux);
    }

    sendAll = () => {
        let GPFiles = this.props.files.filter(file => file.isGuestPass === true);
        for (let file of GPFiles) {
            this.sendToPresentation(file.id, file.tileId);
        }
    }

    enterContent = (id) => {
        this.setState({hoveredItemId: id})
    }

    leaveContent = () => {
        this.setState({hoveredItemId: ''})
    }

    render() {

        let guestPassFiles = null;
        if (this.props.files?.length) {
            guestPassFiles = this.props.files.filter(file => file?.isGuestPass && !file?.isTempHidden);
        }
        let guestPassFileMapping = null;
        if (guestPassFiles?.length > 0) {
            guestPassFileMapping = guestPassFiles.map((file) => {
                if (file.type === 'image/jpeg' || file.type === 'image/png') {
                    return(
                        <StyledFileContainer
                            key={file.id}
                            id={file.id}
                            onPointerEnter={() => this.enterContent(file.id)}
                            onPointerLeave={() => this.leaveContent()}
                            isHovered={this.state.hoveredItemId === file.id}
                        >
                            <div 
                                className='delete-or-add'
                            >
                                <div className='icon-holder' onClick={() => this.sendToPresentation(file.id, file.tileId)}>
                                    <CheckCircleRoundedIcon 
                                        style={{
                                            fontSize: '32px',
                                            color: '#03bd00'
                                        }}
                                    />
                                </div>
                                <div className='icon-holder' onClick={() => this.destroyTileAndFile(file.id, file.tileId)}>
                                    <CancelRoundedIcon 
                                        style={{
                                            fontSize: '32px',
                                            color: '#c40d00'
                                        }}
                                    />
                                </div>
                            </div>
                            <img src={file.url} alt="" 
                                style={{
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                    height: "100%",
                                    filter: 'drop-shadow(0 0 0.4rem #3b3b3b80)'
                                }}
                            />
                        </StyledFileContainer>
                    )
                } else if (file.type === 'video/x-mastroka' || file.type === 'video/mp4' || file.type === 'video/mov') {
                    return(
                        <StyledFileContainer
                            key={file.id}
                            id={file.id}
                            isHovered={this.state.hoveredItemId === file.id}
                            onPointerEnter={() => this.enterContent(file.id)}
                            onPointerLeave={() => this.leaveContent()}
                        >
                            <div 
                                className='delete-or-add'
                            >
                                <div className='icon-holder' onClick={() => this.sendToPresentation(file.id, file.tileId)}>
                                    <CheckCircleRoundedIcon 
                                        style={{
                                            fontSize: '32px',
                                            color: '#03bd00'
                                        }}
                                    />
                                </div>
                                <div className='icon-holder' onClick={() => this.destroyTileAndFile(file.id, file.tileId)}>
                                    <CancelRoundedIcon 
                                        style={{
                                            fontSize: '32px',
                                            color: '#c40d00'
                                        }}
                                    />
                                </div>
                            </div>
                        <StyledVideo controls={false}>
                            <source src={file.url} type="video/mp4"/>
                        </StyledVideo>
                        </StyledFileContainer>
                    )
                } else if (file.type === 'application/pdf') {
                    return(
                        <StyledFileContainer
                            key={file.id}
                            id={file.id}
                            isHovered={this.state.hoveredItemId === file.id}
                            onPointerEnter={() => this.enterContent(file.id)}
                            onPointerLeave={() => this.leaveContent()}
                        >
                            <div 
                                className='delete-or-add'
                            >
                                <div className='icon-holder' onClick={() => this.sendToPresentation(file.id, file.tileId)}>
                                    <CheckCircleRoundedIcon 
                                        style={{
                                            fontSize: '32px',
                                            color: '#03bd00'
                                        }}
                                    />
                                </div>
                                <div className='icon-holder' onClick={() => this.destroyTileAndFile(file.id, file.tileId)}>
                                    <CancelRoundedIcon 
                                        style={{
                                            fontSize: '32px',
                                            color: '#c40d00'
                                        }}
                                    />
                                </div>
                            </div>
                            <StyledPDFAndVideoHolder
                                style={{
                                    backgroundColor: 'white'
                                }}
                            >
                                <div className='icon'>
                                    <PictureAsPdfRoundedIcon
                                        style={{
                                            fontSize: '32px',
                                            color: this.props.outlineColor !== '' ? this.props.outlineColor : '#0768b0'
                                        }}
                                    />
                                </div>
                                <div 
                                    className='name'
                                    style={{
                                        borderRadius: '3px'
                                    }}
                                >
                                    <p>{file.name.slice(0, -4)}</p>
                                </div>
                            </StyledPDFAndVideoHolder>
                        </StyledFileContainer>
                    )
                } else {
                    return null;
                }
            });
        }

        return(
            <>
                <StyledBackdrop
                    style={{
                        visibility: this.props.guestpassDropboxModalView ? 'visible' : 'hidden'
                    }}
                    onClick={() => this.handleModalShow()}
                />
                <StyledCreationModal 
                    active={this.props.guestpassDropboxModalView}
                    files={this.props.files?.filter(file => file?.isGuestPass).length}
                >
                    <div className='exit-icon'
                        onClick={() => this.handleModalShow()}
                    >
                        <CancelRoundedIcon
                            style={{
                                color: '#ff1940',
                                fontSize: `32px`,
                                cursor: 'pointer',
                            }}
                        />
                    </div>
                    <div className='intro-box'>
                        <div className='title'>
                            <p>Guest Pass Dropbox</p>
                        </div>
                        <div className='caption'>
                            <p>If guest files have been added, click each file below to add them to the presentation, or delete them as needed.</p>
                        </div>
                    </div>
                    <div className='function-box'>
                        <div 
                            className='function-button'
                            style={{
                                backgroundColor: '#03bd00'
                            }}
                            onClick={() => this.sendAll()}
                        >
                            <p>Add All</p>
                        </div>
                        <div
                            className='function-button'
                            style={{
                                backgroundColor: '#ff1940'
                            }}
                            onClick={() => this.destroyAll()}
                        >
                            <p>Delete All</p>
                        </div>
                    </div>
                    <div className='guest-file-box'>
                        {guestPassFileMapping}
                    </div>
                </StyledCreationModal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {        
        presentationId: state.presentationReducer.id,
        files: state.tileReducer.files,
        guestpassDropboxModalView: state.viewReducer.guestpassDropboxModalView
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setAllFiles: (files) => dispatch(setAllFiles(files)),
        removeFile: (id) => dispatch(removeFile(id)),
        editFileGuestPass: (data) => dispatch(editFileGuestPass(data)),
        removeTile: (id) => dispatch(removeTile(id)),
        editTileGuestPass: (data) => dispatch(editTileGuestPass(data)),
        setGuestpassDropboxModalView: (bool) => dispatch(setGuestpassDropboxModalView(bool))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestPassDropboxModal);