import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
    setTileSpeed,
    setTileMovementDirection,
    setIconsColor,
    setIconsBorderColor
} from '../actions/presentationActions';
import {
    setExportVisit,
    setCurrentPage
} from '../actions/visitActions';
import {
    setIconColorModalView
} from '../actions/viewActions';
import {
    editPresentation
} from '../utils/API';
// import pin from '../assets/images/UnpinButton.png';
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import f2b from '../assets/images/f2bbackground.jpg';
import ChangeIconColorModal from '../components/ChangeIconColorModal';
import LockRoundedIcon from '@material-ui/icons/LockRounded';

const StyledSettingsContainer = styled.div`
    height: calc(100vh - 80px);
    width: 100%;
    transition: 0.4s all ease;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .continue {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 2;
        right: 1.5%;
        bottom: 5%;
        height: 10%;
        width: 5%;
        border-radius: 24px;
        box-shadow: 1px 1px 2px 0px #3b3b3b80;

        @media (max-width: 1367px) and (orientation: landscape) {
            height: 80px;
            width: 80px;
            top: calc(50% - 40px);
            right: 6%;
        }

        @media (orientation: portrait) {
            height: 80px;
            width: 80px;
            top: calc(50% - 40px);
        }

        @media (orientation: portrait) and (max-width: 900px) {
            height: 70px;
            width: 70px;
            top: calc(50% - 40px);
        }
    }

    .overlay {
        position: absolute;
        z-index: 1;
        height: calc(100vh - 80px);
        width: 100%;
        top: 0px;
        left: 0;
        overflow: hidden;
    }

    .centering-box {
        z-index: 2;
        height: 90%;
        width: 70%;
        position: relative;
        display: grid;
        grid-template-columns: 7fr 3fr;

        @media (orientation: portrait) {
            grid-template-columns: auto;
            grid-template-rows: 600px 230px;
            width: 100%;
            height: 100%;
        }

        @media (orientation: portrait) and (max-width: 900px) {
            grid-template-rows: 450px 230px;
        }

        .icon-container {
            grid-column: 1 / span 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            @media (max-width: 1367px) and (orientation: landscape) {
                justify-content: flex-start;
                flex-direction: column;
            }

            @media (orientation: portrait) {
                grid-column: auto;
                grid-row: 1 / span 1;
            }

            .icon-box {
                height: 50%;
                width: 100%;
                display: grid;
                grid-template-rows: 1fr 1fr;
                grid-template-columns: 1fr 1fr 1fr;
                background-color: #FFFFFFBF;
                border-radius: 15px;

                @media (orientation: portrait) {
                    border-radius: 0px;
                }

                .icon-holder {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon {
                        height: 150px;
                        width: 150px;
                        border-radius: 20px;
                        background-color: ${props => props.tileColor ? props.tileColor : '#80808080'};
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media (max-width: 1367px) and (orientation: landscape) {
                            height: 120px;
                            width: 120px;
                        }

                        @media (max-width: 1200px) and (orientation: landscape) {
                            height: 100px;
                            width: 100px;
                        }

                        @media (orientation: portrait) {
                            height: 120px;
                            width: 120px;
                        } 

                        @media (orientation: portrait) and (max-width: 900px) {
                            height: 100px;
                            width: 100px;
                        }
                    }
                }
            }

            .color-select-button {
                height: 60px;
                width: 200px;
                position: absolute;
                border-radius: 8px;
                bottom: calc(12.5% - 30px);
                left: calc(50% - 100px);
                background-color: #3b3b3b;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 1px 1px 2px 1px #3b3b3b80;

                @media (max-width: 1367px) and (orientation: landscape) {
                    position: relative;
                    bottom: auto;
                    left: auto;
                    margin: 70px;
                }

                p {
                    color: white;
                    font-size: 20px;
                    margin-block: 0px;
                    font-family: 'Crimson Text', serif;
                }
            }
        }

        .settings-container {
            grid-column: 2 / span 1;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 1367px) and (orientation: landscape) {
                align-items: flex-start;
            }

            @media (orientation: portrait) {
                grid-column: auto;
                grid-row: 2 / span 1;
            }

            .settings-box {
                height: 80%;
                width: 80%;
                // background-color: #80808080;
                border-radius: 20px;
                position: relative;

                @media (max-width: 1367px) and (orientation: landscape) {
                    width: 100%;
                }

                @media (orientation: portrait) {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                }

                .speed-box {
                    height: 35%;
                    width: 80%;
                    position: absolute;
                    top: 6.33%;
                    left: 10%;

                    @media (orientation: portrait) {
                        position: static;
                        width: 45%;
                        top: auto;
                        left: auto;
                        height: 100%;
                    }

                    .title {
                        height: 14%;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media (orientation: portrait) {
                            position: static;
                        }

                        .title-box {
                            height: 90%;
                            width: 70%;
                            background-color: #3b3b3b;
                            border-radius: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            @media (max-width: 1367px) and (orientation: landscape) {
                                width: 100%;
                                border-radius: 0px;
                            }

                            p {
                                font-size: 14px;
                                color: white;
                                margin-block: 0px;
                                font-weight: 600;

                                @media (max-width: 1367px) and (orientation: landscape) {
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    .selections {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 86%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: center;

                        @media (orientation: portrait) {
                            position: static;
                        }

                        .selection {
                            height: 32.5%;
                            width: 100%;
                            background-color: #3b3b3b;
                            border-radius: 2px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            opacity: 0.5;
                            cursor: pointer;
                            transition: 0.3s ease all;

                            p {
                                font-size: 20px;
                                color: white;
                                margin-block: 0px;
                                font-weight: 600;
                            }
                        }
                    }
                }

                .direction-box {
                    height: 45%;
                    width: 80%;
                    position: absolute;
                    bottom: 6.33%;
                    left: 10%;

                    @media (orientation: portrait) {
                        position: static;
                        width: 45%;
                        top: auto;
                        left: auto;
                        height: 100%;
                    }

                    .title {
                        height: 11%;
                        width: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media (orientation: portrait) {
                            position: static;
                        }

                        .title-box {
                            height: 90%;
                            width: 80%;
                            background-color: #3b3b3b;
                            border-radius: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            @media (max-width: 1367px) and (orientation: landscape) {
                                border-radius: 0px;
                                width: 100%;
                            }

                            p {
                                font-size: 14px;
                                color: white;
                                margin-block: 0px;
                                font-weight: 600;

                                @media (max-width: 1367px) and (orientation: landscape) {
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    .selections {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 89%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: center;

                        @media (orientation: portrait) {
                            position: static;
                        }

                        .selection {
                            height: 24.5%;
                            width: 100%;
                            background-color: #3b3b3b;
                            border-radius: 2px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            opacity: 0.5;
                            cursor: pointer;
                            transition: 0.3s ease all;

                            p {
                                font-size: 20px;
                                color: white;
                                margin-block: 0px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
`;

class Settings extends Component {

    constructor() {
        super();
        this.state = {
            animate: false
        }
    }

    componentDidMount = () => {
        if (this.props.isAuth !== true) {
            this.props.history.push('/')
        }
        setTimeout(() => {
            this.setState({ animate: true });
        }, 50);
    }

    editPresentationDirection = (direction) => {
        this.props.setTileMovementDirection(direction);
        let movementObject = {
            TileMoveDirection: direction
        }
        editPresentation(this.props.presentationId, movementObject);
    }

    editPresentationSpeed = (speed) => {
        this.props.setTileSpeed(speed);
        let tilePlacementTime = null;
        if (speed === 300) {
            tilePlacementTime = 3;
        } else if (speed === 150) {
            tilePlacementTime = 4;
        } else if (speed === 120) {
            tilePlacementTime = 5;
        }

        if (tilePlacementTime) {
            let speedObject = {
                tileSpeed: speed,
                tilePlacement: tilePlacementTime
            }
            editPresentation(this.props.presentationId, speedObject);
        } else {
            console.log("UNKNOWN VALUE");
            return;
        }
    }

    setColor = (color) => {
        let colorObject = {
            iconColor: color.hex
        }
        this.props.setIconsColor(color.hex);
        editPresentation(this.props.presentationId, colorObject);
    }

    continue = () => {
        if (this.props.iconsColor !== '#3b3b3b') {
            this.props.setExportVisit(true);
            this.props.setCurrentPage('export')
            this.props.history.push('/export');
        } else {
            return;
        }
    }

    handleModalShow = () => {
        this.props.setIconColorModalView(!this.props.iconColorModalView);
    }
    
    render() {

        return(
            <>
                <ChangeIconColorModal />
                <StyledSettingsContainer 
                    style={{opacity: this.state.animate ? 1 : 0}}
                    tileColor={this.props.outlineColor}
                >
                    <div className='overlay'
                        style={{
                            backgroundImage: `url(${f2b})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            opacity: '0.5'
                        }}
                    />
                    <div className='centering-box'>
                        <div className='icon-container'>
                            <div className='icon-box'>
                                <div className='icon-holder'>
                                    <div className='icon'>
                                        <CancelRoundedIcon 
                                            style={{
                                                fontSize: '84px',
                                                color: this.props.iconsColor ? this.props.iconsColor : 'white'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='icon-holder'>
                                    <div className='icon'>
                                        <ArrowBackIosRoundedIcon 
                                            style={{
                                                fontSize: '84px',
                                                color: this.props.iconsColor ? this.props.iconsColor : 'white'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='icon-holder'>
                                    <div className='icon'>
                                        <ArrowForwardIosRoundedIcon 
                                            style={{
                                                fontSize: '84px',
                                                color: this.props.iconsColor ? this.props.iconsColor : 'white'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='icon-holder'>
                                    <div className='icon'>
                                        <LockRoundedIcon 
                                            style={{
                                                fontSize: '84px',
                                                color: this.props.iconsColor ? this.props.iconsColor : 'white'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='icon-holder'>
                                    <div className='icon'>
                                        <PlayArrowRoundedIcon 
                                            style={{
                                                fontSize: '84px',
                                                color: this.props.iconsColor ? this.props.iconsColor : 'white'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='icon-holder'>
                                    <div className='icon'>
                                        <InfoRoundedIcon 
                                            style={{
                                                fontSize: '84px',
                                                color: this.props.iconsColor ? this.props.iconsColor : 'white'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='color-select-button' onClick={() => this.handleModalShow()}>
                                <p>Icon Color Select</p>
                            </div>
                        </div>
                        <div className='settings-container'>
                            <div className='settings-box'>
                                <div className='speed-box'>
                                    <div className='title'>
                                        <div className='title-box'>
                                            <p>Presentation Speed</p>
                                        </div>
                                    </div>
                                    <div className='selections'>
                                        <div 
                                            className='selection'
                                            style={{
                                                backgroundColor: this.props.tileSpeed === 120 ? 'rgb(7, 104, 176)' : '#3b3b3b',
                                                opacity: this.props.tileSpeed === 120 ? '1' : '0.5'
                                            }}
                                            onClick={() => this.editPresentationSpeed(120)}
                                        >
                                            <p>Slow</p>
                                        </div>
                                        <div 
                                            className='selection'
                                            style={{
                                                backgroundColor: this.props.tileSpeed === 150 ? 'rgb(7, 104, 176)' : '#3b3b3b',
                                                opacity: this.props.tileSpeed === 150 ? '1' : '0.5'
                                            }}
                                            onClick={() => this.editPresentationSpeed(150)}
                                        >
                                            <p>Normal</p>
                                        </div>
                                        <div 
                                            className='selection'
                                            style={{
                                                backgroundColor: this.props.tileSpeed === 300 ? 'rgb(7, 104, 176)' : '#3b3b3b',
                                                opacity: this.props.tileSpeed === 300 ? '1' : '0.5'
                                            }}
                                            onClick={() => this.editPresentationSpeed(300)}
                                        >
                                            <p>Fast</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='direction-box'>
                                    <div className='title'>
                                        <div className='title-box'>
                                            <p>Tile Movement Direction</p>
                                        </div>
                                    </div>
                                    <div className='selections'>
                                        <div 
                                            className='selection'
                                            style={{
                                                backgroundColor: this.props.tileMovementDirection === 2 ? 'rgb(7, 104, 176)' : '#3b3b3b',
                                                opacity: this.props.tileMovementDirection === 2 ? '1' : '0.5'
                                            }}
                                            onClick={() => this.editPresentationDirection(2)}
                                        >
                                            <p>Left to Right</p>
                                        </div>
                                        <div 
                                            className='selection'
                                            style={{
                                                backgroundColor: this.props.tileMovementDirection === 1 ? 'rgb(7, 104, 176)' : '#3b3b3b',
                                                opacity: this.props.tileMovementDirection === 1 ? '1' : '0.5'
                                            }}
                                            onClick={() => this.editPresentationDirection(1)}
                                        >
                                            <p>Right to Left</p>
                                        </div>
                                        <div 
                                            className='selection'
                                            style={{
                                                backgroundColor: this.props.tileMovementDirection === 3 ? 'rgb(7, 104, 176)' : '#3b3b3b',
                                                opacity: this.props.tileMovementDirection === 3 ? '1' : '0.5'
                                            }}
                                            onClick={() => this.editPresentationDirection(3)}
                                        >
                                            <p>Top to Bottom</p>
                                        </div>
                                        <div 
                                            className='selection'
                                            style={{
                                                backgroundColor: this.props.tileMovementDirection === 4 ? 'rgb(7, 104, 176)' : '#3b3b3b',
                                                opacity: this.props.tileMovementDirection === 4 ? '1' : '0.5'
                                            }}
                                            onClick={() => this.editPresentationDirection(4)}
                                        >
                                            <p>Bottom to Top</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                        className='continue'
                        style={{
                            backgroundColor: this.props.iconsColor === '#3b3b3b' ? "#7693a8" : "#2695D2",
                            cursor: this.props.iconsColor === '#3b3b3b' ? 'default' : 'pointer'
                        }}
                        onClick={() => this.continue()} 
                    >
                        <DoubleArrowRoundedIcon 
                            style={{
                                fontSize: '48px',
                                color: 'white'
                            }}
                        />
                    </div>
                </StyledSettingsContainer>
            </>
        );

    }
}

const mapStateToProps = state => {
    return {        
        iconsColor: state.presentationReducer.iconsColor,
        iconsBorderColor: state.presentationReducer.iconsBorderColor,
        tileMovementDirection: state.presentationReducer.tileMovementDirection,
        isAuth: state.authReducer.isAuth,
        presentationId: state.presentationReducer.id,
        tileSpeed: state.presentationReducer.tileSpeed,
        iconColorModalView: state.viewReducer.iconColorModalView,
        outlineColor: state.tileReducer.outlineColor
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTileSpeed: (data) => dispatch(setTileSpeed(data)),
        setTileMovementDirection: (data) => dispatch(setTileMovementDirection(data)),
        setIconsColor: (color) => dispatch(setIconsColor(color)),
        setIconsBorderColor: (color) => dispatch(setIconsBorderColor(color)),
        setExportVisit: (bool) => dispatch(setExportVisit(bool)),
        setCurrentPage: (page) => dispatch(setCurrentPage(page)),
        setIconColorModalView: (bool) => dispatch(setIconColorModalView(bool))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);