import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import {
    setFontColorModalView
} from '../actions/viewActions';
import {
    setFontColor,
} from '../actions/presentationActions';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import {
    editPresentation,
} from '../utils/API';

const StyledBackdrop = styled.div`
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 6;
    background-color: #3b3b3bBF;
    overflow: hidden;
    top: 0;
`;

const StyledCreationModal = styled.div`
    height: 400px;
    width: 400px;
    background-color: #fffcf5;
    position: absolute;
    z-index: ${props => props.active ? 7 : 0};
    top: calc(50% - 200px);
    left: calc(50% - 200px);
    box-shadow: 1px 1px 8px 1px #00000080;
    transition: all 0.3s ease;
    visibility: ${props => props.active ? `visible` : `hidden`};
    opacity: ${props => props.active ? 1 : 0};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .exit-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        width: 40px;
    }
`;

class ChangeColorModal extends Component {

    constructor(){
        super();
        this.state = {

        }
    }

    setFontColor = (color) => {
        let colorObject = {
            fontColor: color.hex
        }
        this.props.setFontColor(color.hex);
        editPresentation(this.props.presentationId, colorObject);
    }

    handleModalShow = () => {
        this.props.setFontColorModalView(!this.props.fontColorModalView);
    }

    render() {

        return(
            <>
                <StyledBackdrop
                    style={{
                        visibility: this.props.fontColorModalView ? 'visible' : 'hidden'
                    }}
                    onClick={() => this.handleModalShow()}
                />
                <StyledCreationModal 
                    active={this.props.fontColorModalView}
                >
                    <SketchPicker
                        color={this.props.fontColor}
                        onChangeComplete={this.setFontColor}
                    />
                    <div className='exit-icon'
                        onClick={() => this.handleModalShow()}
                    >
                        <CancelRoundedIcon
                            style={{
                                color: '#ff1940',
                                fontSize: `32px`,
                                cursor: 'pointer',
                            }}
                        />
                    </div>
                </StyledCreationModal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {        
        fontColorModalView: state.viewReducer.fontColorModalView,
        presentationId: state.presentationReducer.id,
        fontColor: state.presentationReducer.fontColor,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setFontColorModalView: (bool) => dispatch(setFontColorModalView(bool)),
        setFontColor: (color) => dispatch(setFontColor(color)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeColorModal);