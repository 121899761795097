import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { 

} from '../actions/presentationActions';
import {

} from '../actions/tileActions'
import {

} from '../actions/visitActions';
import {
    createTileGP,
    deleteTileGP,
    createFileGP,
    deleteFileGP,
    getSelectedPresentationGP
} from '../utils/API';
import { v4 as uuidv4 } from 'uuid';
import '../assets/images/logo.png';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';

const StyledGuestPassContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.7s all ease;
    background-color: #0768b0;

    .content-container {
        height: 80%;
        width: 40%;
        background-color: white;
        border-radius: 40px;
        box-shadow: 3px 3px 3px #00000040;
        display: grid;
        grid-template-rows: 1fr 1fr;

        .expo-container {
            grid-row: 1 / span 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .logo {
                display: flex;
                justify-content: center;
                align-items: center;
                object-fit: contain;
                height: 200px;
                width: 350px;
    
                img {
                    height: 100%;
                    width: 100%;
                }
            }
    
            .title-box {
                display: flex;
                justify-content: center;
                align-items: center;
    
                p {
                    margin-block-end: 0px;
                    margin-block-start: 0px;
                    font-size: 1.8em;
                    font-weight: 600;
                    letter-spacing: 0.05em;
                    font-family: 'Oswald', sans-serif;
                    text-align: center;
                }
            }
    
            .text-box {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 5%;
                width: 60%;
    
                p {
                    margin-block-end: 0px;
                    margin-block-start: 0px;
                    font-size: 1.3em;
                    font-weight: 400;
                    letter-spacing: 0.02em;
                    font-family: 'Oswald', sans-serif;
                    text-align: center;
                }
            }
    
            .button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
    
                label {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    border-radius: 5px;
                    width: 200px;
                    height: 50px;
                    background-color: #0768b0;
                    color: white;
                    cursor: pointer;
                    font-size: 1.25em;
                    font-weight: 700;
                    color: white;
                    text-align: center;
                }

                p {
                    font-family: 'Roboto Slab', serif;
                }
            }
        }
    }

    .preview-container {
        grid-row: 2 / span 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .preview-box {
            width: 80%;
            height: 75%;
            background-color: #EBEBEB;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            overflow-y: auto;
            padding: 2%;
            color: black;
            font-size: 1.3em;
            letter-spacing: 0.05em;
            font-weight: 500;
            font-family: 'Roboto Slab', serif;
        }
    }
`;

const StyledInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

const StyledFileContainer = styled.div`
    width: 150px;
    height: 100px;
    background-color: black;
    margin: 1%;
    border-radius: 5px;
    position: relative;
`;

const StyledVideo = styled.video`
    max-width: 100%; 
    max-height: 100%;
    width: 100%;
    height: auto; 
    background-size: cover;
    overflow: hidden;
`;

const StyledDeleteButton = styled.button`
    position: absolute;
    top: 1%;
    right: 1%;
    background-color: red;
    height: 20px;
    width: 22px;
    cursor: pointer;
    border-radius: 2px;
    z-index: 1;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
`;

const StyledPDFAndVideoHolder = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-item: center;
    background-color: white;
    border-radius: 3px;
    object-fit: cover;
    flex-shrink: 0;

    img {
        z-index: 1;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        position: absolute;
        height: 40px;
        width: 40px;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
    }

    .name {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        height: 20px;
        padding-left: 5px;
        padding-right: 5px;
        z-index: 2;
        position: absolute;
        top: calc(50% + 20px);
        left: 0;

        p {
            font-size: 12px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-block: 0px;
        }
    }
`;

class GuestPass extends Component {

    constructor() {
        super();
        this.state = {
            animate: false,
            search: '',
            uploadCount: 0,
            files: [],
            presentationId: ""
        }
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.setState({ animate: true });
        }, 50);

        let param = window.location.search.slice(1);
        this.setState({presentationId: param});
        getSelectedPresentationGP(param).then(res => {
            if (res.errors || res === undefined) {
                this.props.history.push('/');
                return;
            }
        });
    }

    setFiles = (file) => {
        this.setState({files: this.state.files.concat(file)});
    }

    checkValidURL = (uuid) => {
        return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(uuid);
    };

    removeFile = (fileId, tileId) => {
        deleteFileGP(fileId, tileId, this.state.presentationId).then((fileRes) => {
            deleteTileGP(tileId).then((tileRes) => {console.log("TILE DELETION ", tileRes)});
            this.setState({files: this.state.files.filter(iteratedFile => iteratedFile.id !== fileId)});
            this.setState({uploadCount: this.state.uploadCount - 1});
        });
    }

    multiUpload = (event) => {
        console.log("BEGINNING UPLOAD");
        for (let i = 0; i < event.target.files.length; i++) {
            let tileId = uuidv4();
            let newTile = {
                id: tileId,
                presentationId: this.state.presentationId,
                files: [],
                ContentPaths: null,
                PrimaryThumbnailPath: null,
                SecondaryThumbnailPath: null,
                isGuestPass: true,
                Title: "",
                SubTitle: "",
                Description: "",
                Tags: ""
            }
            let file = event.target.files[i];
            createTileGP(newTile).then((res) => {
                let uploadFile = file;
                let formData = new FormData();
                formData.append('file', uploadFile);
                console.log("DATA RECEIVED FROM UPLOADED FILE", uploadFile);
                let thumbBool = false;
                if (uploadFile.type === "image/jpeg" || uploadFile.type === "image/png") {
                    thumbBool = true;
                }
                createFileGP(tileId, formData, thumbBool, true, false).then((resFile) => {
                    this.setFiles(resFile.data.file);
                    this.setState({uploadCount: this.state.uploadCount + 1}, () => console.log("FILE ADDED", resFile.data.file));
                });
            })
        }
    }

    render() {

        let fileMapping = this.state.files.map((file) => {
            let content = null;
            if (file.type === 'image/jpeg' || file.type === 'image/png') {
                content = (
                    <img 
                        src={file.url} 
                        alt="" 
                        style={{maxHeight: "100%", maxWidth: "100%", height: "100%"}}
                    />
                );
            } else if (file.type === 'video/x-mastroka' || file.type === 'video/mp4' || file.type === 'video/mov') {
                content = (
                    <StyledVideo controls>
                        <source src={file.url} type="video/mp4" />
                    </StyledVideo>
                )
            } else if (file.type === 'application/pdf') {
                content = (
                    <StyledPDFAndVideoHolder
                        style={{
                            backgroundColor: 'white'
                        }}
                    >
                        <div className='icon'>
                            <PictureAsPdfRoundedIcon
                                style={{
                                    fontSize: '32px',
                                    color: this.props.outlineColor !== '' ? this.props.outlineColor : '#0768b0'
                                }}
                            />
                        </div>
                        <div 
                            className='name'
                            style={{
                                borderRadius: '3px'
                            }}
                        >
                            <p>{file.name.slice(0, -4)}</p>
                        </div>
                    </StyledPDFAndVideoHolder>
                )
            }

            return (
                <>
                    <StyledFileContainer
                        key={file.id}
                        id={file.id}
                    >
                        <StyledDeleteButton
                            onClick={() => this.removeFile(file.id, file.tileId)}
                        >X</StyledDeleteButton>
                        {content}
                    </StyledFileContainer>
                </>
            )
        });

        return(
            <StyledGuestPassContainer style={{opacity: this.state.animate ? 1 : 0}}>
                <div className="content-container">
                    <div className="expo-container">
                        <div className="logo">
                            <img 
                                src={require('../assets/images/logo.png')}
                                alt="logo"
                            />
                        </div>
                        <div className="title-box">
                            <p>Presentation Board Editor - File Upload</p>
                        </div>
                        <div className="text-box">
                            <p>Please upload your content with the button below - it will automatically be sent to the proper presentation. When you are done uploading, feel free to close the page.</p>
                        </div>
                        <div className="button-container">
                            <label for="addFiles">Upload</label>
                            <StyledInput type="file" multiple={true} accept=".jpg,.png,.mp4,.pdf" onInput={this.multiUpload} name="addFiles" id="addFiles"></StyledInput>
                            <p className="upload-count">{this.state.uploadCount} file{this.state.uploadCount !== 1 ? 's' : null} uploaded</p>
                        </div>
                    </div>
                    <div className="preview-container">
                        <div className="preview-box"
                            style={{
                                alignItems: this.state.files.length ? "start" : "center",
                                alignContent: this.state.files.length ? "flex-start" : "stretch"
                            }}
                        >
                            {this.state.files.length > 0 ? null : "Uploaded Files"}
                            {fileMapping}
                        </div>
                    </div>
                </div>
            </StyledGuestPassContainer>
        );
    }
}

const mapStateToProps = state => {
    return {        

    }
}

const mapDispatchToProps = dispatch => {
    return{

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestPass);
