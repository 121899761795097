import {
    SET_BACKGROUND_COLOR,
    SET_BACKGROUND_IMAGE_URL,
    SET_PRESENTATION_ID,
    SET_TILE_SPEED, 
    SET_TILE_MOVEMENT_DIRECTION, 
    SET_ICONS_COLOR,
    SET_NEW_PRESENTATION,
    SET_ICONS_BORDER_COLOR,
    SET_PRESENTATION_TILES,
    ADD_TILE,
    REMOVE_TILE,
    EDIT_TILE_TITLE,
    EDIT_TILE_SUBTITLE,
    EDIT_TILE_TAGS,
    EDIT_TILE_DESCRIPTION,
    SET_BACKGROUND_IMAGE_ID,
    SET_FONT_COLOR,
    EDIT_TILE_GUEST_PASS,
    RESET_PRESENTATION,
    SET_PRESENTATION_SIZE,
    SET_FILE_OVERRIDE_BOOL,
    EDIT_TILE_ORDER
} from '../constants/actionTypes';

const initialState = {
    backgroundImage: '',
    backgroundId: '',
    color: '',
    tileSpeed: 120,
    tileMovementDirection: 2,
    iconsColor: '#3b3b3b',
    iconsBorderColor: 'transparent',
    id: 0,
    isNewPresentation: false,
    presentationTiles: [],
    fontColor: '#FFFFFF',
    presentationSize: 0,
    fileOverrideBool: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_NEW_PRESENTATION:
            return {
                ...state,
                isNewPresentation: action.payload
            };
        case SET_FILE_OVERRIDE_BOOL:
            return {
                ...state,
                fileOverrideBool: action.payload
            };
        case SET_BACKGROUND_COLOR:
            return {
                ...state,
                color: action.payload
            };
        case SET_BACKGROUND_IMAGE_URL:
            return {
                ...state,
                backgroundImage: action.payload
            };
        case SET_BACKGROUND_IMAGE_ID:
            return {
                ...state,
                backgroundId: action.payload
            };
        case SET_PRESENTATION_SIZE:
            return {
                ...state,
                presentationSize: action.payload
            };
        case SET_PRESENTATION_ID:
            return {
                ...state,
                id: action.payload
            }
        case SET_TILE_SPEED:
            return {
                ...state,
                tileSpeed: action.payload
            };
        case SET_TILE_MOVEMENT_DIRECTION:
            return {
                ...state,
                tileMovementDirection: action.payload
            };
        case SET_ICONS_COLOR:
            return {
                ...state,
                iconsColor: action.payload
            }
        case SET_FONT_COLOR:
            return {
                ...state,
                fontColor: action.payload
            }
        case SET_ICONS_BORDER_COLOR:
            return {
                ...state,
                iconsBorderColor: action.payload
            }
        case SET_PRESENTATION_TILES:
            return {
                ...state,
                presentationTiles: action.payload
            }
        case ADD_TILE:
            return {
                ...state,
                presentationTiles: state?.presentationTiles?.concat(action.payload)
            };
        case REMOVE_TILE:
            return {
                ...state,
                presentationTiles: state?.presentationTiles?.filter(tile => tile?.id !== action.payload)
            };
        case EDIT_TILE_TITLE:
            return {
                ...state,
                presentationTiles: state?.presentationTiles?.map(    
                    (tile) => tile?.id === action.payload.id ? {
                        ...tile,
                        Title: action.payload.title, 
                    } : tile
                )    
            };
        case EDIT_TILE_TAGS:
            return {
                ...state,
                presentationTiles: state?.presentationTiles?.map(    
                    (tile) => tile?.id === action.payload.id ? {
                        ...tile,
                        Tags: action.payload.tags, 
                    } : tile
                )    
            };
        case EDIT_TILE_DESCRIPTION:
            return {
                ...state,
                presentationTiles: state?.presentationTiles?.map(    
                    (tile) => tile?.id === action.payload.id ? {
                        ...tile,
                        Description: action.payload.description, 
                    } : tile
                )    
            };
        case EDIT_TILE_SUBTITLE:
            return {
                ...state,
                presentationTiles: state?.presentationTiles?.map(    
                    (tile) => tile?.id === action.payload.id ? {
                        ...tile,
                        SubTitle: action.payload.subtitle, 
                    } : tile
                )    
            };
        case EDIT_TILE_ORDER:
            return {
                ...state,
                presentationTiles: state?.presentationTiles?.map(    
                    (tile) => tile?.id === action.payload.id ? {
                        ...tile,
                        order: action.payload.order, 
                    } : tile
                )    
            };
        case EDIT_TILE_GUEST_PASS:
            return {
                ...state,
                presentationTiles: state?.presentationTiles?.map(    
                    (tile) => tile?.id === action.payload.id ? {
                        ...tile,
                        isGuestPass: action.payload.isGuestPass, 
                    } : tile
                )    
            };
        case RESET_PRESENTATION:
            return {
                ...state,
                backgroundImage: '',
                backgroundId: '',
                color: '',
                tileSpeed: 120,
                tileMovementDirection: 2,
                iconsColor: '#3b3b3b',
                iconsBorderColor: 'transparent',
                id: 0,
                isNewPresentation: false,
                presentationTiles: [],
                fontColor: '#FFFFFF',
                presentationSize: 0,
                fileOverrideBool: false
            };
        default:
            return state;
    }
};