import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import '../assets/icons/logout.png';
import '../assets/icons/choosepresentation.png';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import FolderRoundedIcon from '@material-ui/icons/FolderRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import {
    setIsAuth,
    resetUser
} from '../actions/authActions';
import { withRouter } from 'react-router-dom';
import {
    setGuestpassGenerateModalView,
    setGuestpassDropboxModalView,
    setCompanyUserFilter,
} from '../actions/viewActions';
import {
    resetVisit,
    setCurrentPage,
    setByUserView
} from '../actions/visitActions';
import {
    resetPresentation
} from '../actions/presentationActions';
import {
    resetTiles
} from '../actions/tileActions';

const StyledHeaderContainer = styled.div`
    width: 100%;
    height: 80px;
    background-color: #3b3b3b80;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    box-shadow: 0px 3px 4px 0px #808080;

    @media (max-width: 1350px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
        height: 64px;
    }

    .icon {
        @media (max-width: 1350px) {
            font-size: 36px !important;
        }

        @media (max-width: 450px) {
            font-size: 30px !important;
        }
    }

    .exit-container {
        grid-column: 1 / span 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: 50px;

        @media (max-width: 1350px) {
            height: 100%;
            width: 25%;
            padding-left: 10px;
        }

        @media (max-width: 450px) {
            width: min-content;
        }

        div {
            height: 70px;
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            object-fit: contain;
            margin-right: 20px;
            cursor: pointer;

            @media (max-width: 1350px) {
                height: 55px;
                width: min-content;
                margin-right: 10px;
            }

            @media (max-width: 450px) {
                height: 40px;
            }
        }

        .notification-holder {
            position: relative;

            .notification {
                position: absolute;
                top: 10%;
                left: 1%;
                background-color: #E21616;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                box-shadow: 1px 1px 1px 0px #3b3b3b80;

                @media (max-width: 450px) {
                    top: -25%;
                    left: 0%;
                }

                p {
                    text-align: center;
                    margin-block-start: 0px;
                    margin-block-end: 0px;
                    color: white;
                    font-size: 12px;
                    font-weight: 600;
                }
            }

        }
    }

    .navigation-container {
        grid-column: 2 / span 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @media (max-width: 1350px) {
            width: 50%;
            height: 100%;
        }

        @media (max-width: 650px) {
            width: min-content;
        }
    }

    .guestpass-container {
        grid-column: 3 / span 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding-right: 50px;

        @media (max-width: 1350px) {
            width: 25%;
            padding-right: 10px;
            height: 100%;
        }

        @media (max-width: 450px) {
            width: min-content;
        }

        .notification-holder {
            position: relative;
        }

        div {
            height: 70px;
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            object-fit: contain;
            margin-right: 20px;
            cursor: pointer;

            @media (max-width: 1350px) {
                height: 55px;
                width: min-content;
                margin-left: 10px;
                margin-right: 0px;
            }

            @media (max-width: 450px) {
                height: 40px;
            }
        }
    }
`;

const StyledLinkTab = styled.div`
    width: 80px;
    height: 58px;
    border-radius: 3px;
    margin: 0px 10px 0px 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;
    color: #3b3b3b;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    box-shadow: 1px 1px 1px 1px #3b3b3b80;
    border: 1px solid #80808080;
    font-family: 'Roboto Slab', serif;

    @media (max-width: 650px) {
        width: 45px;
        height: 60px;
        margin: 0px 2px 0px 2px;
        font-size: 11px;
    }

    &:hover {
       text-decoration: none;
    }
`

class Header extends Component {

    constructor() {
        super();
        this.state = {

        }
    }

    handleModalShowLink = () => {
        this.props.setGuestpassGenerateModalView(!this.props.guestpassGenerateModalView);
    }

    handleModalShowDropbox = () => {
        this.props.setGuestpassDropboxModalView(!this.props.guestpassDropboxModalView);
    }

    logout = () => {
        console.log("LOGGING OUT");
        this.props.resetPresentation();
        this.props.resetTiles();
        this.props.resetVisit();
        this.props.setCurrentPage('');
        this.props.resetUser();
        this.props.setCompanyUserFilter("company");
        this.props.setByUserView(true);
        this.props.history.push('/');
        this.props.setIsAuth(false);
    }

    returnToPresentations = () => {
        this.props.resetPresentation();
        this.props.resetTiles();
        this.props.resetVisit();
        this.props.setCurrentPage('');
        this.props.history.push('/select');
    }

    render() {

        let guestPassFileCount = 0;
        if (this.props.files?.length) {
            guestPassFileCount = this.props.files.filter(file => file?.isGuestPass && !file?.isTempHidden).length;
        }

        return(
            <StyledHeaderContainer>
                <div className="exit-container">
                    <div className="notification-holder" data-tip="View Guest Pass Dropbox">
                        <div 
                            className="notification"
                            style={{
                                visibility: guestPassFileCount > 0 ? 'visible' : 'hidden'
                            }}
                            onClick={() => this.handleModalShowDropbox()}
                        >
                            <p>{guestPassFileCount <= 999 ? guestPassFileCount : "999+"}</p>
                        </div> 
                        <WorkRoundedIcon
                            className='icon'
                            style={{
                                fontSize: '48px',
                                color: 'white'
                            }}
                            onClick={() => this.handleModalShowDropbox()}
                        />
                    </div>
                    <div data-tip="Generate Guest Pass URL">
                        <PersonAddRoundedIcon
                            className='icon'
                            style={{
                                fontSize: '48px',
                                cursor: 'pointer',
                                color: 'white'
                            }}
                            onClick={() => this.handleModalShowLink()}
                        />
                    </div>
                </div>
                <div className="navigation-container">
                    {this.props.isNewPresentation ? (
                        <Link to='/intro' onClick={() => this.props.setCurrentPage('intro')} style={{textDecoration: "none"}}>
                            <StyledLinkTab
                                style={{
                                    backgroundColor: this.props.currentPage === 'intro' ? '#26D2B8' : 'white',
                                    color: this.props.currentPage === 'intro' ? 'white' : '#3b3b3b',
                                    textShadow: this.props.currentPage === 'intro' ? '1px 1px 1px #3b3b3b' : 'none'
                                }}
                            >Intro</StyledLinkTab>
                        </Link>
                    ) : null}
                    {this.props.isScreenVisited ? (
                        <Link to='/screen' onClick={() => this.props.setCurrentPage('screen')} style={{textDecoration: "none"}}>
                            <StyledLinkTab
                                style={{
                                    backgroundColor: this.props.currentPage === 'screen' ? '#26D2B8' : 'white',
                                    color: this.props.currentPage === 'screen' ? 'white' : '#3b3b3b',
                                    textShadow: this.props.currentPage === 'screen' ? '1px 1px 1px #3b3b3b' : 'none'
                                }}
                            >Screen</StyledLinkTab>
                        </Link>
                    ) : null}
                    {this.props.isTilesVisited ? (
                        <Link to='/tiles' onClick={() => this.props.setCurrentPage('tiles')} style={{textDecoration: "none"}}>
                            <StyledLinkTab
                                style={{
                                    backgroundColor: this.props.currentPage === 'tiles' ? '#26D2B8' : 'white',
                                    color: this.props.currentPage === 'tiles' ? 'white' : '#3b3b3b',
                                    textShadow: this.props.currentPage === 'tiles' ? '1px 1px 1px #3b3b3b' : 'none'
                                }}
                            >Tiles</StyledLinkTab>
                        </Link>
                    ) : null}
                    {this.props.isSettingsVisited ? (
                        <Link to='/settings' onClick={() => this.props.setCurrentPage('settings')} style={{textDecoration: "none"}}>
                            <StyledLinkTab
                                style={{
                                    backgroundColor: this.props.currentPage === 'settings' ? '#26D2B8' : 'white',
                                    color: this.props.currentPage === 'settings' ? 'white' : '#3b3b3b',
                                    textShadow: this.props.currentPage === 'settings' ? '1px 1px 1px #3b3b3b' : 'none'
                                }}
                            >Settings</StyledLinkTab>
                        </Link>
                    ) : null}
                    {this.props.isExportVisited ? (
                        <Link to='/export' onClick={() => this.props.setCurrentPage('export')} style={{textDecoration: "none"}}>
                            <StyledLinkTab
                                style={{
                                    backgroundColor: this.props.currentPage === 'export' ? '#26D2B8' : 'white',
                                    color: this.props.currentPage === 'export' ? 'white' : '#3b3b3b',
                                    textShadow: this.props.currentPage === 'export' ? '1px 1px 1px #3b3b3b' : 'none'
                                }}
                            >Export</StyledLinkTab>
                        </Link>
                    ) : null}
                </div>
                <div className="guestpass-container">
                    <div>
                        <FolderRoundedIcon 
                            onClick={() => this.returnToPresentations()}
                            className='icon'
                            style={{
                                fontSize: '48px',
                                cursor: 'pointer',
                                color: 'white'
                            }}
                            data-tip="Change Presentation"
                        />
                    </div>
                    <div>
                        <ExitToAppRoundedIcon
                            className='icon'
                            style={{
                                fontSize: '48px',
                                cursor: 'pointer',
                                color: 'white'
                            }}
                            data-tip="Logout"
                            onClick={() => this.logout()}
                        />
                    </div>
                </div>
            </StyledHeaderContainer>
        );

    }
}

const mapStateToProps = state => {
    return {        
        isScreenVisited: state.visitReducer.isScreenVisited,
        isTilesVisited: state.visitReducer.isTilesVisited,
        isSettingsVisited: state.visitReducer.isSettingsVisited,
        isExportVisited: state.visitReducer.isExportVisited,
        isNewPresentation: state.presentationReducer.isNewPresentation,
        files: state.tileReducer.files,
        currentPage: state.visitReducer.currentPage,
        guestpassGenerateModalView: state.viewReducer.guestpassGenerateModalView,
        guestpassDropboxModalView: state.viewReducer.guestpassDropboxModalView,
        userView: state.viewReducer.userView
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setIsAuth: (bool) => dispatch(setIsAuth(bool)),
        resetVisit: () => dispatch(resetVisit()),
        resetPresentation: () => dispatch(resetPresentation()),
        resetTiles: () => dispatch(resetTiles()),
        setCurrentPage: (page) => dispatch(setCurrentPage(page)),
        setGuestpassGenerateModalView: (bool) => dispatch(setGuestpassGenerateModalView(bool)),
        setGuestpassDropboxModalView: (bool) => dispatch(setGuestpassDropboxModalView(bool)),
        setCompanyUserFilter: (string) => dispatch(setCompanyUserFilter(string)),
        resetUser: () => dispatch(resetUser()),
        setByUserView: (bool) => dispatch(setByUserView(bool))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));