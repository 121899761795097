import { 
    SET_OUTLINE_COLOR,
    SET_CURRENT_TILE_ID,
    SET_ALL_FILES,
    REFRESH_FILES,
    SET_CURRENT_TILE_FILES,
    REMOVE_FILE,
    EDIT_FILE,
    EDIT_FILE_GUEST_PASS,
    RESET_TILES
} 
from '../constants/actionTypes';

export function setOutlineColor(color) {
    return dispatch => {  
        return dispatch({  
            type: SET_OUTLINE_COLOR,
            payload: color
        });  
    }  
}

export function setCurrentTileId(id) {
    return dispatch => {  
        return dispatch({  
            type: SET_CURRENT_TILE_ID,
            payload: id
        });  
    }  
}

export function setAllFiles(files) {
    return dispatch => {  
        return dispatch({  
            type: SET_ALL_FILES,
            payload: files
        });  
    }  
}

export function refreshFiles() {
    return dispatch => {  
        return dispatch({  
            type: REFRESH_FILES
        });  
    }  
}

export function setCurrentTileFiles(files) {
    return dispatch => {  
        return dispatch({  
            type: SET_CURRENT_TILE_FILES,
            payload: files
        });  
    }  
}

export function removeFile(id) {
    return dispatch => {
        return dispatch({
            type: REMOVE_FILE,
            payload: id
        })
    }
}

export function editFile(fileData) {  
    return dispatch => {  
        return dispatch({  
            type: EDIT_FILE,
            payload: fileData
        });  
    }  
}; 

export function editFileGuestPass(fileData) {  
    return dispatch => {  
        return dispatch({  
            type: EDIT_FILE_GUEST_PASS,
            payload: fileData
        });  
    }  
}; 

export function resetTiles() {  
    return dispatch => {  
        return dispatch({  
            type: RESET_TILES
        });  
    }  
}; 