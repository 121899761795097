import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import PlayCircleFilledWhiteRoundedIcon from '@material-ui/icons/PlayCircleFilledWhiteRounded';
import {
    createFile,
    deleteFile,
    updateFile,
    updateTile,
    deleteTile,
    addVideoThumbnail
} from '../utils/API';
import {
    setCurrentTileId,
    setAllFiles,
    setCurrentTileFiles,
    refreshFiles,
    removeFile,
    editFile
} from '../actions/tileActions';
import {
    editTileTitle,
    editTileSubtitle,
    editTileDescription,
    editTileTags,
    removeTile,
    setPresentationSize,
    editTileOrder
} from '../actions/presentationActions';
import {
    setTileModalView
} from '../actions/viewActions';
// import ReactTooltip from 'react-tooltip';

const StyledBackdrop = styled.div`
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 4;
    background-color: #3b3b3bBF;
    overflow: hidden;
    top: 0;
`;

const StyledCreationModal = styled.div`
    height: 900px;
    width: 600px;
    background-color: white;
    position: absolute;
    z-index: ${props => props.active ? 5 : 0};
    top: calc(50% - 450px);
    left: calc(50% - 300px);
    box-shadow: 1px 1px 8px 1px #00000080;
    transition: all 0.3s ease;
    visibility: ${props => props.active ? `visible` : `hidden`};
    opacity: ${props => props.active ? 1 : 0};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (max-width: 1367px) and (orientation: landscape) {
        height: 600px;
        top: calc(50% - 350px);
    }

    @media (orientation: portrait) and (max-width: 1025px) {
        top: 2%;
    }

    .exit-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        width: 40px;
        z-index: 2;
    }

    .intro-box {
        position: absolute;
        top: 2px;
        left: 40px;
        height: 150px;
        width: calc(100% - 80px);
        display: grid;
        grid-template-rows: 1fr 1.5fr;
        z-index: 1;

        @media (max-width: 1367px) and (orientation: landscape) {
            height: min-content;
        }

        .title-box {
            grid-row: 1 / span 1;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 100%;
            min-width: 0;

            .title {
                height: 90%;
                width: 90%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 2%;
                padding-right: 2%;
                overflow: hidden;

                p {
                    margin-block: 0px;
                    font-family: 'Crimson Text', serif;
                    font-size: 28px;
                    font-weight: 600;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .change-box {
            display: flex;
            justify-content: center;
            align-items: center;

            .title-input {
                height: 100%;
                width: 35%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .title-box {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 90%;
        
                    p {
                        margin-block: 0px;
                        color: #3b3b3b;
                        font-size: 14px;
                    }
                }
        
                input {
                    height: 30px;
                    width: 90%;
                    padding: 1% 2% 1% 2%;
                    text-align: center;
                    font-style: ${props => props.length ? `normal` : `italic`};
                    text-align: left;
                }
            }
            
            .subtitle-input {
                height: 100%;
                width: 35%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .title-box {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    width: 90%;
        
                    p {
                        margin-block: 0px;
                        color: #3b3b3b;
                        font-size: 14px;
                    }
                }
        
                input {
                    height: 30px;
                    width: 90%;
                    padding: 1% 2% 1% 2%;
                    text-align: center;
                    font-style: ${props => props.length ? `normal` : `italic`};
                    text-align: left;
                }
            }

            .submit-box {
                height: 100%;
                width: 30%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                z-index: 1;
                flex-direction: column;

                section {
                    height: 25px;
                    width: 100%;
                }
    
                .submit-button {
                    height: 30px;
                    width: 90px;
                    background-color: rgb(7, 104, 176);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    box-shadow: 1px 1px 2px 0px #3b3b3b80;
                    cursor: pointer;
                    margin-left: 13px;

                    @media (max-width: 1367px) and (orientation: landscape) {
                        position: relative;
                        bottom: auto;
                    }
    
                    p {
                        margin-block: 0px;
                        font-size: 15px;
                        color: white;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .desc-tags-box {
        position: absolute;
        bottom: 580px;
        left: 5%;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        height: 175px;
        flex-direction: column;

        @media (max-width: 1367px) and (orientation: landscape) {
            bottom: 320px;
        }


        .tile-tags {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40%;
            width: 100%;

            .tags-list-box {
                width: 100%;
                height: 100%;
            }

            .tags-input-box {
                width: 70%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                
                div {
                    height: 25px;
                    width: 90%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    p {
                        margin-block: 0px;
                        color: #3b3b3b;
                        font-size: 14px;
                        text-align: left;
                    }
                }

                input {
                    height: 30px;
                    width: 90%;
                    padding: 1% 2% 1% 2%;
                    text-align: center;
                    font-style: italic;
                    text-align: left;
                }

                section {
                    height: 30px;
                    width: 90%;
                    padding: 1% 2% 1% 2%;
                    background-color: #3b3b3b13;
                    overflow: hidden;

                    p {
                        margin-block: 0px;
                        color: #3b3b3b;
                        font-size: 14px;
                        text-align: left;
                        font-family: 'Space Mono', monospace;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .tags-upload-box {
                width: 30%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                align-items: center;

                section {
                    height: 25px;
                    width: 100%;
                }

                .upload-button {
                    height: 30px;
                    width: 90px;
                    background-color: rgb(7,104,176);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    box-shadow: 1px 1px 2px 0px #3b3b3b80;
                    cursor: pointer;

                    p {
                        margin-block: 0px;
                        font-size: 15px;
                        color: white;
                        font-weight: 600;
                    }
                }
            }
        }

        .tile-description {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60%;
            width: 100%;

            .desc-list-box {
                width: 100%;
                height: 100%;
            }

            .desc-input-box {
                width: 70%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                
                div {
                    height: 25px;
                    width: 90%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    p {
                        margin-block: 0px;
                        color: #3b3b3b;
                        font-size: 14px;
                        text-align: left;
                    }
                }

                textarea {
                    height: 100px;
                    width: 90%;
                    padding: 1% 2% 1% 2%;
                    font-style: italic;
                    text-align: left;
                }

                section {
                    height: 100px;
                    width: 90%;
                    padding: 1% 2% 1% 2%;
                    background-color: #3b3b3b13;
                    overflow: hidden;

                    p {
                        margin-block: 0px;
                        color: #3b3b3b;
                        font-size: 14px;
                        text-align: left;
                        font-family: 'Space Mono', monospace;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 100%;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                    }
                }
            }

            .desc-upload-box {
                width: 30%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                align-items: center;

                section {
                    height: 25px;
                    width: 100%;
                }

                .upload-button {
                    height: 30px;
                    width: 90px;
                    background-color: rgb(7,104,176);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    box-shadow: 1px 1px 2px 0px #3b3b3b80;
                    cursor: pointer;

                    p {
                        margin-block: 0px;
                        font-size: 15px;
                        color: white;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .upload-box {
        position: absolute;
        left: 5%;
        width: 90%;
        height: 50px;
        bottom: 525px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;

        @media (max-width: 1367px) and (orientation: landscape) {
            width: min-content;
            bottom: 296px;
            left: auto;
            right: 6%;
        }

        .upload-button {
            cursor: pointer;
            height: 35px;
            width: 130px;
            border-radius: 5px;
            background-color: #3b3b3b;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 1px 1px 2px 1px #3b3b3b40;
            margin-left: 5px;
            margin-right: 5px;

            @media (max-width: 1367px) and (orientation: landscape) {
                margin: 0px;
                margin-top: -5px;
                height: 35px;
                width: 80px;
            }

            p {
                margin-block: 0px;
                color: white;
                font-size: 14px;
                font-weight: 600;
                font-family: 'Crimson Text', serif;
                text-align: center;

                @media (max-width: 1367px) and (orientation: landscape) {
                    font-size: 12px;
                }
            }
        }
    }

    .file-box {
        position: absolute;
        left: 5%;
        width: 90%;
        height: 500px;
        bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 1367px) and (orientation: landscape) {
            height: 300px;
            bottom: 10px;
        }
        
        .files {
            height: 98%;
            width: 98%;
            background-color: #3b3b3b40;
            border-radius: 3px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            overflow-y: auto;
            overflow-x: hidden;
            flex-wrap: wrap;
        }
    }
`;

const StyledFileContainer = styled.div`
    margin: calc(2% - 2px) calc(1% - 2px);
    width: 31.33%;
    height: 21%;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 2px;
    flex-shrink: 0;

    @media (max-width: 1367px) and (orientation: landscape) {
        height: 32%;
    }

    .add-tile {
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 2px;
        box-shadow: 2px 2px 3px 1px #3b3b3b80;
    }

    .delete-tile {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 35px;
        z-index: 2;
    }

    .unique-image-thumb-cover {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3b3b3b80;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        p {
            margin-block: 0px;
            font-size: 14px;
            font-weight: 600;
            color: white;
            font-family: 'Space Mono', sans-serif;
            text-shadow: 1px 1px black;
        }
    }
`;

const StyledVideo = styled.video`
    max-width: 100%; 
    max-height: 100%;
    width: 100%;
    height: auto; 
    background-size: cover;
    overflow: hidden;
    filter: drop-shadow(0 0 0.4rem #3b3b3b80);
`;

const StyledInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

const StyledFileError = styled.div`
    width: 230px;
    height: 150px;
    background-color: #3b3b3b;
    position: absolute;
    left: calc(50% - 115px);
    top: calc(50% - 75px);
    box-shadow: 2px 2px 3px 1px #3b3b3b80;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding: 1%;

    p {
        margin-block: 0px;
        color: white;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }
`;

const StyledPDFAndVideoHolder = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-item: center;
    background-color: white;
    border-radius: 3px;
    object-fit: cover;
    flex-shrink: 0;

    img {
        z-index: 1;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        position: absolute;
        height: 40px;
        width: 40px;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
    }

    .name {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100%;
        max-width: 100%;
        height: 20px;
        padding-left: 5px;
        padding-right: 5px;
        z-index: 2;
        position: absolute;
        top: calc(50% + 20px);
        left: 0;
        border-radius: 0px !important;

        p {
            font-size: 12px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-block: 0px;
        }
    }
`;


class TileModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: '',
            subtitle: '',
            errorVisible: false,
            errorMessage: '',
            tags: '',
            description: ''
        }
    }

    clearLocal = () => {
        this.setState({title: ''});
        this.setState({subtitle: ''});
    }

    clearTags = () => {
        this.setState({tags: ''});
    }

    clearDescription = () => {
        this.setState({description: ''});
    }

    setFilesForTile = () => {
        this.props.refreshFiles();
        let filter = this.props.files.filter(file => 
            (file.tileId === this.state.tileId)
        );
        this.props.setCurrentTileFiles(filter);
    }

    onChangeTitle = (event) => {
        this.setState({ title: event.target.value })
    }

    onChangeSubtitle = (event) => {
        this.setState({ subtitle: event.target.value })
    }

    updateTileDetails = () => {
        let updatedTile = {};
        if (this.state.title !== '') {
            updatedTile.Title = this.state.title;
        }
        if (this.state.subtitle !== '') {
            updatedTile.SubTitle = this.state.subtitle;
        }
        updateTile(this.props.currentTileId, updatedTile);
        let updatedTitleRedux = {
            id: this.props.currentTileId,
            title: this.state.title,
        }
        let updatedSubtitleRedux = {
            id: this.props.currentTileId,
            subtitle: this.state.subtitle
        }
        if (this.state.title !== '') {
            this.props.editTileTitle(updatedTitleRedux);
        }
        if (this.state.subtitle !== '') {
            this.props.editTileSubtitle(updatedSubtitleRedux);
        }
        this.clearLocal();
    }

    updateTileDescription = () => {
        let updatedTile = {
            Description: this.state.description
        };
        updateTile(this.props.currentTileId, updatedTile);
        let updatedTileRedux = {
            id: this.props.currentTileId,
            description: this.state.description,
        }
        this.props.editTileDescription(updatedTileRedux);
        this.clearDescription();
    }

    deleteTileDescription = () => {
        let updatedTile = {
            Description: ""
        };
        updateTile(this.props.currentTileId, updatedTile);
        let updatedTileRedux = {
            id: this.props.currentTileId,
            description: ""
        }
        this.props.editTileDescription(updatedTileRedux);
        this.clearDescription();
    }

    updateTileTags = () => {
        let trimmedTags = this.state.tags.replace(/\s+/g, '');
        let updatedTile = {
            Tags: trimmedTags
        };
        updateTile(this.props.currentTileId, updatedTile);
        let updatedTileRedux = {
            id: this.props.currentTileId,
            tags: trimmedTags,
        }
        this.props.editTileTags(updatedTileRedux);
        this.clearTags();
    }

    deleteTileTags = () => {
        let updatedTile = {
            Tags: ""
        };
        updateTile(this.props.currentTileId, updatedTile);
        let updatedTileRedux = {
            id: this.props.currentTileId,
            tags: ""
        }
        this.props.editTileTags(updatedTileRedux);
        this.clearTags();
    }

    handleUploadUniqueImageThumb = (event, totalSize) => {
        let thumbFile = this.props.files.find(file => (file?.isThumbnail) && (file?.tileId === this.props.currentTileId));
        // console.log("EVENT", event);
        if (thumbFile) {
            if (thumbFile.uniqueImageThumbnail === true) {
                deleteFile(thumbFile.id, this.props.currentTileId, this.props.presentationId).then((res) => {
                    console.log("FILE DELETE RESPONSE - UNIQUE IMAGE THUMBNAIL", res);
                    this.props.removeFile(thumbFile.id);
                });
            } else {
                let thumbnailObject = {
                    isThumbnail: false
                }
                updateFile(thumbFile.id, thumbnailObject);
                let updatedFileRedux = {
                    id: thumbFile.id,
                    isThumbnail: false
                }
                this.props.editFile(updatedFileRedux);
            }
        }
        let uploadFile = event.target.files[0];
        let formData = new FormData();
        formData.append('file', uploadFile);
        if ((uploadFile.size && uploadFile.size < 100000000 && (totalSize + uploadFile.size < 500000000)) || (uploadFile.size && this.props.fileOverrideBool)) {
            console.log("HIT IF");
            console.log("UPLOAD SIZE", uploadFile);
            console.log("FILE OVERLOAD", this.props.fileOverrideBool);
            let fillerId = uuidv4();
            let fillerFile = {
                id: fillerId,
                type: 'filler',
                tileId: this.props.currentTileId
            };
            this.props.setAllFiles(fillerFile);
            createFile(this.props.currentTileId, formData, true, false, true).then((res) => {
                if (res.data.errors) {
                    this.setState({errorMessage: `File ${res.data.errors[0].value} too large or already exists within tile.`});
                    this.setState({errorVisible: true});
                    setTimeout(() => {
                        this.setState({errorVisible: false});
                    }, 3000);
                    this.props.removeFile(fillerId);
                } else {
                    console.log("FILE RES", res.data);
                    this.props.setAllFiles(res.data.file);
                    this.props.removeFile(fillerId);
                }
            });
        } else {
            console.log("HIT ELSE");
            if (uploadFile.size && (totalSize + uploadFile.size > 500000000)) {
                this.setState({errorMessage: 'Data limit reached.'});
            } else {
                this.setState({errorMessage: `Uploaded files (${uploadFile.name}) cannot be larger than 100MB.`});
            }
            this.setState({errorVisible: true});
            setTimeout(() => {
                this.setState({errorVisible: false});
            }, 3000);
        }
    }


    handleUploadFile = (event, totalSize) => {
        let thumbFile = this.props.files.find(file => (file?.isThumbnail) && (file?.tileId === this.props.currentTileId));
        let hasThumb;
        if (thumbFile !== undefined) {
            hasThumb = true;
        } else {
            hasThumb = false;
        }
        for (let i = 0; i < event.target.files.length; i++) {
            let uploadFile = event.target.files[i];
            // console.log("THIS IS A FILE", uploadFile);
            let formData = new FormData();
            formData.append('file', uploadFile);
            if ((uploadFile.size && uploadFile.size < 100000000 && (totalSize + uploadFile.size < 500000000)) || (uploadFile.size && this.props.fileOverrideBool)) {
                console.log("HIT IF");
                console.log("UPLOAD SIZE", uploadFile);
                console.log("FILE OVERLOAD", this.props.fileOverrideBool);
                let thumbBool = false;
                if (hasThumb === false && (uploadFile.type === "image/jpeg" || uploadFile.type === "image/png")) {
                    thumbBool = true;
                    hasThumb = true;
                } else {
                    thumbBool = false;
                }
                let fillerId = uuidv4();
                let fillerFile = {
                    id: fillerId,
                    type: 'filler',
                    tileId: this.props.currentTileId
                };
                this.props.setAllFiles(fillerFile);
                createFile(this.props.currentTileId, formData, thumbBool, false, false).then((res) => {
                    if (res.data.errors) {
                        this.setState({errorMessage: `File ${res.data.errors[0].value} too large or already exists within tile.`});
                        this.setState({errorVisible: true});
                        setTimeout(() => {
                            this.setState({errorVisible: false});
                        }, 3000);
                        this.props.removeFile(fillerId);
                    } else {
                        console.log("FILE RES", res.data);
                        this.props.setAllFiles(res.data.file);
                        this.props.removeFile(fillerId);
                    }
                });
            } else {
                console.log("HIT ELSE");
                if (uploadFile.size && (totalSize + uploadFile.size > 500000000)) {
                    this.setState({errorMessage: 'Data limit reached.'});
                } else {
                    this.setState({errorMessage: `Uploaded files (${uploadFile.name}) cannot be larger than 100MB.`});
                }
                this.setState({errorVisible: true});
                setTimeout(() => {
                    this.setState({errorVisible: false});
                }, 3000);
            }
        }
    }

    setAsThumbnail = (event, fileId, tileFiles) => {
        event.stopPropagation();
        // console.log("EVENT", event);
        for (let file of tileFiles) {
            if (file.isThumbnail === true) {
                if (file.uniqueImageThumbnail === true) {
                    // old thumbnail is a Unique Image Thumbnail - delete that whole file
                    deleteFile(file.id, this.props.currentTileId, this.props.presentationId).then((res) => {
                        console.log("FILE DELETE RESPONSE - UNIQUE IMAGE THUMBNAIL", res);
                        this.props.removeFile(file.id);
                    });
                } else {
                    // old thumbnail is normal image - just remove isThumbnail status
                    let thumbnailObject = {
                        isThumbnail: false
                    }
                    updateFile(file.id, thumbnailObject);
                    let updatedFileRedux = {
                        id: file.id,
                        isThumbnail: false
                    }
                    this.props.editFile(updatedFileRedux);
                }
            }
        }
        let newThumbnailObject = {
            isThumbnail: true
        }
        updateFile(fileId, newThumbnailObject);
        let newFileRedux = {
            id: fileId,
            isThumbnail: true
        }
        this.props.editFile(newFileRedux);
    }

    destroyFile = (event, fileId) => {
        event.stopPropagation();
        let thumbnailFile = this.props.files?.find(file => file?.thumbnailOfId  === fileId);
        if (thumbnailFile) {
            deleteFile(thumbnailFile.id, this.props.currentTileId, this.props.presentationId).then((thumbRes) => {
                this.props.removeFile(thumbnailFile.id);
                console.log("THUMBNAIL DELETE RESPONSE", thumbRes);
                deleteFile(fileId, this.props.currentTileId, this.props.presentationId).then((res) => {
                    console.log("FILE DELETE RESPONSE", res);
                    this.props.removeFile(fileId);
                    if (!this.props.files.filter(file => file.tileId === this.props.currentTileId).length) {
                        console.log("NO FILES REMAIN - DELETING TILE");
                        deleteTile(this.props.currentTileId).then(deleteTileRes => {
                            let tileOrder = this.props.presentationTiles.find(tile => tile.id === this.props.currentTileId).order;
                            let otherTiles = this.props.presentationTiles.filter(tile => tile.id !== this.props.currentTileId);
                            for (let tempTile of otherTiles) { // for every file except the tile we're removing (we do this to avoid possible async state update not being fast enough)
                                if (tempTile.order !== null) { // check null order
                                    if (tempTile.order > tileOrder) { // if the tile was after the removed tile in order
                                        // push the order back one in state and database
                                        let orderState = {
                                            id: tempTile.id,
                                            order: tempTile.order - 1
                                        }
                                        let newOrder = {
                                            order: tempTile.order - 1
                                        }
                                        this.props.editTileOrder(orderState);
                                        updateTile(tempTile.id, newOrder);
                                    } 
                                }
                            }
                            this.props.removeTile(this.props.currentTileId);
                            this.handleModalShow();
                        })
                    }
                });
            });
        } else {
            deleteFile(fileId, this.props.currentTileId, this.props.presentationId).then((res) => {
                console.log("FILE DELETE RESPONSE", res);
                this.props.removeFile(fileId);
                if (!this.props.files.filter(file => file.tileId === this.props.currentTileId).length) {
                    console.log("NO FILES REMAIN - DELETING TILE");
                    deleteTile(this.props.currentTileId).then(tileDeleteRes => {
                        let tileOrder = this.props.presentationTiles.find(tile => tile.id === this.props.currentTileId).order;
                        let otherTiles = this.props.presentationTiles.filter(tile => tile.id !== this.props.currentTileId);
                        for (let tempTile of otherTiles) { // for every file except the tile we're removing (we do this to avoid possible async state update not being fast enough)
                            if (tempTile.order !== null) { // check null order
                                if (tempTile.order > tileOrder) { // if the tile was after the removed tile in order
                                    // push the order back one in state and database
                                    let orderState = {
                                        id: tempTile.id,
                                        order: tempTile.order - 1
                                    }
                                    let newOrder = {
                                        order: tempTile.order - 1
                                    }
                                    this.props.editTileOrder(orderState);
                                    updateTile(tempTile.id, newOrder);
                                } 
                            }
                        }
                        this.props.removeTile(this.props.currentTileId);
                        this.handleModalShow();
                    });
                }
            });
        }
    }

    tempHideFile = (event, fileId) => {
        event.stopPropagation();
        let update = {
            isTempHidden: true
        }
        updateFile(fileId, update).then((res) => {
            console.log("FILE HIDE RESPONSE", res);
            this.props.removeFile(fileId);
            if (!this.props.files.filter(file => file.tileId === this.props.currentTileId).length) {
                updateTile(this.props.currentTileId, update);
                this.props.removeTile(this.props.currentTileId);
                this.handleModalShow();
            }
        })
    }

    handleModalShow = () => {
        this.clearLocal();
        this.clearDescription();
        this.clearTags();
        this.props.setTileModalView(!this.props.tileModalView);
    }

    handleUploadVideoThumb = (event, tileId, fileId, hasThumb) => {
        console.log("EVENT", event);
        event.stopPropagation();
        if (event.target.files[0]) {
            let uploadFile = event.target.files[0];
            let formData = new FormData();
            formData.append('file', uploadFile);
            // this.props.setAllFiles(fillerFile);
            if (hasThumb) {
                let thumbnailFile = this.props.files?.find(file => file?.thumbnailOfId  === fileId);
                deleteFile(thumbnailFile.id, tileId, this.props.presentationId).then((thumbRes) => {
                    this.props.removeFile(thumbnailFile.id);
                    console.log("DELETING OLD THUMB", thumbRes);
                    addVideoThumbnail(tileId, fileId, formData).then((res) => {
                        console.log("FILE RES", res.data);
                        this.props.setAllFiles(res.data.file);
                    });
                });
            } else {
                addVideoThumbnail(tileId, fileId, formData).then((res) => {
                    console.log("FILE RES", res.data);
                    this.props.setAllFiles(res.data.file);
                });
            }
        }
    }

    render() {

        let currentTile;
        if (this.props.presentationTiles?.length) {
            currentTile = this.props.presentationTiles.find(tile => tile.id === this.props.currentTileId);
        }

        let currentTileFiles = null;
        let allTileFiles = null;
        if (this.props.files?.filter(file => file?.tileId === this.props?.currentTileId)?.length) {
            currentTileFiles = this.props.files?.filter(file => (file?.tileId === this.props?.currentTileId) && (!file?.isTempHidden) && (!file?.thumbnailOfId));
            allTileFiles = this.props.files?.filter(file => file?.tileId === this.props?.currentTileId);
        }
        let currentTileFilesMapping = null;
        if (currentTileFiles?.length > 0) {
            currentTileFilesMapping = currentTileFiles.map((file) => {
                if (file.type === 'image/jpeg' || file.type === 'image/png') {
                    return(
                        <StyledFileContainer
                            key={file.id}
                            id={file.id}
                            isHovered={this.state.hoveredItemId === file.id}
                            isThumbnail={file.isThumbnail}
                            style={{cursor: 'pointer', border: file.isThumbnail ? '4px solid yellow' : '4px solid transparent'}}
                            onClick={(event) => this.setAsThumbnail(event, file.id, allTileFiles)}
                        >
                            {file.uniqueImageThumbnail === true ? (
                                <div className='unique-image-thumb-cover'>
                                    <p>Thumbnail Only</p>
                                </div>
                            ) : null}
                            <img src={file.url} alt="" 
                                style={{
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                    height: "100%",
                                    filter: 'drop-shadow(0 0 0.4rem #3b3b3b80)'
                                }}
                            />
                            <div className='delete-tile' onClick={(event) => this.destroyFile(event, file.id)}>
                                <CancelRoundedIcon 
                                    style={{
                                        color: 'rgb(255, 25, 64)',
                                        fontSize: '24px'
                                    }}
                                />
                            </div>
                        </StyledFileContainer>
                    )
                } else if (file.type === 'video/x-mastroka' || file.type === 'video/mp4' || file.type === 'video/mov') {
                    let InputRef = React.createRef();
                    let videoThumb = this.props.files?.find(foundFile => foundFile?.thumbnailOfId === file.id);
                    return(
                        <StyledFileContainer
                            key={file.id}
                            id={file.id}
                            isHovered={this.state.hoveredItemId === file.id}
                            isThumbnail={file.isThumbnail}
                            style={{cursor: videoThumb ? 'pointer' : 'default', border: videoThumb?.isThumbnail ? '4px solid yellow' : '4px solid transparent'}}
                            onClick={videoThumb ? (event) => this.setAsThumbnail(event, videoThumb.id, allTileFiles) : null}
                        >
                            <input type='file' accept=".jpg,.png" multiple={false} ref={InputRef} style={{height: '0.1px', width: '0.1px', display: 'none'}} onChange={(event) => this.handleUploadVideoThumb(event, this.props.currentTileId, file.id, videoThumb ? true : false)}/>
                            {/* <StyledVideo controls={false}>
                                <source src={file.url} type="video/mp4"/>
                            </StyledVideo> */}
                            <StyledPDFAndVideoHolder
                                style={{
                                    backgroundColor: videoThumb ? 'transparent' : 'white'
                                }}
                            >
                                {videoThumb ? (
                                    <img src={videoThumb.url} alt="" 
                                        style={{
                                            maxHeight: "100%",
                                            maxWidth: "100%",
                                            height: "100%",
                                            filter: 'drop-shadow(0 0 0.4rem #3b3b3b80)'
                                        }}
                                    />
                                ): null}
                                <div className='icon'>
                                    <PlayCircleFilledWhiteRoundedIcon
                                        style={{
                                            fontSize: '32px',
                                            color: this.props.outlineColor !== '' ? this.props.outlineColor : '#0768b0'
                                        }}
                                    />
                                </div>
                                <div 
                                    className='name'
                                    style={{
                                        backgroundColor: videoThumb ? '#FFFFFF80' : 'transparent',
                                        borderRadius: '3px'
                                    }}
                                >
                                    <p>{decodeURIComponent(file.name).slice(0, -4)}</p>
                                </div>
                            </StyledPDFAndVideoHolder>
                            <div className='delete-tile' onClick={(event) => this.destroyFile(event, file.id)}>
                                <CancelRoundedIcon 
                                    style={{
                                        color: 'rgb(255, 25, 64)',
                                        fontSize: '24px'
                                    }}
                                />
                            </div>
                            <div className='delete-tile' style={{right: 'auto', left: 0, visibility: !videoThumb ? 'visible' : 'visible'}} onClick={() => InputRef.current.click()}>
                                <PublishRoundedIcon 
                                    style={{
                                        color: '#3b3b3b',
                                        fontSize: '24px'
                                    }}
                                />
                            </div>
                        </StyledFileContainer>
                    )
                } else if (file.type === 'application/pdf') {
                    let PdfRef = React.createRef();
                    let pdfThumb = this.props.files?.find(foundFile => foundFile?.thumbnailOfId === file.id);
                    return (
                        <StyledFileContainer
                            key={file.id}
                            id={file.id}
                            isHovered={this.state.hoveredItemId === file.id}
                            isThumbnail={file.isThumbnail}
                            style={{cursor: pdfThumb ? 'pointer' : 'default', border: pdfThumb?.isThumbnail ? '4px solid yellow' : '4px solid transparent'}}
                            onClick={pdfThumb ? (event) => this.setAsThumbnail(event, pdfThumb.id, allTileFiles) : null}
                        >
                            <input type='file' accept=".jpg,.png" multiple={false} ref={PdfRef} style={{height: '0.1px', width: '0.1px', display: 'none'}} onChange={(event) => this.handleUploadVideoThumb(event, this.props.currentTileId, file.id, pdfThumb ? true : false)}/>
                            <StyledPDFAndVideoHolder
                                style={{
                                    backgroundColor: pdfThumb ? 'transparent' : 'white'
                                }}
                            >
                                {pdfThumb ? 
                                    (
                                        <img src={pdfThumb.url} alt="" 
                                        style={{
                                            maxHeight: "100%",
                                            maxWidth: "100%",
                                            height: "100%",
                                            filter: 'drop-shadow(0 0 0.4rem #3b3b3b80)'
                                        }}
                                        />
                                    ) :
                                null}
                                <div className='icon'>
                                    <PictureAsPdfRoundedIcon
                                        style={{
                                            fontSize: '32px',
                                            color: this.props.outlineColor !== '' ? this.props.outlineColor : '#0768b0'
                                        }}
                                    />
                                </div>
                                <div 
                                    className='name'
                                    style={{
                                        backgroundColor: pdfThumb ? '#FFFFFF80' : 'transparent',
                                        borderRadius: '3px'
                                    }}
                                >
                                    <p>{decodeURIComponent(file.name).slice(0, -4)}</p>
                                </div>
                            </StyledPDFAndVideoHolder>
                            <div className='delete-tile' onClick={(event) => this.destroyFile(event, file.id)}>
                                <CancelRoundedIcon 
                                    style={{
                                        color: 'rgb(255, 25, 64)',
                                        fontSize: '24px'
                                    }}
                                />
                            </div>
                            <div className='delete-tile' style={{right: 'auto', left: 0, visibility: !pdfThumb ? 'visible' : 'visible'}} onClick={() => PdfRef.current.click()}>
                                <PublishRoundedIcon 
                                    style={{
                                        color: '#3b3b3b',
                                        fontSize: '24px'
                                    }}
                                />
                            </div>
                        </StyledFileContainer>
                    )
                } else if (file.type ==='filler') {
                    return(
                        <StyledFileContainer
                            key={file.id}
                            id={file.id}
                            isHovered={this.state.hoveredItemId === file.id}
                            isThumbnail={file.isThumbnail}
                        >
                            <div style={{height: "100%", width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Spinner animation="border" role="status" variant="dark">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        </StyledFileContainer>
                    )
                } else {
                    return null;
                }
            });
        }

        return(
            <>
                <StyledBackdrop
                    style={{
                        visibility: this.props.tileModalView ? 'visible' : 'hidden'
                    }}
                    onClick={() => this.handleModalShow()}
                />
                <StyledFileError style={{
                    visibility: this.state.errorVisible ? 'visible' : 'hidden'
                }}>
                    <p>{this.state.errorMessage}</p>
                </StyledFileError>
                <StyledCreationModal 
                    active={this.props.tileModalView}
                >
                    <div className='exit-icon'
                        onClick={() => this.handleModalShow()}
                    >
                        <CancelRoundedIcon
                            style={{
                                color: '#ff1940',
                                fontSize: `32px`,
                                cursor: 'pointer',
                            }}
                        />
                    </div>
                    <div className='intro-box'>
                        <div className='title-box'>
                            <div className='title'>
                                <p>{currentTile?.Title !== '' ? currentTile?.Title : "Tile Details"} {currentTile?.SubTitle !== "" ? "-" : null} {currentTile?.SubTitle !== '' ? currentTile?.SubTitle : null}</p>
                            </div>
                        </div>
                        <div className='change-box'>
                            <div className='title-input'>
                                <div className='title-box'>
                                    <p>Title</p>
                                </div>
                                <input
                                    onChange={this.onChangeTitle}
                                    value={this.state.title}
                                    type="text"
                                    placeholder={currentTile ? currentTile.Title : 'Title'}
                                />
                            </div>
                            <div className='subtitle-input'>
                                <div className='title-box'>
                                    <p>Subtitle</p>
                                </div>
                                <input
                                    onChange={this.onChangeSubtitle}
                                    value={this.state.subtitle}
                                    type="text"
                                    placeholder={currentTile ? currentTile.SubTitle : 'Subtitle'}
                                />
                            </div>
                            <div className='submit-box'>
                                <section />
                                <div className='submit-button' onClick={() => this.updateTileDetails()}>
                                    <p>Update</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='desc-tags-box'>
                        <div className='tile-tags'>
                            <div className='tags-input-box'>
                                <div>
                                    <p>Tags</p>
                                </div>
                                {currentTile?.Tags && currentTile?.Tags?.length? (
                                    <section>
                                        <p>{currentTile.Tags}</p>
                                    </section>
                                ) : (
                                    <input 
                                        placeholder="Enter tags as comma-separated list"
                                        onChange={(event) => this.setState({tags: event.target.value})}
                                        value={this.state.tags}
                                    />
                                )}
                            </div>
                            <div className='tags-upload-box'>
                                <section />
                                <div 
                                    className='upload-button'
                                    onClick={currentTile?.Tags && currentTile.Tags?.length ? () => this.deleteTileTags() : () => this.updateTileTags()}
                                    style={{
                                        backgroundColor: currentTile?.Tags && currentTile.Tags?.length ? 'rgb(255, 25, 64)' : 'rgb(7,104,176)'
                                    }}
                                >
                                    <p>{currentTile?.Tags && currentTile.Tags?.length ? 'Clear' : 'Update'}</p>
                                </div>
                            </div>
                        </div>
                        <div className='tile-description'>
                            <div className='desc-input-box'>
                                <div>
                                    <p>Description</p>
                                </div>
                                {currentTile?.Description && currentTile.Description?.length ? (
                                    <section>
                                        <p>{currentTile.Description}</p>
                                    </section>
                                ) : (
                                    <textarea
                                        onChange={(event) => this.setState({description: event.target.value})}
                                        value={this.state.description}
                                    />
                                )}
                            </div>
                            <div className='desc-upload-box'>
                                <section />
                                <div 
                                    className='upload-button' 
                                    onClick={currentTile?.Description && currentTile.Description?.length ? () => this.deleteTileDescription() : () => this.updateTileDescription()}
                                    style={{
                                        backgroundColor: currentTile?.Description && currentTile.Description?.length ? 'rgb(255, 25, 64)' : 'rgb(7,104,176)'
                                    }}
                                >
                                    <p>{currentTile?.Description && currentTile.Description?.length ? 'Clear' : 'Update'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='upload-box'>
                        <label className='upload-button' for="addFile">
                            <p>Upload Files</p>
                        </label>
                        <StyledInput type="file" multiple={true} accept=".jpg,.png,.mp4,.pdf" onChange={(event) => {this.handleUploadFile(event, this.props.totalFileSize); event.target.value=""}} name="addFile" id="addFile"></StyledInput>
                        <label className='upload-button' for="addThumbnail">
                            <p>Upload Tile Thumb</p>
                        </label>
                        <StyledInput type="file" multiple={false} accept=".jpg,.png" onChange={(event) => {this.handleUploadUniqueImageThumb(event, this.props.totalFileSize); event.target.value=""}} name="addThumbnail" id="addThumbnail"></StyledInput>
                    </div>
                    <div className='file-box'>
                        <div className='files'>
                            {currentTileFilesMapping}
                        </div>
                    </div>
                </StyledCreationModal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {        
        currentTileId: state.tileReducer.currentTileId,
        files: state.tileReducer.files,
        presentationTiles: state.presentationReducer.presentationTiles,
        presentationId: state.presentationReducer.id,
        currentTileFiles: state.tileReducer.currentTileFiles,
        fontColor: state.presentationReducer.fontColor,
        tileModalView: state.viewReducer.tileModalView,
        presentationSize: state.presentationReducer.presentationSize,
        fileOverrideBool: state.presentationReducer.fileOverrideBool
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setCurrentTileId: (id) => dispatch(setCurrentTileId(id)),
        setAllFiles: (files) => dispatch(setAllFiles(files)),
        refreshFiles: () => dispatch(refreshFiles()),
        setCurrentTileFiles: (files) => dispatch(setCurrentTileFiles(files)),
        removeFile: (id) => dispatch(removeFile(id)),
        editTileTitle: (data) => dispatch(editTileTitle(data)),
        editTileSubtitle: (data) => dispatch(editTileSubtitle(data)),
        editFile: (data) => dispatch(editFile(data)),
        removeTile: (id) => dispatch(removeTile(id)),
        setTileModalView: (bool) => dispatch(setTileModalView(bool)),
        setPresentationSize: (file) => dispatch(setPresentationSize(file)),
        editTileTags: (tileData) => dispatch(editTileTags(tileData)),
        editTileDescription: (tileData) => dispatch(editTileDescription(tileData)),
        editTileOrder: (tileData) => dispatch(editTileOrder(tileData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TileModal);