import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
    setScreenVisit
} from '../actions/visitActions';


const StyledIntroContainer = styled.div`
    display: flex;
    justify-content: center;
    align: items: center;
    text-align: center;
    height: 87vh;
    width: 100%;

    @keyframes intro {
        from {opacity: 0; transform: translateX(-100px);}
        to {opacity: 1; transform: translateX(0px);}
    }

    .animation:hover {
        animation-name: intro;
        animation-duration: 2s;
        animation-iteration-count: 1;
    }
`;

const ContentBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    width: 55%;
`;

const StyledTitleContainer = styled.h1`
    font-family: 'Oswald', sans-serif;
    font-size: 3.8em;
    transition: all 2s;
    margin-bottom: 3%;
    font-weight: 550;
`;

const StyledTextContainer = styled.p`
    font-size: 1.3em;
    text-spacing: 0.015em;
    font-family: 'Noto Sans', sans-serif;
    line-height: 2.25;
    transition: all 1.5s;
    margin-bottom: 5%;
`;

const StyledButton = styled.button`
    height: 50px;
    width: 150px;
    color: white;
    background-color: #0768b0;
    font-family: 'Oswald', sans-serif;
    border-radius: 5px;
    font-size: 1.3em;
    letter-spacing: 0.035em;
    cursor: pointer;
`;

class Intro extends Component {

    constructor() {
        super();
        this.state = {
            animate: false
        }
    }

    componentDidMount = () => {
        if (this.props.isAuth !== true) {
            this.props.history.push('/')
        }
        setTimeout(() => {
            this.setState({ animate: true });
        }, 50);
    }

    continue = () => {
        this.props.setScreenVisit(true);
        this.props.history.push("/screen");
    }

    render() {

        return(
            <StyledIntroContainer>
                <ContentBox>
                    <StyledTitleContainer style={{opacity: this.state.animate ? 1 : 0}}>Welcome to the Remembrance Board Editor</StyledTitleContainer>
                    <StyledTextContainer style={{transform: this.state.animate ? "translateX(0px)" : "translateX(-50px)", opacity: this.state.animate ? 1 : 0}}>
                        We're going to start building our your presentation. All YOU have to do is a few simple tasks - choose a backdrop, set some colors and settings,
                        and upload your content - and you're done! 
                        Users who desire an even finer touch can edit their content further, adding titles, descriptions, and more - but it's never required.
                        Setting up your Remembrance Board has never been easier - and we'll be with you every step of the way. When you're ready to get started, click 'Next' below.
                    </StyledTextContainer>
                    <StyledButton onClick={() => this.continue()}>Next</StyledButton>
                </ContentBox>
            </StyledIntroContainer>
        );

    }
}

const mapStateToProps = state => {
    return {        
        isAuth: state.authReducer.isAuth
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setScreenVisit: (bool) => dispatch(setScreenVisit(bool))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Intro);