import { combineReducers } from 'redux';
import presentationReducer from './presentationReducer';
import tileReducer from './tileReducer';
import authReducer from './authReducer';
import visitReducer from './visitReducer';
import viewReducer from './viewReducer';

export default combineReducers({
    presentationReducer,
    tileReducer,
    authReducer,
    visitReducer,
    viewReducer
});