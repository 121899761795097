import {
    SET_CREATE_PRESENTATION_MODAL_VIEW,
    SET_BACKGROUND_COLOR_MODAL_VIEW,
    SET_GUESTPASS_GENERATE_MODAL_VIEW,
    SET_GUESTPASS_DROPBOX_MODAL_VIEW,
    SET_ICON_COLOR_MODAL_VIEW,
    SET_TILE_COLOR_MODAL_VIEW,
    SET_FONT_COLOR_MODAL_VIEW,
    SET_ADD_TILE_MODAL_VIEW,
    SET_TILE_MODAL_VIEW,
    SET_FILE_OVERRIDE_MODAL_VIEW,
    SET_COMPANY_USER_FILTER
} from '../constants/actionTypes';

export function setCreatePresentationModalView(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_CREATE_PRESENTATION_MODAL_VIEW,
            payload: bool
        });  
    }  
}; 

export function setBackgroundColorModalView(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_BACKGROUND_COLOR_MODAL_VIEW,
            payload: bool
        });  
    }  
}; 

export function setGuestpassGenerateModalView(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_GUESTPASS_GENERATE_MODAL_VIEW,
            payload: bool
        });  
    }  
}; 

export function setGuestpassDropboxModalView(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_GUESTPASS_DROPBOX_MODAL_VIEW,
            payload: bool
        });  
    }  
}; 

export function setIconColorModalView(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_ICON_COLOR_MODAL_VIEW,
            payload: bool
        });  
    }  
};

export function setFileOverrideModalView(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_FILE_OVERRIDE_MODAL_VIEW,
            payload: bool
        });  
    }  
};

export function setTileColorModalView(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_TILE_COLOR_MODAL_VIEW,
            payload: bool
        });  
    }  
}; 

export function setFontColorModalView(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_FONT_COLOR_MODAL_VIEW,
            payload: bool
        });  
    }  
}; 

export function setAddTileModalView(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_ADD_TILE_MODAL_VIEW,
            payload: bool
        });  
    }  
}; 

export function setTileModalView(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_TILE_MODAL_VIEW,
            payload: bool
        });  
    }  
}; 

export function setCompanyUserFilter(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_COMPANY_USER_FILTER,
            payload: string
        });  
    }  
}; 