import { 
    SET_OUTLINE_COLOR,
    SET_CURRENT_TILE_ID,
    SET_ALL_FILES,
    REFRESH_FILES,
    SET_CURRENT_TILE_FILES,
    REMOVE_FILE,
    EDIT_FILE,
    EDIT_FILE_GUEST_PASS,
    RESET_TILES
} from '../constants/actionTypes';

const initialState = {
    outlineColor: '#3b3b3b',
    currentTileId: 0,
    files: [],
    currentTileFiles: []
}

export default (state = initialState, action) => {
    switch (action.type) {   
        case SET_OUTLINE_COLOR:
            return {
                ...state,
                outlineColor: action.payload
            };
        case SET_CURRENT_TILE_ID:
            return {
                ...state,
                currentTileId: action.payload
            };
        case REFRESH_FILES: 
            return {
                ...state,
                currentTileFiles: []
            }
        case SET_ALL_FILES:
            return {
                ...state,
                files: state.files.concat(action.payload)
            };
        case SET_CURRENT_TILE_FILES:
            return {
                ...state,
                currentTileFiles: state.currentTileFiles.concat(action.payload)
            };
        case REMOVE_FILE:
            return {
                ...state,
                files: state.files.filter(file => file?.id !== action.payload),
            };
        case EDIT_FILE:
            return {
                ...state,
                files: state.files.map(    
                    (file) => file.id === action.payload.id ? {
                        ...file,
                        isThumbnail: action.payload.isThumbnail
                    } : file
                ),
                currentTileFiles: state.currentTileFiles.map(    
                    (file) => file.id === action.payload.id ? {
                        ...file,
                        isThumbnail: action.payload.isThumbnail
                    } : file
                )      
            };
        case EDIT_FILE_GUEST_PASS:
            return {
                ...state,
                files: state.files.map(    
                    (file) => file.id === action.payload.id ? {
                        ...file,
                        isGuestPass: action.payload.isGuestPass
                    } : file
                ) 
            };
        case RESET_TILES:
            return {
                ...state,
                outlineColor: '',
                files: [],
                currentTileFiles: [],
                currentTileId: 0
            };
        default:
            return state;
    }
};