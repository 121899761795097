import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import '../assets/images/logo.png';
import '../assets/images/logo2.png';
// import shapes from '../assets/images/shapes.gif';
import f2b from '../assets/images/f2bbackground.jpg';
import CreatePresentationModal2 from '../components/CreatePresentationModal2';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ConfirmArchiveModal from '../components/ConfirmArchiveModal';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import ReactTooltip from 'react-tooltip';
import { 
    setPresentationId,
    setBackgroundColor,
    setBackgroundImage,
    setTileSpeed,
    setTileMovementDirection,
    setIconsColor,
    setNewPresentation,
    setIconsBorderColor,
    setPresentationTiles,
    setBackgroundId,
    setFontColor,
    setPresentationSize,
    setFileOverrideBool
} from '../actions/presentationActions';
import {
    setAllFiles,
    setOutlineColor
} from '../actions/tileActions'
import {
    setScreenVisit,
    setTilesVisit,
    setSettingsVisit,
    setExportVisit,
    setCurrentPage,
    setByUserView
} from '../actions/visitActions';
import {
    setIsAuth,
    resetUser,
    setCompanyName,
    setCompanyId
} from "../actions/authActions";
import {
    setCompanyUserFilter,
    setCreatePresentationModalView
} from "../actions/viewActions";
import {
    getSelectedPresentation,
    createNewPresentation,
    getPresentationTiles,
    getFiles,
    getBackground
} from '../utils/API';
import { v4 as uuidv4 } from 'uuid';
// import PersonRounded from '@material-ui/icons/PersonRounded';

const StyledPresentationsContainer = styled.div`
    height: 100vh;
    width: 100vw;
    transition: 0.7s all ease;
    background-color: #0768b0;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .header {
        background-color: #3b3b3bBF;
        height: 80px;
        width: 100%;
        box-shadow: 0px 3px 3px 1px #000000;
        position: relative;

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: 55px;
        }

        .logo-box {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            width: 150px;
            object-fit: contain;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 10px;

            @media (max-width: 1100px) {
                width: min-content;
            }

            .logo {
                height: 55px;
                width: 120px;

                @media (max-width: 1100px) {
                    height: 40px;
                    width: 90px;
                }

                @media (max-width: 767px) {

                }
            }
        }

        .create-presentation-box {
            position: absolute;
            height: 100%;
            left: 150px;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 1100px) {
                left: 120px;
                width: min-content;
            }

            @media (max-width: 767px) {
                left: calc(50% - 50px);
            }

            .create-button {
                height: 40px;
                width: 180px;
                border-radius: 3px;
                background-color: #0768b0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                box-shadow: 1px 1px 2px 1px #779bd940;

                @media (max-width: 1100px) {
                    width: 140px;
                    height: 35px;
                }

                @media (max-width: 767px) {
                    height: 50px;
                    width: 100px;
                }

                @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
                    height: 40px;
                }
                
                p {
                    margin-block: 0px;
                    font-size: 15px;
                    font-weight: 600;
                    color: #ffffff;
                    text-shadow: 1px 1px #3b3b3b;
                    text-align: center;

                    @media (max-width: 1100px) {
                        font-size: 13px;
                    }
                }
            }
        }

        .filter-box {
            position: absolute;
            height: 100%;
            width: 300px;
            display: flex;
            justify-content: center;
            align-items: center;
            left: calc(50% - 150px);

            @media (max-width: 1100px) {
                width: min-content;
                left: calc(50% - 75px);
            }

            @media (max-width: 767px) {
                display: none;
            }

            input {
                width: 240px;
                padding: 2px 10px 2px 10px;
                font-family: 'Roboto Slab', serif;
                font-size: 14px;
                text-align: center;

                @media (max-width: 1100px) {
                    width: 150px;
                    font-size: 12px;
                }

                @media (max-width: 767px) {
                    display: none;
                }
            }

            .toggle-company-user {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 28px;
                background-color: red;
                width: 28px;
                margin-left: 1px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                cursor: pointer;

                svg {
                    color: white;
                    height: 20px;
                    width: 20px;
                    cursor: inherit;
                }
            }
        }

        .logout-box {
            position: absolute;
            height: 100%;
            width: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            right: 0;
            cursor: pointer;
        }

    }

    .body {
        position: relative;
        height: calc(100vh - 80px);
        width: 100%;
        background-color: #ffffff;
        display: flex;
        padding: 1% 2% 70px 2%;
        flex-wrap: wrap;
        object-fit: contain;
        overflow-y: auto;
        overflow-x: hidden;

        ::-webkit-scrollbar {
            width: 5px;
            transition: 0.3 ease all;
        }

        ::-webkit-scrollbar-track {
            background: white;
        }

        ::-webkit-scrollbar-thumb {
            background: #888888;
            border-radius: 200px;
            transition: 0.3 ease all;
        }
        
        ::-webkit-scrollbar-thumb:hover {
            background: #555555;
        }

        @media (max-width: 450px) {

        }

        @media (max-width: 815px) and (max-height: 550px) and (orientation: landscape) {
            height: calc(100vh - 55px);
            top: auto;
        }

        .overlay {
            position: absolute;
            z-index: 1;
            height: calc(100vh - 80px);
            width: 100%;
            top: 0px;
            left: 0;
        }
    }
`;

const StyledChoiceContainer = styled.div`
    height: 250px;
    width: 18%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin: 1%;
    border-radius: 5px;
    box-shadow: 4px 5px 14px 0px #3b3b3b;
    position: relative;
    object-fit: contain;
    flex-shrink: 0;
    z-index: 2;

    @media (max-width: 1366px) {
        width: 21%;
        margin: 2%;
    }

    @media (max-width: 1024px) {
        width: 31.33%;
        margin: 1%;
    }

    @media (max-width: 767px) {
        width: 48%;
        margin: 1%;
    }

    @media (max-width: 450px) {
        width: 86%;
        margin: 7%;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    &:hover {
        box-shadow: 4px 5px 14px 0px #0768b0;
    }

    .interior-div {
        position: absolute;
        z-index: 1;
        height: 30%;
        width: 100%;
        top: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        padding-left: 10%;
        padding-right: 10%;
        background-color: #ffffffBF;
        overflow: hidden;

        p {
            color: #3b3b3b;
            font-size: 20px;
            letter-spacing: 0.05em;
            text-shadow: 1px 1px 1px black;
            margin-block-start: 0px;
            margin-block-end: 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }

    .archive-button {
        position: absolute;
        top: 3%;
        right: 3%;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        background-color: transparent !important;
    }

`;

const StyledExtraTile = styled.div`
    height: 250px;
    width: 18%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin: 1%;
    border-radius: 5px;
    box-shadow: 4px 5px 14px 0px #3b3b3b;
    position: relative;
    object-fit: contain;
    z-index: 2;

    @media (max-width: 1366px) {
        width: 21%;
        margin: 2%;
    }

    @media (max-width: 1024px) {
        width: 31.33%;
        margin: 1%;
    }

    @media (max-width: 767px) {
        width: 48%;
        margin: 1%;
    }

    @media (max-width: 450px) {
        width: 86%;
        margin: 7%;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    &:hover {
        box-shadow: 4px 5px 14px 0px #0768b0;
    }
`;

class choosePresentation extends Component {

    constructor() {
        super();
        this.state = {
            animate: false,
            search: '',
            backgroundImages: []
        }
    }

    componentDidMount = () => {
        if (this.props.isAuth !== true) {
            this.props.history.push('/')
        }
        setTimeout(() => {
            this.setState({ animate: true });
        }, 50);

        let activePresentations = this.props.presentations.filter(pres => !pres.isArchived);
        for (let presentation of activePresentations) {
            let arr = this.state.backgroundImages;
            getBackground(presentation.id).then((backgroundRes) => {
                // console.log("BACKGROUND", backgroundRes);
                if (backgroundRes.data.backgrounds.length) {
                    arr.push(backgroundRes.data.backgrounds[0]);
                    // console.log("ARR", arr);
                    this.setState({backgroundImages: arr});
                }
            });
        }
    }

    testContinue = () => {
        this.props.setScreenVisit(true);
        this.props.setTilesVisit(true);
        this.props.setSettingsVisit(true);
        this.props.setExportVisit(true);
        this.props.history.push('/intro');
    }

    logout = () => {
        this.props.setIsAuth(false);
        this.props.resetUser();
        this.props.setCompanyUserFilter("company");
        this.props.setByUserView(true);
        this.props.history.push('/');
    }

    handleInputChange = (event) => {
        let searchTerm = event.target.value;
        this.setState({search: searchTerm});
    }

    handleToggleCompanyUserFilter = () => {
        if (this.props.companyUserFilter === 'company') {
            this.props.setCompanyUserFilter('user');
        } else if (this.props.companyUserFilter === 'user') {
            this.props.setCompanyUserFilter('company');
        }
    }

    handleModalShow = () => {
        this.props.setCreatePresentationModalView(!this.props.createPresentationModalView);
    }

    createPresentation = () => {
        if (this.props.user !== '') {
            let newPresentation = {
                id: uuidv4(),
                userId: this.props.user,
            }
            this.props.setPresentationId(newPresentation.id);
            createNewPresentation(newPresentation);
            this.props.setNewPresentation(true);
            this.props.history.push('/screen');
        } else {
            return;
        }
    }

    selectPresentation = async(presentationId) => {
        getSelectedPresentation(presentationId).then(res => {
            console.log("PRESENTATION: ", res);
            getBackground(res.presentation.id).then((backgroundRes) => {
                console.log("BACKGROUND", backgroundRes);
                if (backgroundRes.data.backgrounds.length) {
                    this.props.setBackgroundImage(backgroundRes.data.backgrounds[0].url);
                    this.props.setBackgroundId(backgroundRes.data.backgrounds[0].id);
                }
            });
            this.props.setPresentationId(res.presentation.id);
            this.props.setBackgroundColor(res.presentation.backgroundColor);
            this.props.setTileSpeed(res.presentation.tileSpeed);
            this.props.setTileMovementDirection(res.presentation.TileMoveDirection);
            this.props.setIconsColor(res.presentation.iconColor);
            this.props.setIconsBorderColor(res.presentation.borderColor);
            this.props.setOutlineColor(res.presentation.tileColor);
            this.props.setFontColor(res.presentation.fontColor);
            this.props.setFileOverrideBool(res.presentation.fileOverride);
            if (this.props.userView === false) {
                this.props.setCompanyId(res.presentation.companyId);
                this.props.setCompanyName(res.presentation.companyName);
            }
            this.props.setNewPresentation(false);
            this.props.setScreenVisit(true);
            this.props.setTilesVisit(true);
            this.props.setSettingsVisit(true);
            this.props.setExportVisit(true);
            getPresentationTiles(res.presentation.id).then(tileRes => {
                console.log("TILES: ", tileRes.data.tiles);
                this.props.setPresentationTiles(tileRes.data.tiles);
                for (let tile of tileRes.data.tiles) {
                    getFiles(tile.id).then(fileRes => {
                        // console.log(`FILES FOR TILE ID ${tile.id}: `, fileRes.data.files);
                        this.props.setAllFiles(fileRes.data.files);
                    })
                }
                this.props.setCurrentPage('screen')
                this.props.history.push('/screen');
            })
        })

    }

    

    render() {
        let tileMapping;
        let extraTile = null;
        let allowedCentering = false;
        if (this.props.presentations.length) {
            let filteredPresentations = this.props.presentations.filter((presentation) => {
                return presentation.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
            });

            let companyUserFilteredPresentations;
            if (this.props.companyUserFilter === 'company') {
                companyUserFilteredPresentations = filteredPresentations;
            } else if (this.props.companyUserFilter === 'user') {
                companyUserFilteredPresentations = filteredPresentations.filter(pres => pres.userId === this.props.user);
            } else {
                companyUserFilteredPresentations = filteredPresentations;
            }

            let userViewFilteredPresentations;
            if (this.props.userView === true) {
                userViewFilteredPresentations = companyUserFilteredPresentations;
            } else if (this.props.userView === false) {
                userViewFilteredPresentations = companyUserFilteredPresentations.filter(pres => pres.companyId !== this.props.hiddenCompanyId);
            } else {
                userViewFilteredPresentations = companyUserFilteredPresentations;
            }
            
            if (userViewFilteredPresentations.filter(pres => pres.isArchived !== true).length || this.props.userView === true) {
                allowedCentering = true;
                tileMapping = (
                    userViewFilteredPresentations.filter(pres => pres.isArchived !== true).sort((a, b) => b.updatedAt - a.updatedAt).map((presentation) => {
                        let foundBackground = this.state.backgroundImages.find(background => background.presentationId === presentation.id);
                        return(
                            <StyledChoiceContainer 
                                key={presentation.id}
                                style={{
                                    backgroundImage: foundBackground ? `url('${foundBackground.url}')` : 'none',
                                    backgroundSize: 'cover',
                                    backgroundColor: presentation.backgroundColor ? presentation.backgroundColor : '#0768b0',
                                }}
                                onClick={() => this.selectPresentation(presentation.id)}
                            >
                                <div className='interior-div'>
                                    <p>{presentation.name ? presentation.name : presentation.id}</p>
                                </div>
                                <div className='archive-button' style={{backgroundColor: this.state.hoverChosen === presentation.id ? '#ededed' : 'white', display: this.props.userView === true ? 'flex' : 'none'}}>
                                    <ConfirmArchiveModal
                                        presentationId={presentation.id}
                                    />
                                </div>
                            </StyledChoiceContainer>
                        )
                    })
                );
            } else {
                tileMapping = (
                    <p style={{color: "black", fontFamily: "'Roboto Slab', serif", fontSize: "2em", textAlign: 'center'}}>
                        {this.props.userView === true ? 
                            `There doesn't seem to be anything here. Please create a presentation to continue.` : 
                            `Sorry, it appears you haven't been assigned to any any external company presentations at this time.`
                        }
                    </p>
                );
            }

            extraTile = (
                <StyledExtraTile
                    onClick={this.props.userView === true ? () => this.handleModalShow() : null}
                    style={{
                        display: this.props.userView === true ? 'flex' : 'none'
                    }}
                >
                    <AddRoundedIcon 
                        style={{
                            fontSize: '84px',
                            color: '#3b3b3b'
                        }}
                    />
                </StyledExtraTile>
            )

        } else {
            tileMapping = (
                <p style={{color: "black", fontFamily: "'Roboto Slab', serif", fontSize: "2em", textAlign: 'center'}}>
                    {this.props.userView === true ? 
                        `There doesn't seem to be anything here. Please create a presentation to continue.` : 
                        `Sorry, it appears you haven't been assigned to any any external company presentations at this time.`
                    }
                </p>
            );
        }

        return(
            <StyledPresentationsContainer>
                <div className='header'>
                    <div className='logo-box'>
                        <img
                            className="logo"
                            src={require('../assets/images/logo2.png')}
                            alt="logo"
                        />
                    </div>
                    <div className='create-presentation-box'>
                        <div 
                            className='create-button'
                            onClick={this.props.userView === true ? () => this.handleModalShow() : null}
                            style={{
                                display: this.props.userView === true ? 'flex' : 'none'
                            }}
                        >
                            <p>Create Presentation</p>
                        </div>
                    </div>
                    <div className='filter-box'>
                        <input
                            value={this.state.search}
                            onChange={this.handleInputChange}
                            type="text"
                            placeholder="Filter Presentations..."
                        />
                        <div 
                            className='toggle-company-user'
                            style={{
                                backgroundColor: this.props.companyUserFilter === 'company' ? '#0768b0' : 'rgb(38, 210, 184)',
                                display: this.props.userView === true ? 'flex' : 'none'
                            }}
                            onClick={this.props.userView === true ? () => this.handleToggleCompanyUserFilter() : null}
                            data-tip={this.props.companyUserFilter === 'company' ? 'Showing all company presentations' : 'Showing user-specific presentations'}
                        >
                            <ReactTooltip />
                            {this.props.companyUserFilter === 'company' 
                                ? 
                                    <WorkRoundedIcon />
                                :
                                    <PersonRoundedIcon />
                            }
                        </div>
                    </div>
                    <div className='logout-box'
                        onClick={() => this.logout()}
                    >
                        <ExitToAppRoundedIcon
                            style={{
                                fontSize: '48px',
                                color: 'white',
                            }}
                        />
                    </div>
                </div>
                <div 
                    className='body'
                    style={{
                        justifyContent: allowedCentering ? "start" : "center", 
                        alignItems: allowedCentering ? "start" : "center",
                        alignContent: allowedCentering ? "flex-start" : "stretch",
                    }}
                >
                    <div 
                        className='overlay'
                        style={{
                            backgroundImage: `url(${f2b})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            opacity: '0.5'
                        }}
                    />
                    {tileMapping}
                    {extraTile}
                </div>
                <CreatePresentationModal2 />
            </StyledPresentationsContainer>
        );
    }
}

const mapStateToProps = state => {
    return {        
        presentations: state.authReducer.presentations,
        isNewPresentation: state.presentationReducer.isNewPresentation,
        user: state.authReducer.user,
        email: state.authReducer.email,
        isAuth: state.authReducer.isAuth,
        createPresentationModalView: state.viewReducer.createPresentationModalView,
        presentationSize: state.presentationReducer.presentationSize,
        companyUserFilter: state.viewReducer.companyUserFilter,
        userView: state.visitReducer.userView,
        hiddenCompanyId: state.authReducer.hiddenCompanyId
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setPresentationId: (id) => dispatch(setPresentationId(id)),
        setBackgroundColor: (color) => dispatch(setBackgroundColor(color)),
        setBackgroundImage: (url) => dispatch(setBackgroundImage(url)),
        setBackgroundId: (id) => dispatch(setBackgroundId(id)),
        setTileSpeed: (data) => dispatch(setTileSpeed(data)),
        setTileMovementDirection: (data) => dispatch(setTileMovementDirection(data)),
        setIconsColor: (color) => dispatch(setIconsColor(color)),
        setIconsBorderColor: (color) => dispatch(setIconsBorderColor(color)),
        setNewPresentation: (bool) => dispatch(setNewPresentation(bool)),
        setScreenVisit: (bool) => dispatch(setScreenVisit(bool)),
        setTilesVisit: (bool) => dispatch(setTilesVisit(bool)),
        setSettingsVisit: (bool) => dispatch(setSettingsVisit(bool)),
        setExportVisit: (bool) => dispatch(setExportVisit(bool)),
        setPresentationTiles: (tiles) => dispatch(setPresentationTiles(tiles)),
        setAllFiles: (files) => dispatch(setAllFiles(files)),
        setOutlineColor: (color) => dispatch(setOutlineColor(color)),
        setFontColor: (color) => dispatch(setFontColor(color)),
        setIsAuth: (bool) => dispatch(setIsAuth(bool)),
        setCreatePresentationModalView: (bool) => dispatch(setCreatePresentationModalView(bool)),
        setCompanyName: (string) => dispatch(setCompanyName(string)),
        setCompanyId: (string) => dispatch(setCompanyId(string)),
        setCurrentPage: (page) => dispatch(setCurrentPage(page)),
        setPresentationSize: (file) => dispatch(setPresentationSize(file)),
        setFileOverrideBool: (bool) => dispatch(setFileOverrideBool(bool)),
        setCompanyUserFilter: (string) => dispatch(setCompanyUserFilter(string)),
        resetUser: () => dispatch(resetUser()),
        setByUserView: (bool) => dispatch(setByUserView(bool))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(choosePresentation);
