import {
    SET_SCREEN_VISIT,
    SET_TILES_VISIT,
    SET_SETTINGS_VISIT,
    SET_EXPORT_VISIT,
    RESET_VISIT,
    SET_CURRENT_PAGE,
    SET_BY_USER_VIEW
} from '../constants/actionTypes';

export function setCurrentPage(page) {  
    return dispatch => {  
        return dispatch({  
            type: SET_CURRENT_PAGE,
            payload: page
        });  
    }  
};

export function setByUserView(bool) {
    return (dispatch) => {
        return dispatch({
            type: SET_BY_USER_VIEW,
            payload: bool
        });
    }
}

export function setScreenVisit(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_SCREEN_VISIT,
            payload: bool
        });  
    }  
}; 

export function setTilesVisit(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_TILES_VISIT,
            payload: bool
        });  
    }  
}; 

export function setSettingsVisit(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_SETTINGS_VISIT,
            payload: bool  
        });  
    }  
}; 

export function setExportVisit(bool) {
    return dispatch => {  
        return dispatch({  
            type: SET_EXPORT_VISIT,
            payload: bool
        });  
    }  
}

export function resetVisit() {
    return dispatch => {  
        return dispatch({  
            type: RESET_VISIT
        });  
    }  
}