import {
    SET_SCREEN_VISIT,
    SET_TILES_VISIT,
    SET_SETTINGS_VISIT,
    SET_EXPORT_VISIT,
    RESET_VISIT,
    SET_CURRENT_PAGE,
    SET_BY_USER_VIEW
} from '../constants/actionTypes';

const initialState = {
    isScreenVisited: false,
    isTilesVisited: false,
    isSettingsVisited: false,
    isExportVisited: false,
    currentPage: '',
    userView: true
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };
        case SET_SCREEN_VISIT:
            return {
                ...state,
                isScreenVisited: action.payload
            };
        case SET_TILES_VISIT:
            return {
                ...state,
                isTilesVisited: action.payload
            };
        case SET_SETTINGS_VISIT:
            return {
                ...state,
                isSettingsVisited: action.payload
            };
        case SET_EXPORT_VISIT:
            return {
                ...state,
                isExportVisited: action.payload
            }
        case SET_BY_USER_VIEW:
            return {
                ...state,
                userView: action.payload
            }
        case RESET_VISIT:
            return {
                ...state,
                isScreenVisited: false,
                isTilesVisited: false,
                isSettingsVisited: false,
                isExportVisited: false,
                currentPage: ''
            };
        default:
            return state;
    }
};