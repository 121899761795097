import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { 
//     setScreenVisit
// } from '../actions/visitActions';
import {
    editPresentation
} from '../utils/API';
// import { withRouter } from 'react-router-dom';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import {
    editPresentationArchival
} from '../actions/authActions';

const ConfirmText = styled.div`
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
    }
`;

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        height: 30px;
        width: 150px;
        background-color: #0768b0;
        color: white;
        box-shadow: 2px 2px 4px 0px #808080;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1%;
        font-weight: 600;
        cursor: pointer;
        border-radius: 8px;
    }
`;


class ConfirmArchiveModal extends Component {

    constructor(props){
        super(props);

        this.state = {
            showHide: false,
        }
    }

    handleModalShowHide = (event) => {
        event.stopPropagation();
        this.setState({ showHide: !this.state.showHide });
    }

    archivePresentation = (event) => {
        if (this.props.presentationId === '' || this.props.presentationId === null) {
            return;
        } else {
            console.log("ARCHIVING");
        }

        let archiveObj = {
            isArchived: true
        }

        let archiveStateObj = {
            isArchived: true,
            id: this.props.presentationId
        }
        console.log("ARCHIVE STATE OBJ", archiveStateObj);
        editPresentation(this.props.presentationId, archiveObj);
        this.props.editPresentationArchival(archiveStateObj);
        this.handleModalShowHide(event);
    }

    render() {

        return(
            <>
                <CancelRoundedIcon 
                    style={{
                        color: '#ff1940',
                        fontSize: `28px`,
                        cursor: 'pointer',
                    }}
                    onClick={this.props.userView === true ? (event) => this.handleModalShowHide(event) : null}
                />

                <Modal show={this.state.showHide} style={{position: "fixed", top: "30%"}}>
                    <Modal.Body style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                        <ConfirmText>
                            <p>Are you sure you'd like to archive this presentation?</p>
                        </ConfirmText>
                        <ButtonContainer>
                            <div 
                                style={{backgroundColor: 'rgb(184, 0, 9)'}}
                                onClick={this.props.userView === true ? (event) => this.archivePresentation(event) : null}
                            >
                                Yes, Archive
                            </div>
                            <div 
                                onClick={(event) => this.handleModalShowHide(event)}
                            >
                                Go Back
                            </div>
                        </ButtonContainer>
                    </Modal.Body>

                </Modal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {        
        user: state.authReducer.user,
        userView: state.visitReducer.userView
    }
}

const mapDispatchToProps = dispatch => {
    return{
        editPresentationArchival: (bool) => dispatch(editPresentationArchival(bool))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmArchiveModal);