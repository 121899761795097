import {
    SET_USER,
    SET_TOKEN,
    SET_PREVIOUS_TOKEN,
    SET_PRESENTATIONS,
    SET_EMAIL,
    SET_IS_AUTH,
    EDIT_PRESENTATION_ARCHIVAL,
    EDIT_PRESENTATION_COLOR,
    ADD_PRESENTATION,
    SET_AUTH_TOKEN,
    SET_COMPANY_NAME,
    SET_COMPANY_ID,
    RESET_USER,
    SET_HIDDEN_COMPANY_ID
} from '../constants/actionTypes'

export function setUser(user) {
    return dispatch => {  
        return dispatch({  
            type: SET_USER,
            payload: user 
        });  
    }  
}

export function setToken(token) {
    return dispatch => {  
        return dispatch({  
            type: SET_TOKEN,
            payload: token 
        });  
    }  
}

export function setAuthToken(token) {
    return dispatch => {  
        return dispatch({  
            type: SET_AUTH_TOKEN,
            payload: token 
        });  
    }  
}

export function resetUser() {
    return dispatch => {  
        return dispatch({  
            type: RESET_USER
        });  
    }  
}

export function setCompanyName(string) {
    return dispatch => {  
        return dispatch({  
            type: SET_COMPANY_NAME,
            payload: string
        });  
    }  
}

export function setCompanyId(id) {
    return dispatch => {  
        return dispatch({  
            type: SET_COMPANY_ID,
            payload: id
        });  
    }  
}

export function setHiddenCompanyId(id) {
    return dispatch => {  
        return dispatch({  
            type: SET_HIDDEN_COMPANY_ID,
            payload: id
        });  
    }  
}

export function setPreviousToken(token) {
    return dispatch => {  
        return dispatch({  
            type: SET_PREVIOUS_TOKEN,
            payload: token 
        });  
    }  
}

export function setPresentations(data) {
    return dispatch => {  
        return dispatch({  
            type: SET_PRESENTATIONS,
            payload: data
        });  
    }  
}

export function setEmail(email) {
    return dispatch => {  
        return dispatch({  
            type: SET_EMAIL,
            payload: email
        });  
    }  
}

export function setIsAuth(bool) {
    return dispatch => {  
        return dispatch({  
            type: SET_IS_AUTH,
            payload: bool
        });  
    }  
}

export function editPresentationArchival(presentationData) {  
    return dispatch => {  
        return dispatch({  
            type: EDIT_PRESENTATION_ARCHIVAL,
            payload: presentationData
        });  
    }  
};

export function editPresentationColor(presentationData) {  
    return dispatch => {  
        return dispatch({  
            type: EDIT_PRESENTATION_COLOR,
            payload: presentationData
        });  
    }  
};

export function addPresentation(obj) {
    return dispatch => {  
        return dispatch({  
            type: ADD_PRESENTATION,
            payload: obj
        });  
    }  
}
