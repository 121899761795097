import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import {
    setFileOverrideBool
} from '../actions/presentationActions';
import {
    setFileOverrideModalView
} from '../actions/viewActions';

import {
    editPresentation
} from '../utils/API.js';

const StyledBackdrop = styled.div`
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 4;
    background-color: #3b3b3bBF;
    overflow: hidden;
    top: 0;
`;

const StyledCreationModal = styled.div`
    height: 300px;
    width: 400px;
    background-color: #fffcf5;
    position: absolute;
    z-index: ${props => props.active ? 5 : 0};
    top: calc(50% - 150px);
    left: calc(50% - 200px);
    box-shadow: 1px 1px 8px 1px #00000080;
    transition: all 0.3s ease;
    visibility: ${props => props.active ? `visible` : `hidden`};
    opacity: ${props => props.active ? 1 : 0};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .error-message {
        position: absolute;
        height: 60px;
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        background-color: #3b3b3b;
        border-radius: 3px;
        top: calc(50% - 30px);
        left: calc(50% - 150px);
        padding: 2%;

        p {
            margin-block: 0px;
            font-size: 16px;
            font-weight: 600;
            color: white;
            text-align: center;
        }
    }

    .centering-box {
        height: 250px;
        width: 350px;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;

        .title {
            display: flex;
            justify-content: center;
            align-items: flex-end;

            p {
                font-size: 28px;
                font-weight: 600;
                font-family: 'Crimson Text', serif;
                margin-block: 0px;
            }
        }

        .input {
            display: flex;
            justify-content: center;
            align-items: center;

            input {
                width: 300px;
                text-align: center;
            }
        }

        .submit {
            display: flex;
            justify-content: center;
            align-items: flex-start;

            .submit-button {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                width: 150px;
                border-radius: 20px;
                box-shadow: 1px 1px 2px 0px #3b3b3b80;
                cursor: pointer;
                background-color: #3b3b3b;

                p {
                    margin-block: 0px;
                    font-size: 18px;
                    font-weight: 600;
                    color: white;
                }
            }
        }
    }

    .exit-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        width: 40px;
    }
`;


class FileOverrideModal extends Component {

    constructor(){
        super();
        this.state = {
            passInput: '',
            errorVisible: false,
            errorMessage: ''
        }
    }

    handleModalShow = () => {
        this.props.setFileOverrideModalView(!this.props.fileOverrideModalView);
    }

    onChange = (event) => {
        this.setState({ passInput: event.target.value });
    }

    handleOnSubmit = () => {
        if (this.state.passInput.toLowerCase() === 'pennstatedad') {
            let obj = {
                fileOverride: true
            }
            editPresentation(this.props.presentationId, obj).then((res) => {
                this.props.setFileOverrideBool(res.data.presentation[0].fileOverride);
                this.setState({ errorVisible: true });
                this.setState({ errorMessage: 'File Override Successful. Welcome aboard, Captain.' });
                setTimeout(() => {
                    this.setState({ errorVisible: false });
                }, 1500);
            });
        } else {
            this.setState({ errorVisible: true });
            this.setState({ errorMessage: 'Incorrect password' });
            setTimeout(() => {
                this.setState({ errorVisible: false });
            }, 1500);    
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleOnSubmit();
        }
    };

    render() {

        return(
            <>
                <StyledBackdrop
                    style={{
                        visibility: this.props.fileOverrideModalView ? 'visible' : 'hidden'
                    }}
                    onClick={() => this.handleModalShow()}
                />
                <StyledCreationModal 
                    active={this.props.fileOverrideModalView}
                >
                    <div className='exit-icon'
                        onClick={() => this.handleModalShow()}
                    >
                        <CancelRoundedIcon
                            style={{
                                color: '#ff1940',
                                fontSize: `32px`,
                                cursor: 'pointer',
                            }}
                        />
                    </div>
                    <div 
                        className='error-message'
                        style={{
                            visibility: this.state.errorVisible ? 'visible' : 'hidden'
                        }}
                    >
                        <p>{this.state.errorMessage}</p>
                    </div>
                    <div className='centering-box'>
                        <div className='title'>
                            <p>File Limit Override</p>
                        </div>
                        <div className='input'>
                            <input 
                                onChange={this.onChange}
                                onKeyPress={this.handleKeyPress}
                                value={this.state.passInput}
                                type="password"
                                placeholder="Enter Launch Codes"
                            />
                        </div>
                        <div className='submit'>
                            <div className='submit-button' onClick={this.handleOnSubmit}>
                                <p>Submit</p>
                            </div>
                        </div>
                    </div>
                </StyledCreationModal>
            </>
        )
    }
}


const mapStateToProps = state => {
    return {        
        presentationId: state.presentationReducer.id,
        fileOverrideBool: state.presentationReducer.fileOverrideBool,
        fileOverrideModalView: state.viewReducer.fileOverrideModalView
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setFileOverrideModalView: (bool) => dispatch(setFileOverrideModalView(bool)),
        setFileOverrideBool: (bool) => dispatch(setFileOverrideBool(bool))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FileOverrideModal);