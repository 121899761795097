import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { 
    addTile,
    setPresentationSize
} from '../actions/presentationActions';
import {
    setAllFiles,
    removeFile
} from '../actions/tileActions';
import {
    setAddTileModalView
} from "../actions/viewActions";
import {
    createTile,
    createFile
} from '../utils/API';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

const StyledBackdrop = styled.div`
    top: 0;
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 5;
    background-color: #3b3b3bBF;
`;

const StyledCreationModal = styled.div`
    height: 250px;
    width: 400px;
    background-color: white;
    position: absolute;
    z-index: ${props => props.active ? 5 : 0};
    top: calc(50% - 125px);
    left: calc(50% - 200px);
    box-shadow: 1px 1px 8px 1px #00000080;
    transition: all 0.3s ease;
    visibility: ${props => props.active ? `visible` : `hidden`};
    opacity: ${props => props.active ? 1 : 0};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .input-box {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .title-box {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding-left: 50px;

            p {
                margin-block: 0px;
                color: #3b3b3b;
                font-size: 14px;
            }
        }

        input {
            height: 30px;
            width: 300px;
            padding: 1% 5% 1% 5%;
            text-align: center;
            font-style: ${props => props.length ? `normal` : `italic`};
            border-radius: 2px;
        }
    }

    .confirm-box {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .confirm-button {
            height: 35px;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 12px;
            background-color: #0768b0;
            cursor: pointer;
            box-shadow: 1px 1px 1px #3b3b3b;

            p {
                margin-block: 0px;
                color: white;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }

    .exit-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        width: 40px;
    }
`;

const StyledLabel = styled.label`
    height: 35px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #0768b0;
    cursor: pointer;
    box-shadow: 1px 1px 1px #3b3b3b;
    margin-block: 0px;
    color: white;
    font-size: 14px;
    font-weight: 600;
`;

const StyledInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

const StyledFileError = styled.div`
    width: 230px;
    height: 150px;
    background-color: #3b3b3b;
    position: absolute;
    left: calc(50% - 115px);
    top: calc(50% - 75px);
    box-shadow: 2px 2px 3px 1px #3b3b3b80;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    padding: 1%;

    p {
        margin-block: 0px;
        color: white;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }
`;


class CreateTileModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "",
            subtitle: "",
            clicked: false,
            clickedSub: false,
            errorVisible: false,
            errorMessage: ''
        }
    }
    
    inputRef = React.createRef();

    resetLocal = () => {
        this.setState({title: ''});
        this.setState({subtitle: ''});
        this.setState({clicked: false});
        this.setState({clickedSub: false});
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          this.inputRef.current.click();
        }
    };

    handleModalShow = () => {
        this.props.setAddTileModalView(!this.props.addTileModalView);
        this.resetLocal();
    }

    onChangeTitle = (event) => {
        this.setState({ title: event.target.value });
    }

    onChangeSubtitle = (event) => {
        this.setState({ subtitle: event.target.value });
    }

    addTile = (event, totalSize) => {
        let highest = -1;
        if (this.props.presentationTiles.length) {
            for (let tile of this.props.presentationTiles) {
                if (tile.order !== null) {
                    if (tile.order > highest) {
                        highest = tile.order;
                    }
                }
            }
        }
        let orderValue;
        if (highest !== null) {
            orderValue = highest + 1;
        } else {
            orderValue = 0;
        }
        console.log("ORDER VALUE", orderValue);
        let tileId = uuidv4();
        let hasThumb = false;
        let newTile = {
            id: tileId,
            order: orderValue,
            presentationId: this.props.presentationId,
            files: [],
            ContentPaths: null,
            PrimaryThumbnailPath: null,
            SecondaryThumbnailPath: null,
            isGuestPass: false,
            Title: this.state.title,
            SubTitle: this.state.subtitle,
            Description: "",
            Tags: ""
        }
        this.props.addTile(newTile);
        createTile(newTile);
        for (let i = 0; i < event.target.files.length; i++) {
            console.log(`FILE ${i} START`);
            let uploadFile = event.target.files[i];
            let formData = new FormData();
            formData.append('file', uploadFile);
            if ((uploadFile.size && uploadFile.size < 100000000 && (totalSize + uploadFile.size < 500000000)) || (uploadFile.size && this.props.fileOverrideBool)) {
                let thumbBool = false;
                if (hasThumb === false && (uploadFile.type === "image/jpeg" || uploadFile.type === "image/png")) {
                    thumbBool = true;
                    hasThumb = true;
                } else {
                    thumbBool = false;
                }
                let fillerId = uuidv4();
                let fillerFile = {
                    id: fillerId,
                    type: 'filler',
                    tileId: tileId
                };
                this.props.setAllFiles(fillerFile);
                createFile(tileId, formData, thumbBool, false, false).then((res) => {
                    if (res.data.errors) {
                        console.log("ERROR", res.data.errors);
                        this.setState({errorMessage: `File ${res.data.errors[0].value} too large or already exists within tile.`});
                        this.setState({errorVisible: true});
                        setTimeout(() => {
                            this.setState({errorVisible: false});
                        }, 3000);
                        this.props.removeFile(fillerId);
                    } else {
                        console.log(`FILE ${i} COMPLETE`);
                        this.props.setAllFiles(res.data.file);
                        this.props.removeFile(fillerId);
                    }
                });
            } else {
                if (uploadFile.size && (totalSize + uploadFile.size > 500000000)) {
                    this.setState({errorMessage: 'Data limit reached.'});
                } else {
                    this.setState({errorMessage: `Uploaded files (${uploadFile.name}) cannot be larger than 100MB.`});
                }
                this.setState({errorVisible: true});
                setTimeout(() => {
                    this.setState({errorVisible: false});
                }, 3000);
            }
        }
        this.handleModalShow();
        this.resetLocal();
    }

    clickInput = () => {
        this.setState({ clicked: true });
        this.setState({ clickedSub: false });
    }

    clickInputSub = () => {
        this.setState({ clickedSub: true });
        this.setState({ clicked: false });
    }

    render() {

        return(
            <>
                <StyledBackdrop
                    style={{
                        visibility: this.props.addTileModalView ? 'visible' : 'hidden'
                    }}
                    onClick={() => this.handleModalShow()}
                />
                <StyledFileError style={{
                    visibility: this.state.errorVisible ? 'visible' : 'hidden'
                }}>
                    <p>Uploaded files cannot be larger than 100MB.</p>
                </StyledFileError>
                <StyledCreationModal 
                    active={this.props.addTileModalView}
                    length={this.state.title.length}
                >
                    <div className='exit-icon'
                        onClick={() => this.handleModalShow()}
                    >
                        <CancelRoundedIcon
                            style={{
                                color: '#ff1940',
                                fontSize: `32px`,
                                cursor: 'pointer',
                            }}
                        />
                    </div>
                    <div className='input-box'>
                        <div className='title-box'>
                            <p>Tile Title</p>
                        </div>
                        <input
                            onChange={this.onChangeTitle}
                            onKeyPress={this.handleKeyPress}
                            value={this.state.title}
                            type="text"
                            placeholder={this.state.clicked === false ? "Sample Title" : null}
                            onClick={() => this.clickInput()}
                        />
                    </div>
                    <div className='input-box'>
                        <div className='title-box'>
                            <p>Tile Subtitle</p>
                        </div>
                        <input
                            onChange={this.onChangeSubtitle}
                            onKeyPress={this.handleKeyPress}
                            value={this.state.subtitle}
                            type="text"
                            placeholder={this.state.clickedSub === false ? "Sample Subtitle" : null}
                            onClick={() => this.clickInputSub()}
                        />
                    </div>
                    <div className='confirm-box'>
                        <StyledLabel for="addTile">Choose Files</StyledLabel>
                        <StyledInput type="file" multiple={true} accept=".jpg,.png,.mp4,.pdf" ref={this.inputRef} onChange={(event) => {this.addTile(event, this.props.totalFileSize); event.target.value=""}} name="addTile" id="addTile"></StyledInput>
                    </div>
                </StyledCreationModal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {        
        addTileModalView: state.viewReducer.addTileModalView,
        presentationId: state.presentationReducer.id,
        presentationSize: state.presentationReducer.presentationSize,
        fileOverrideBool: state.presentationReducer.fileOverrideBool,
        presentationTiles: state.presentationReducer.presentationTiles
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setAddTileModalView: (bool) => dispatch(setAddTileModalView(bool)),
        addTile: (tile) => dispatch(addTile(tile)),
        setAllFiles: (file) => dispatch(setAllFiles(file)),
        removeFile: (id) => dispatch(removeFile(id)),
        setPresentationSize: (file) => dispatch(setPresentationSize(file))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTileModal);