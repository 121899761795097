import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";
import {
    setByUserView
} from '../actions/visitActions';
import {
    setUser,
    setToken,
    setPresentations,
    setEmail,
    setIsAuth,
    setAuthToken,
    setCompanyId,
    setCompanyName,
    addPresentation,
    setHiddenCompanyId
  } from '../actions/authActions';
import { getAuth } from 'firebase/auth';
import {
    // login,
    // getUserPresentations,
    firebaseUser,
    getPresentationsByCompany,
    getPresentationsByFirestore
} from '../utils/API';
import '../assets/images/logo.png';
import { Spinner } from 'react-bootstrap';

const StyledLoginContainer = styled.div`
    height: 100vh;
    width: 100%;
    transition: 0.4s all ease;
    background-color: #0768b0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledLoginBox = styled.div`
    height: 75%;
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 50px;
    box-shadow: 2px 2px 8px 3px #3b3b3b80;

    @media (orientation: portrait) {
        width: 70%;
    }

    @media (orientation: landscape) and (max-width: 1366px) {
        width: 50%;
    }

    @media (orientation: portrait) and (max-width: 450px) {
        height: 90%;
        width: 90%;
    }

    @media (orientation: landscape) and (max-width: 767px) {
        flex-direction: row;
        height: 80%;
        width: 90%;
    }
`;

const StyledLogoBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    width: 100%;
    height: min-content;

    @media (orientation: landscape) and (max-width: 767px) {
        width: 50%;
    }

    img {
        height: 250px;
        width: 450px;
        max-height: 100%;
        max-width: 100%;

        @media (orientation: portrait) and (max-width: 767px) {
            height: 150px;
            width: 270px;
            margin-bottom: 30px;
        }

        @media (orientation: landscape) and (max-width: 1024px) {
            height: 200px;
            width: 360px;
        }

        @media (orientation: landscape) and (max-width: 767px) {
            height: 150px;
            width: 270px;
        }

        @media (orientation: landscape) and (max-width: 650px) {
            height: 125px;
            width: 235px;
        }
    }
`;

const StyledLoginInputs = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: min-content;
    width: 100%;

    .text-container {
        display: flex;
        justify-content: center;
        margin: 1%;
        width: 300px;

        p {
            text-align: center;
            letter-spacing: 0px;
            color: gray;
            line-height: 1em;
            font-size: 14px;
        }
    }

    .spinning-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1%;
        width: 300px;
    }
`;

const StyledForm = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    input {
        margin: 10px;
        height: 42px;
        width: 300px;

        @media (orientation: landscape) and (max-width: 767px) {
            height: 35px !important;
            width: 225px;
        }
    }
`;

const StyledButtonContainer = styled.div`
    height: min-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledButton = styled.button`
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    height: 42px;
    width: 140px;
    color: white;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0768b0;
    border-color: transparent;
    cursor: pointer;

    @media (orientation: landscape) and (max-width: 767px) {
        height: 35px !important;
        width: 225px;
    }
`;


class Login extends Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            email: '',
            password: '',
            errorMessage: '',
            loggingIn: false
        }
    }

    continue = () => {
        this.props.history.push('/select');
    }

    async loginAttempt() {
        this.setState({ loggingIn: true });
        try {
            await this.props.firebase.doSignInWithEmailAndPassword(this.state.email, this.state.password);
            let T =  await getAuth().currentUser.getIdTokenResult();
            console.log("USER ID?", T.claims);
            // setRole(T.claims.role);
            this.props.setUser(T.claims.user_id);
            getAuth().currentUser.getIdToken(true)
            .then((idToken) => {
                this.props.setAuthToken(idToken);
                let userObj = {
                    userId: T.claims.user_id,
                    token: idToken
                }
                firebaseUser(userObj).then((authRes) => {
                    console.log("AUTH RES", authRes);
                    if (authRes.companyId && authRes.companyName && authRes.jwtToken) {
                        window.sessionStorage.setItem('token', authRes.jwtToken);
                        this.props.setToken(authRes.jwtToken);
                        this.props.setCompanyName(authRes.companyName);
                        this.props.setCompanyId(authRes.companyId);
                        this.props.setByUserView(true);
                        getPresentationsByCompany(authRes.companyId).then((presRes) => {
                            console.log("PRES RES", presRes);
                            if (!presRes.data.errors) {
                                this.props.setPresentations(presRes.data.presentations);
                                this.setState({loggingIn: false});
                                this.props.setIsAuth(true);
                                this.continue();
                            } else {
                                this.setState({loggingIn: false});
                                this.setState({errorMessage: "Oops! We don't have an account with those credentials. Please try again."});
                                setTimeout(() => {
                                    this.setState({errorMessage: ""});
                                }, 2500);
                            }
                        }).catch((err) => {
                            console.log("NO TOKEN");
                        })
                    } else {
                        this.setState({loggingIn: false});
                        this.setState({errorMessage: "Oops! We don't have an account with those credentials. Please try again."});
                        setTimeout(() => {
                            this.setState({errorMessage: ""});
                        }, 2500);
                    }
                });
            });
        } catch(err) {
            this.setState({loggingIn: false});
            this.setState({errorMessage: "Oops! We don't have an account with those credentials. Please try again."});
            setTimeout(() => {
                this.setState({errorMessage: ""});
            }, 2500);
        }
    }

    async externalLoginAttempt() {
        this.setState({ loggingIn: true });
        try {
            await this.props.firebase.doSignInWithEmailAndPassword(this.state.email, this.state.password);
            let T =  await getAuth().currentUser.getIdTokenResult();
            console.log("USER ID?", T.claims);
            // setRole(T.claims.role);
            this.props.setUser(T.claims.user_id);
            getAuth().currentUser.getIdToken(true)
            .then((idToken) => {
                this.props.setAuthToken(idToken);
                let userObj = {
                    userId: T.claims.user_id,
                    token: idToken
                }
                firebaseUser(userObj).then((authRes) => {
                    console.log("AUTH RES", authRes);
                    if (authRes.companyId && authRes.companyName && authRes.jwtToken) {
                        window.sessionStorage.setItem('token', authRes.jwtToken);
                        this.props.setHiddenCompanyId(authRes.companyId);
                        this.props.setToken(authRes.jwtToken);
                        const q = query(collection(this.props.firebase.db, "events"), where("users", "array-contains", T.claims.user_id), where("application", "==", "PB"));
                        getDocs(q).then(querySnapshot => {
                            let docList = querySnapshot.docs.map(doc => {
                                return {
                                    id: doc.id,
                                    data: doc.data()
                                }
                            });
                            // console.log("DOC LIST", docList);
                            if (docList.length) {
                                this.props.setByUserView(false);
                                for (let doc of docList) {
                                    getPresentationsByFirestore(doc.data.company_id, doc.data.name).then(finalRes => {
                                        console.log("pres", finalRes);
                                        if (finalRes.data.presentations.length) {
                                            this.props.addPresentation(finalRes.data.presentations[0]);
                                        }
    
                                        this.setState({loggingIn: false});
                                        this.props.setIsAuth(true);
                                        this.continue();
                                    });
                                }
                            } else {
                                this.setState({loggingIn: false});
                                this.setState({errorMessage: "Oops! That account has no external presentations, or doesn't exist."});
                                setTimeout(() => {
                                    this.setState({errorMessage: ""});
                                }, 2500);
                            }
                        }).catch(err => {
                            console.log("DOCUMENT RETRIEVAL FAILED");
                            this.setState({loggingIn: false});
                            this.setState({errorMessage: "Oops! That account has no external presentations, or doesn't exist."});
                            setTimeout(() => {
                                this.setState({errorMessage: ""});
                            }, 2500);
                        })

                        // getPresentationsByCompany(authRes.companyId).then((presRes) => {
                        //     console.log("PRES RES", presRes);
                        //     if (!presRes.data.errors) {
                        //         this.props.setPresentations(presRes.data.presentations);
                        //         this.setState({loggingIn: false});
                        //         this.props.setIsAuth(true);
                        //         this.continue();
                        //     } else {
                        //         this.setState({loggingIn: false});
                        //         this.setState({errorMessage: "Oops! We don't have an account with those credentials. Please try again."});
                        //         setTimeout(() => {
                        //             this.setState({errorMessage: ""});
                        //         }, 2500);
                        //     }
                        // }).catch((err) => {
                        //     console.log("NO TOKEN");
                        // });
                    } else {
                        this.setState({loggingIn: false});
                        this.setState({errorMessage: "Oops! We don't have an account with those credentials. Please try again."});
                        setTimeout(() => {
                            this.setState({errorMessage: ""});
                        }, 2500);
                    }
                });
            });
        } catch(err) {
            this.setState({loggingIn: false});
            this.setState({errorMessage: "Oops! We don't have an account with those credentials. Please try again."});
            setTimeout(() => {
                this.setState({errorMessage: ""});
            }, 2500);
        }
    }

    render() {
        // console.log("FIREBASE", this.props.firebase);

        return(
            <StyledLoginContainer>
                <StyledLoginBox>
                    <StyledLogoBox>
                        <img 
                            src={require('../assets/images/logo.png')}
                            alt="logo"
                        />
                    </StyledLogoBox>
                    <StyledLoginInputs>
                        <StyledForm>
                            <input 
                                name="email"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={(event) => this.setState({email: event.target.value})}
                                type="text"
                            />
                            <input
                                name="password"
                                placeholder="Password"
                                value={this.state.password}
                                onChange={(event) => this.setState({password: event.target.value})}
                                type="password"
                            />
                        </StyledForm>
                        <StyledButtonContainer>
                        {!this.state.loggingIn ? (
                            <>
                                <StyledButton
                                    onClick={() => {
                                        this.loginAttempt();
                                    }}
                                >
                                    Login
                                </StyledButton>
                                <StyledButton
                                    onClick={() => {
                                        this.externalLoginAttempt();
                                    }}
                                >
                                    CM External Login
                                </StyledButton>
                            </>
                        ) : (
                            <div className='spinning-box'>
                                <Spinner animation="border" role="status" variant="dark">
                                    {/* <span className="sr-only">Loading...</span> */}
                                </Spinner>
                            </div>
                        )}
                        </StyledButtonContainer>
                        <div className="text-container">
                        {this.state.errorMessage.length ? (
                                <p style={{color: '#d61320'}}>{this.state.errorMessage}</p>
                            ) : (
                                <p>For help with passwords, please call:<br></br> 1-908-428-8030 ext. 1003</p>
                            )
                        }
                        </div>
                    </StyledLoginInputs>
                </StyledLoginBox>
            </StyledLoginContainer>
        );
    }
}

const mapStateToProps = state => {
    return {        
        user: state.authReducer.user,
        email: state.authReducer.email,
        token: state.authReducer.token,
        previousToken: state.authReducer.previousToken,
        isAuth: state.authReducer.isAuth,
        authToken: state.authReducer.authToken
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setUser: (user) => dispatch(setUser(user)),
        setToken: (token) => dispatch(setToken(token)),
        setPresentations: (data) => dispatch(setPresentations(data)),
        setEmail: (email) => dispatch(setEmail(email)),
        setIsAuth: (bool) => dispatch(setIsAuth(bool)),
        setAuthToken: (token) => dispatch(setAuthToken(token)),
        setCompanyId: (id) => dispatch(setCompanyId(id)),
        setCompanyName: (string) => dispatch(setCompanyName(string)),
        setByUserView: (bool) => dispatch(setByUserView(bool)),
        addPresentation: (obj) => dispatch(addPresentation(obj)),
        setHiddenCompanyId: (id) => dispatch(setHiddenCompanyId(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));


