import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
    setGuestpassGenerateModalView
} from '../actions/viewActions';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

const StyledBackdrop = styled.div`
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 4;
    background-color: #3b3b3bBF;
    overflow: hidden;
    top: 0;
`;

const StyledCreationModal = styled.div`
    height: 300px;
    width: 500px;
    background-color: white;
    position: absolute;
    z-index: ${props => props.active ? 5 : 0};
    top: calc(50% - 150px);
    left: calc(50% - 250px);
    box-shadow: 1px 1px 8px 1px #00000080;
    transition: all 0.3s ease;
    visibility: ${props => props.active ? `visible` : `hidden`};
    opacity: ${props => props.active ? 1 : 0};
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .exit-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        width: 40px;
    }

    .center-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 220px;
        width: min-content;

        .title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 16.67%;

            p {
                margin-block: 0px;
                font-family: 'Crimson Text', serif;
                font-size: 28px;
                font-weight: 600;
            }
        }

        .caption {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: 33.33%;

            p {
                margin-block: 0px;
                font-size: 14px;
                text-align: center;
            }
        }

        .link {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: 50%;
            position: relative;

            .copy-button {
                position: absolute;
                width: 60px;
                height: 25px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                bottom: 5px;
                right: 5px;
                background-color: rgb(7, 104, 176);
                cursor: pointer;

                p {
                    font-size: 12px;
                    color: white;
                    margin-block: 0px;
                    font-weight: 600;
                }
            }

            .copy-success {
                position: absolute;
                width: 130px;
                height: 25px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                bottom: calc(50% - 15px);
                right: calc(50% - 65px);
                background-color: #3b3b3b;
                cursor: pointer;

                p {
                    font-size: 13px;
                    color: white;
                    margin-block: 0px;
                    font-weight: 400;
                    font-style: italic;
                }
            }

            textarea {
                padding 2%;
            }
        }
    }
`;

class GenerateGuestPassModal extends Component {

    constructor(){
        super();
        this.state = {
            copySuccess: ''
        }
    }

    handleModalShow = () => {
        this.props.setGuestpassGenerateModalView(!this.props.guestpassGenerateModalView);
    }

    copyToClipboard = (event) => {
        this.textArea.select();
        document.execCommand('copy');
        event.target.focus();
        if (window.getSelection) {
            window.getSelection().removeAllRanges();
        } else if (document.selection) {
            document.selection.empty();
        }
        this.setState({ copySuccess: 'copied to clipboard' }, () => {
            setTimeout(() => { 
                this.setState({ copySuccess: '' });
            }, 1500);
        });
    };


    render() {

        let guestPass = `https://rb.f2bevents.com/guestpass/?${this.props.presentationId}`;

        return(
            <>
                <StyledBackdrop
                    style={{
                        visibility: this.props.guestpassGenerateModalView ? 'visible' : 'hidden'
                    }}
                    onClick={() => this.handleModalShow()}
                />
                <StyledCreationModal 
                    active={this.props.guestpassGenerateModalView}
                >
                    <div className='exit-icon'
                        onClick={() => this.handleModalShow()}
                    >
                        <CancelRoundedIcon
                            style={{
                                color: '#ff1940',
                                fontSize: `32px`,
                                cursor: 'pointer',
                            }}
                        />
                    </div>
                    <div className='center-box'>
                        <div className='title'>
                            <p>Guest Pass Link</p>
                        </div>
                        <div className='caption'>
                            <p>Please provide the below URL to anyone whom you wish to add photos or videos to your presentation.</p>
                        </div>
                        <div className='link'>
                            <div className='copy-button' onClick={this.copyToClipboard}>
                                <p>Copy</p>
                            </div>
                            <div
                                style={{
                                    visibility: this.state.copySuccess !== '' ? 'visible' : 'hidden'
                                }}
                                className='copy-success'
                            >
                                <p>{this.state.copySuccess}</p>
                            </div>
                            <textarea 
                                ref={(textarea) => this.textArea = textarea}
                                rows="4" cols="40" defaultValue={guestPass}
                            >
                            </textarea>
                        </div>
                    </div>
                </StyledCreationModal>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {        
        guestpassGenerateModalView: state.viewReducer.guestpassGenerateModalView,
        presentationId: state.presentationReducer.id
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setGuestpassGenerateModalView: (bool) => dispatch(setGuestpassGenerateModalView(bool))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GenerateGuestPassModal);