import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
    setCurrentPage
} from '../actions/visitActions'
import {
    buildFile,
    beginDownload,
    // deleteADV
} from '../utils/API';
// import pin from '../assets/images/UnpinButton.png';
// import winter from '../assets/images/winter.png';
import { Spinner } from 'react-bootstrap';
import f2b from '../assets/images/f2bbackground.jpg';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import ReactTooltip from 'react-tooltip';

const StyledExportContainer = styled.div`
    height: calc(100vh - 80px);
    width: 100%;
    transition: 0.4s all ease;
    position: relative;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1367px) and (orientation: landscape) {
        align-items: flex-start;
        padding-top: 5%;
    }

    @media (max-width: 1025px) and (orientation: landscape) {
        padding-top: 2%;
    }

    @media (orientation: portrait) and (max-width: 1025px) {
        align-items: flex-start;
        padding-top: 10%;
    }

    .download-container {
        position: absolute;
        height: 100px;
        width: 100px;
        z-index: 3;
        bottom: 4.5%;
        right: 8%;

        @media (max-width: 1367px) and (orientation: landscape) {
            height: 80px;
            width: 120px;
            right: auto;
            left: calc(50% - 60px);
            bottom: auto;
            top: calc(432px + 10%);
        }

        @media (max-width: 1025px) and (orientation: landscape) {
            top: calc(432px + 7%);
        }

        @media (orientation: portrait) and (max-width: 1025px) {
            height: 80px;
            width: 120px;
            right: auto;
            left: calc(50% - 60px);
            bottom: auto;
            top: calc(432px + 10%);
        }

        @media (orientation: portrait) and (max-width: 900px) {
            height: 80px;
            width: 120px;
            right: auto;
            left: calc(50% - 60px);
            bottom: auto;
            top: calc(324px + 10%);
        }

        .download-button {
            height: 100%;
            width: 100%;
            border-radius: 8px;
            background-color: rgb(42, 232, 48);
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 2px 2px 3px 0px #3b3b3b80;
        }

        .loading-container {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .download-tip {
        z-index: 3; 
        position: absolute;
        height: 120px;
        width: 180px;
        right: 75px;
        bottom: 150px;
        border-radius: 8px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1%;
        box-shadow: 2px 2px 2px 2px #3b3b3b40;
        transition: 0.3s all ease;

        @media (max-width: 1367px) and (orientation: landscape) {
            right: auto;
            left: calc(66% - 125px);
            height: 80px;
            width: 250px;
            bottom: auto;
            top: calc(432px + 10%);
        }

        @media (max-width: 1025px) and (orientation: landscape) {
            top: calc(432px + 7%);
            left: calc(75% - 125px);
        }

        @media (orientation: portrait) and (max-width: 1025px) {
            right: auto;
            left: calc(75% - 125px);
            height: 80px;
            width: 250px;
            bottom: auto;
            top: calc(432px + 10%);
        }
        
        @media (orientation: portrait) and (max-width: 900px) {
            right: auto;
            left: calc(75% - 125px);
            height: 80px;
            width: 250px;
            bottom: auto;
            top: calc(324px + 10%);
        }

        p {
            font-size: 14px;
            text-align: center;
            color: #3b3b3b;
            margin-block: 0px;
            // font-family: 'Crimson Text', serif;
            font-weight: 600;
        }
    }

    .overlay {
        position: absolute;
        z-index: 1;
        height: calc(100vh - 80px);
        width: 100%;
        top: 0px;
        left: 0;
        overflow: hidden;

        @media (max-width: 1367px) and (orientation: landscape) {

        }
    }

    .centering-box {
        z-index: 2;
        height: 90%;
        width: 70%;
        position: relative;
        box-shadow: 2px 2px 4px 1px #3b3b3b80;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1367px) and (orientation: landscape) {
            height: 432px;
            width: 768px;
        }

        @media (orientation: portrait) and (max-width: 1025px) {
            height: 432px;
            width: 768px;
        }

        @media (orientation: portrait) and (max-width: 900px) {
            height: 324px;
            width: 576px;
        }   

        .top-overlay {
            position: absolute;
            top: 10px;
            height: 60px;
            width: 30%;
            left: 35%;
            background-color: #FFFFFFBF;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;

            @media (max-width: 1367px) and (orientation: landscape) {
                left: calc(50% - 150px);
                width: 300px;
                height: 45px;
                border-radius: 0px;
            }

            @media (orientation: portrait) and (max-width: 1025px) {
                left: calc(50% - 150px);
                width: 300px;
                height: 45px;
                border-radius: 0px;
            }

            @media (orientation: portrait) and (max-width: 1025px) {
                left: calc(50% - 150px);
                width: 300px;
                height: 35px;
                border-radius: 0px;
                top: 0;
            }

            p {
                font-size: 28px;
                color: #3b3b3b;
                margin-block: 0px;
                font-family: 'Crimson Text', serif;
                font-weight: 600;

                @media (orientation: portrait) and (max-width: 1025px) {
                    font-size: 22px;
                }
            }
        }

        .bottom-overlay {
            position: absolute;
            bottom: 10px;
            left: 20%;
            background-color: #FFFFFFBF;
            width: 60%;
            height: 70px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-radius: 6px;

            @media (max-width: 1367px) and (orientation: landscape) {
                left: 0;
                width: 100%;
                height: 45px;
            }

            @media (orientation: portrait) and (max-width: 1025px) {
                left: 0;
                width: 100%;
                height: 45px;
                border-radius: 0px;
            }

            @media (orientation: portrait) and (max-width: 900px) {
                left: 0%;
                width: 100%;
                height: 35px;
            }

            .overlay-grid {
                display: flex;
                justify-content: center;
                align-items: center;

                span, p {
                    font-size: 20px;
                    margin-block: 0px;
                    color: black;
                    font-family: 'Crimson Text', serif;

                    @media (orientation: portrait) and (max-width: 900px) {
                        font-size: 14px;
                    }
                }

                span {
                    font-weight: 600;
                    margin-right: 6px;
                }

                .spinner-holder {
                    margin-left: 10px;
                }
            }
        }

        .tile-box {
            height: 400px;
            width: 500px;
            background-color: #3b3b3b;
            box-shadow: 2px 2px 6px 3px #3b3b3b80;
            border-radius: 6px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 1367px) and (orientation: landscape) {
                height: 250px;
                width: 300px;
            }

            @media (orientation: portrait) and (max-width: 1025px) {
                height: 250px;
                width: 300px;
            }

            @media (orientation: portrait) and (max-width: 900px) {
                height: 200px;
                width: 250px;
            }

            .tile-thumbnail {
                height: 275px;
                width: calc(100% - 8px);
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                @media (max-width: 1367px) and (orientation: landscape) {
                    height: 162px;
                    width: 288px;
                }

                @media (orientation: portrait) and (max-width: 1025px) {
                    height: 162px;
                    width: 288px;
                }

                @media (orientation: portrait) and (max-width: 900px) {
                    height: 129.6px;
                    width: 230.4px;
                }

                .icon-holder {
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 60px;
                    width: 60px;
                    top: calc(50% - 30px);

                    @media (max-width: 1367px) and (orientation: landscape) {
                        height: 45px;
                        width: 45px;

                        svg {
                            font-size: 36px !important;
                        }
                    }

                    @media (orientation: portrait) and (max-width: 1025px) {
                        height: 45px;
                        width: 45px;

                        svg {
                            font-size: 36px !important;
                        }
                    }

                    @media (orientation: portrait) and (max-width: 900px) {
                        height: 45px;
                        width: 45px;

                        svg {
                            font-size: 36px !important;
                        }
                    }
                }
            }

            .tile-text {
                position: absolute;
                height: 60px;
                width: 150px;
                bottom: 0;
                left: calc(50% - 75px);
                display: flex;
                justify-content: center;
                align-items: center;

                @media (max-width: 1367px) and (orientation: landscape) {
                    height: 45px;
                }

                @media (orientation: portrait) and (max-width: 1025px) {

                }

                @media (orientation: portrait) and (max-width: 900px) {
                    height: 40px;
                }

                p {
                    font-size: 18px;
                    margin-block: 0px;
                    font-weight: 600;
                    font-style: italic;

                    @media (orientation: portrait) and (max-width: 1025px) {
                        font-size: 14px;
                    }
                }
            }

            .icon-holder {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 60px;
                width: 60px;

                @media (max-width: 1367px) and (orientation: landscape) {
                    height: 45px;
                    width: 45px;

                    svg {
                        font-size: 30px !important;
                    }
                }

                @media (orientation: portrait) and (max-width: 1025px) {
                    height: 45px;
                    width: 45px;

                    svg {
                        font-size: 30px !important;
                    }

                    &.left {
                        left: 70px !important;
                    }

                    &.right {
                        right: 70px !important;
                    }
                }

                @media (orientation: portrait) and (max-width: 900px) {
                    height: 35px;
                    width: 35px;

                    svg {
                        font-size: 24px !important;
                    }
                }
            }
        }
    }
`;

class Export extends Component {

    constructor() {
        super();
        this.state = {
            animate: false,
            loading: false,
            loadingBox: false,
            loadingMessage: "Your file is being created - this may take a couple minutes."
        }
    }

    componentDidMount = () => {
        if (this.props.isAuth !== true) {
            this.props.history.push('/');
        }
        setTimeout(() => {
            this.setState({ animate: true });
        }, 50);
    }

    downloadFile = () => {
        this.setState({ loadingMessage: "Your file is being created - this may take a couple minutes." });
        this.setState({ loading: true });
        this.setState({ loadingBox: true });
        buildFile(this.props.presentationId, this.props.companyId, this.props.companyName).then((res) => {
            // setTimeout(() => {
                beginDownload(this.props.presentationId).then((downloadRes) => {
                    // console.log("DOWNLOAD RES", downloadRes);
                    // console.log("URL", downloadRes.request.responseURL);
                    // window.open(downloadRes.request.responseURL, 'myIframe');
                    this.setState({ loadingMessage: "Your file has been sent to IU2! Open your app for further instructions." });
                    this.setState({ loading: false });
                    setTimeout(() => {
                        this.setState({ loadingBox: false });
                    }, 3000);
                });
            // }, 1000 * 10)
            console.log(res);
            console.log("ADV DONE");
        });
    }
    
    render() {
        
        let foundFile;
        let firstJpg = this.props.files.find(file => file.type === "image/jpeg");
        let firstPng = this.props.files.find(file => file.type === "image/png");
        if (firstJpg !== undefined) {
            foundFile = firstJpg.url
        } else if (firstPng !== undefined) {
            foundFile = firstPng.url
        } else {
            foundFile = null
        }

        let speed;
        if (this.props.tileSpeed === 120) {
            speed = "Slow";
        } else if (this.props.tileSpeed === 150) {
            speed = "Normal";
        } else if (this.props.tileSpeed === 300) {
            speed = "Fast"
        } else {
            speed = "None Set"
        }

        let direction;
        if (this.props.tileMovementDirection === 1) {
            direction = "Right to Left";
        } else if (this.props.tileMovementDirection === 2) {
            direction = "Left to Right";
        } else if (this.props.tileMovementDirection === 3) {
            direction = "Top to Bottom";
        } else if (this.props.tileMovementDirection === 4) {
            direction = "Bottom to Top"
        } else {
            direction = "None Set"
        }

        return(
            <StyledExportContainer style={{opacity: this.state.animate ? 1 : 0}}>
                <div className='overlay'
                    style={{
                        backgroundImage: `url(${f2b})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        opacity: '0.5'
                    }}
                />
                <div className='download-container'>
                    {this.state.loading ? (
                        <div className='loading-container'>
                            <Spinner animation="border" role="status" variant="dark">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            <ReactTooltip/>
                            <div 
                                className='download-button' 
                                data-tip={
                                    (!this.props.backgroundImage && !this.props.backgroundColor) 
                                    || !this.props.tiles.length 
                                    || !this.props.files.length
                                    ? 'Make sure you have a background image or color, and have uploaded at least one tile with one file.' : null
                                }
                                onClick={                                    
                                    (!this.props.backgroundImage && !this.props.backgroundColor) 
                                    || !this.props.tiles.length 
                                    || !this.props.files.length
                                    ? null : () => this.downloadFile()} 
                                style={{
                                    backgroundColor: 
                                        (!this.props.backgroundImage && !this.props.backgroundColor) 
                                        || !this.props.tiles.length 
                                        || !this.props.files.length
                                        ? '#7693a8' : 'rgb(42, 232, 48)',
                                    cursor: 
                                        (!this.props.backgroundImage && !this.props.backgroundColor) 
                                        || !this.props.tiles.length 
                                        || !this.props.files.length
                                        ? 'default' : 'cursor',
                                }}
                            > 
                                <GetAppRoundedIcon 
                                    style={{
                                        color: 'white',
                                        fontSize: '52px'
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div 
                    className='download-tip'
                    style={{
                        visibility: this.state.loadingBox ? 'visible' : 'hidden',
                        opacity: this.state.loadingBox ? 1 : 0
                    }}
                >
                    <p>{this.state.loadingMessage}</p>
                </div>
                <div 
                    className='centering-box'
                    style={{
                        backgroundImage: this.props.backgroundImage === '' || this.props.backgroundImage === undefined ? "none" : `url('${this.props.backgroundImage}')`,
                        backgroundSize: "cover",
                        backgroundColor: this.props.backgroundColor === '' ? `white` : `${this.props.backgroundColor}`,
                    }}
                >
                    <div className='top-overlay'>
                        <p>Presentation Review</p>
                    </div>
                    <div className='bottom-overlay'>
                        <div 
                            className='overlay-grid'
                        >
                            <span>Presentation Speed:</span><p>{speed}</p>
                        </div>
                        <div 
                            className='overlay-grid'
                        >
                            <span>Tile Movement Direction:</span><p>{direction}</p>
                        </div>
                    </div>
                    <div 
                        className='tile-box'
                        style={{
                            backgroundColor: this.props.outlineColor !== '' ? this.props.outlineColor : '#3b3b3b'
                        }}
                    >
                        <div
                            className='icon-holder'
                            style={{
                                top: 0,
                                left: 0
                            }}
                        >
                            <LockRoundedIcon 
                                style={{
                                    color: this.props.iconsColor === '' || !this.props.iconsColor ? 'white' : `${this.props.iconsColor}`,
                                    fontSize: '44px'
                                }}
                            />
                        </div>
                        <div className='tile-text'>
                            <p style={{color: this.props.fontColor ? this.props.fontColor : 'white'}}>Sample Tile</p>
                        </div>
                        <div 
                            className='icon-holder'
                            style={{
                                top: 0,
                                right: 0
                            }}
                        >
                            <CancelRoundedIcon
                                style={{
                                    color: this.props.iconsColor === '' || !this.props.iconsColor ? 'white' : `${this.props.iconsColor}`,
                                    fontSize: '44px'
                                }}
                            />
                        </div>
                        <div 
                            className='icon-holder left'
                            style={{
                                left: '106px',
                                top: 0
                            }}
                        >
                            <ArrowBackIosRoundedIcon 
                                style={{
                                    fontSize: '50px',
                                    color: this.props.iconsColor === '' || !this.props.iconsColor ? '#3b3b3b' : `${this.props.iconsColor}`
                                }}
                            />
                        </div>
                        <div 
                            className='icon-holder right'
                            style={{
                                right: '106px',
                                top: 0
                            }}
                        >
                            <ArrowForwardIosRoundedIcon 
                                style={{
                                    fontSize: '50px',
                                    color: this.props.iconsColor === '' || !this.props.iconsColor ? '#3b3b3b' : `${this.props.iconsColor}`
                                }}
                                className='right'
                            />
                        </div>
                        <div 
                            className='tile-thumbnail'
                            style={{
                                backgroundImage: foundFile ? `url('${foundFile}')` : `url('${f2b}')`,
                                backgroundSize: "cover"
                            }}
                        >
                            <div 
                                className='icon-holder'
                                style={{
                                    height: '80px',
                                    width: '80px',
                                    top: 'calc(50% - 40px',
                                    left: 'calc(50% - 40px)'
                                }}
                            >
                                <PlayArrowRoundedIcon
                                    style={{
                                        fontSize: '72px',
                                        color: this.props.iconsColor === '' || !this.props.iconsColor ? '#3b3b3b' : `${this.props.iconsColor}`
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </StyledExportContainer>
        );

    }
}

const mapStateToProps = state => {
    return {        
        isAuth: state.authReducer.isAuth,
        backgroundColor: state.presentationReducer.color,
        outlineColor: state.tileReducer.outlineColor,
        files: state.tileReducer.files,
        iconsColor: state.presentationReducer.iconsColor,
        iconsBorderColor: state.presentationReducer.iconsBorderColor,
        backgroundImage: state.presentationReducer.backgroundImage,
        presentationId: state.presentationReducer.id,
        tileMovementDirection: state.presentationReducer.tileMovementDirection,
        tileSpeed: state.presentationReducer.tileSpeed,
        userId: state.authReducer.user,
        fontColor: state.presentationReducer.fontColor,
        tiles: state.presentationReducer.presentationTiles,
        companyId: state.authReducer.companyId,
        companyName: state.authReducer.companyName
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setCurrentPage: (page) => dispatch(setCurrentPage(page))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Export);